export default {
    "title": "Beroe 1916",
    "search": "Търсене",
    "news": "Новини",
    "team": "Отбор",
    "academy": "Академия",
    "history": "История",
    "more": "Още",
    "read-more": "Прочети повече",
    "index-heading-title-1": "Срещи",
    "index-heading-title-2": "Класиране",
    "index-heading-title-3": "Отборът",
    "index-heading-title-4": "История",
    "index-heading-subtitle-1": "Всички изминали и предстоящи мачове",
    "index-heading-subtitle-2": "Къде е класиран Берое",
    "index-heading-subtitle-3": "Запознай се с всички играчи",
    "index-heading-subtitle-4": "Над 100 годишна традиция в спорта ",
    "index-stadium-subtitle": "Един град, един отбор",
    "index-stadium-title-outline-1": "Няма мое",
    "index-stadium-title-outline-2": "Няма твое",
    "index-stadium-title-solid": "Светът е Берое",
    "next-game": "Предстояща среща",
    "last-game": "Последна среща",
    "index-next-games-title": "Предстоящи срещи",
    "all-games": "Всички срещи",
    "index-membership-title": "Бъди верен на любимия отбор",
    "index-membership-subtitle": "Купи своята сезонна карта и не пропускай мач на любимия отбор",
    "learn-more-for-membership": "Научете повече за членството",
    "position": "Позиция",
    "birth-date": "Дата на раждане",
    "birth-place": "Място на раждане",
    "nationality": "Националност",
    "height": "Височина",
    "weight": "Тегло",
    "index-players-title": "Играчи",
    "index-latest-news-title": "Последни новини",
    "index-team-history-title": "История на клуба",
    "professional-football-club": "Професионален футболен клуб",
    "beroe-stara-zagora": "Берое Стара Загора",
    "follow-us": "Последвайте ни",
    "first-team": "Първи отбор",
    "second-team": "Втори отбор",
    "fixtures": "Срещи",
    "standings": "Класиране",
    "footer-copyright": "ПФК БЕРОЕ © 2024",
    "privacy-policy": "Декларация за поверителност",
    "terms-and-conditions": "Права за ползване",
    "gdpr": "Защита на личните данни",
    "contacts": "Контакти",
    "developed-with": "Разработено с",
    "by": "от",
    "all-news": "Всички новини",
    "all-posts": "Още новини",
    "latest-posts": "Последни публикации",
    "academy-subtitle": "Бъдещето е в младите беройци",
    "home": "Начало",
    "academy-paragraph-1": "През годините школата на „Берое“ се е доказала като една от най-успешните в България, от която са излезли редица класни състезатели. В момента клубът разполага с над 200 деца и юноши, които са разпределени в 11 отбора. Най-малките възпитаници са на 7 години. Школата е представена с отбори в две Елитни групи в България, както и с отбор в Трета ЮИ лига.",
    "academy-paragraph-2": "Детско-юношеската школа на „Берое“ е печелила много отличия на национално и международно ниво. Последното голямо постижение е спечелената през 2014 г. Купа на Българския футболен съюз за юноши старша възраст.",
    "history-heading-line-1": "Над 100 години",
    "history-heading-line-2": "история",
    "bulgarian-cup": "Купа на България",
    "bulgarian-supercup": "Суперкупа на България",
    "bulgarian-league": "Шампион на България",
    "winner": "Носител",
    "champion": "Шампион",
    "second-place": "Второ място",
    "third-place": "Tрето място",
    "history-stadium-title-line-1": "Един град,",
    "history-stadium-title-line-2": "един отбор",
    "history-stadium-subtitle": "Стара Загора на 8000, Берое на 100",
    "all-achievements": "Всички постижения",
    "achievements-bulgaria": "В България по години",
    "achievements-bulgaria-line-1": "1968 г. – Финалист за Купата на Съветската армия",
    "achievements-bulgaria-line-2": "1972 г. – Трето място в първенството на България",
    "achievements-bulgaria-line-3": "1973 г. – Финалист за Купата на Съветската армия",
    "achievements-bulgaria-line-4": "1979 г. – Финалист за Купата на Съветската армия",
    "achievements-bulgaria-line-5": "1980 г. – Финалист за Купата на Съветската армия",
    "achievements-bulgaria-line-6": "1986 г. – Шампион на България",
    "achievements-bulgaria-line-7": "2010 г. – Носител на Купата на България",
    "achievements-bulgaria-line-8": "2010 г. – Финалист за Суперкупата на България",
    "achievements-bulgaria-line-9": "2013 г. – Носител на Купата на България",
    "achievements-bulgaria-line-10": "2013 г. – Носител на Суперкупата на България",
    "achievements-bulgaria-line-11": "2015 г. – Вицешампион на А футболна група",
    "achievements-bulgaria-line-12": "2016 г. – Трето място в А футболна група",
    "achievements-europe": "В Европа по години",
    "achievements-europe-line-1": "1968 г. – Носител на Балканската купа",
    "achievements-europe-line-2": "1969 г. – Носител на Балканската купа",
    "achievements-europe-line-3": "1973 г. – Осминафиналист за Купата на УЕФА",
    "achievements-europe-line-4": "1974 г. – Четвъртфиналист за КНК",
    "achievements-europe-line-5": "1980 г. – Осминафиналист за КНК",
    "achievements-europe-line-6": "1982 г. – Носител на Балканската купа",
    "achievements-europe-line-7": "1984 г. – Носител на Балканската купа",
    "choose-year": "Изберете година",
    "news-from-history-title": "Новини от категория “История”",
    "contacts-heading-title": "Изпратете запитване",
    "contacts-heading-subtitle": "Попълнете коректно формата за контакт и нашият екип ще се свърже с Вас възможно най-скоро",
    "your-name": "Вашето име",
    "your-phone-email": "Tелефон/ Email",
    "your-message": "Вашето съобщение",
    "send-now": "Изпрати сега",
    "contact-us": "Свържете се с нас",
    "send-inquiry": "Изпратете запитване",
    "fan-shop": "Фен магазин",
    "fan-shop-title": "Фен магазин",
    "previous-games": "Изминали срещи",
    "next-games": "Предстоящи срещи",
    "table-team": "Клуб",
    "table-matches": "М",
    "table-wins": "П",
    "table-draws": "Р",
    "table-loses": "З",
    "table-goal-difference": "ГР",
    "table-points": "Т",
    "address": "Адрес",
    "phone-email": "Tелефон/Email",
    "working-time": "Работно време",
    "office-1-title": "Административен офис",
    "office-1-address": "Стара Загора 6000, пл. Берое 1",
    "office-1-email": "office@beroeclub.com",
    "office-2-title": "Пресаташе",
    "office-2-name": "Лора Николаева",
    "office-2-email": "lora@beroeclub.com",
    "office-3-title": "Координатор с феновете",
    "office-3-name": "Александър Мечев",
    "fan-shop-address": "Стара Загора 6000,\n" +
        "Захари Княжески 81",
    "fan-shop-phone": "088 716 1916",
    "fan-shop-email": "beroefanshop@gmail.com",
    "days-hours-open": "Понеделник до петък – 11.00 – 19.16",
    "days-hours-closed": "Събота и неделя – почивен ден",
    "goalkeepers": "Вратари",
    "defenders": "Защитници",
    "midfielders": "Полузащитници",
    "forwards": "Нападатели",
    "goalkeeper": "Вратар",
    "defender": "Защитник",
    "midfielder": "Полузащитник",
    "forward": "Нападател",
    //Days of week
    "Monday": "Понеделник",
    "Tuesday": "Вторник",
    "Wednesday": "Сряда",
    "Thursday": "Четвъртък",
    "Friday": "Петък",
    "Saturday": "Събота",
    "Sunday": "Неделя",
    "biography": 'Биография',
    //Stadiums
    "Stadion Beroe": 'Стадион Берое',
    "Huvepharma Arena": 'Хювефарма Арена',
    "Stadion Hristo Botev": 'Стадион Христо Ботев',
    "Stadion Aleksandar Shalamanov": 'Стадион Александър Шаламанов',
    "Stadion Lokomotiv": 'Стадион Локомотив',
    "Stadion Albena-1": 'Стадион Албена 1',
    "Vivacom Arena - Georgi Asparuhov": 'Виваком Арена - Георги Аспарухов',
    "Nikola Shterev-Starika Complex": 'Никола Щерев - Старика',
    "Stadion Vitosha": 'Стадион Витоша',
    "Stadion Vasil Levski": 'Стадион Васил Левски',
    "Trace Arena pomoshtno igrishte": 'Трейс Арена - Помощно игрище',
    "Terenite na Evima Futbol": 'Терените на Евима Футбол',
    "Sportna Baza Beroe": 'Спортна база Берое',
    //Laagues
    "First League": 'Първа Лига',
    "Cup": 'Купа на България',
    "Friendlies Clubs": 'Приятелски мач',
    "financial-statement": 'Финансови отчети',
    //Contact form
    "contact-form-error-message": "Грешка! Моля опитайте по-късно!.",
    "contact-form-success-message": "Вашето съобщение беше изпратено успешно!",
    "contact-form-validation-error-message": "Моля, попълнете всички полета с валидни данни!",
}
