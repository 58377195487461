<template>

  <svg class="icon-svg" width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="icon-path" fill-rule="evenodd" clip-rule="evenodd"
          d="M17.6392 2.70032L2 2.70032L2 5.85798H5.3102C6.92707 5.85798 8.23779 7.16871 8.23779 8.78558V9.62746C10.0311 9.9267 11.398 11.4857 11.398 13.3639C11.398 15.2421 10.0311 16.8011 8.23779 17.1003V18.0352C8.23779 19.6521 6.92706 20.9628 5.3102 20.9628H2L2 24H17.6392V19.5282C14.7381 19.0367 12.5288 16.5116 12.5288 13.4706C12.5288 10.4296 14.7381 7.90459 17.6392 7.41302V2.70032ZM19.6392 2.70032V7.40194C22.5735 7.8654 24.8172 10.406 24.8172 13.4706C24.8172 16.5353 22.5735 19.0759 19.6392 19.5393V24H35.3472V20.9628H32.0365C30.4196 20.9628 29.1089 19.6521 29.1089 18.0352V17.1933C27.3156 16.894 25.9487 15.3351 25.9487 13.4568C25.9487 11.5786 27.3156 10.0196 29.1089 9.72041V8.78557C29.1089 7.1687 30.4196 5.85797 32.0365 5.85797H35.3472V2.70032L19.6392 2.70032ZM19.6392 17.5016C21.462 17.0663 22.8172 15.4268 22.8172 13.4706C22.8172 11.5145 21.462 9.87491 19.6392 9.43963L19.6392 17.5016ZM17.6392 9.45641C15.8506 9.9157 14.5288 11.5388 14.5288 13.4706C14.5288 15.4025 15.8506 17.0256 17.6392 17.4848L17.6392 9.45641ZM9.39795 13.3639C9.39795 14.1305 8.91557 14.7844 8.23779 15.0387L8.23779 11.689C8.91558 11.9434 9.39795 12.5973 9.39795 13.3639ZM29.1089 11.782C28.4311 12.0363 27.9487 12.6902 27.9487 13.4568C27.9487 14.2234 28.4311 14.8774 29.1089 15.1317V11.782ZM2 18.9628H5.3102C5.82249 18.9628 6.23779 18.5475 6.23779 18.0352L6.23779 8.78558C6.23779 8.27328 5.8225 7.85798 5.3102 7.85798H2L2 18.9628ZM35.3472 7.85797V18.9628H32.0365C31.5242 18.9628 31.1089 18.5475 31.1089 18.0352V8.78557C31.1089 8.27327 31.5242 7.85797 32.0365 7.85797H35.3472ZM37.3472 2.62791C37.3472 1.56333 36.4842 0.700317 35.4196 0.700317H1.9276C0.863014 0.700317 0 1.56333 0 2.62791V24.0724C0 25.137 0.863013 26 1.9276 26L35.4196 26C36.4842 26 37.3472 25.137 37.3472 24.0724V2.62791Z"
          fill="#0E4127"/>
  </svg>


</template>

<script>
export default {
  name: "MoreIcon",
  props: {
    primaryColor: {
      type: String,
      default: '#0E4127', // Default primary color
    },
    hoverColor: {
      type: String,
      default: '#FFFFFF', // Default hover color
    },
    width: {
      type: Number,
      default: 39,
    },
    height: {
      type: Number,
      default: 26,
    },
  },
};
</script>

<style scoped lang="scss">
.icon-svg {
  transition: 0.3s ease-in-out;
}

.icon-path {
  fill: var(--primary-color, #0E4127); /* Default fill color */
  transition: 0.3s ease-in-out;
}

.menu-item:hover .icon-svg .icon-path {
  fill: var(--hover-color, #FFFFFF); /* Fill color on parent hover */
  transition: 0.3s ease-in-out;
}
</style>
