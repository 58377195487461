import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css';
import { i18n } from './i18n'
import axios from "axios";
import {VueReCaptcha} from 'vue-recaptcha-v3'

axios.defaults.headers.common["X-Accept-Language"] = i18n.global.locale;

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueReCaptcha, {
        siteKey: '6Ldg5zMqAAAAAIR5EFvFb0Wpk4iDU_rf8xpgVwkw',
        loaderOptions: {
            autoHideBadge: true
        }
    })
    .mount("#app");
