<template>
  <div class="page-wrapper text-center container-custom d">
    <div class="page-title"> {{ $t('terms-and-conditions') }}</div>
    <div class="d-flex justify-content-center paragraph-text">
      <div class="text-start" v-if="$root.$i18n.locale === 'en'">
        <p>ALL RIGHTS TO USE THE WORK ARE GRANTED UNDER THE TERMS OF THIS CREATIVE COMMONS<br/>AGREEMENT (&amp;quot;AGREEMENT&amp;quot;).
          THE WORK IS SUBJECT TO APPLICABLE LAW AND THE TERMS OF<br/>THIS AGREEMENT. THE WORK MAY NOT BE USED IN WAYS
          NOT PROVIDED BY THIS<br/>AGREEMENT.<br/>USER ACCEPTS AND AGREES TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY
          USING THE<br/>PRODUCT IN ANY MANNER.<br/>1. Definitions<br/>1. &amp;quot;COLLECTIVE WORK&amp;quot; is a work,
          such as a periodical, anthology, or encyclopedia, that includes<br/>the WORK in its unaltered entirety,
          together with other objects constituting separate and<br/>independent works. THE COLLECTIVE WORK, shall not be
          considered a DERIVATIVE WORK for<br/>the purposes of this Agreement.<br/>2. &amp;quot;DERIVATIVE WORK&amp;quot;
          is an adapted, altered or reworked WORK, such as a translation, musical<br/>arrangement, dramatization,
          novelisation, screen adaptation, sound recording, artistic<br/>reproduction, abridged edition, summary or any
          other form in which the WORK may be<br/>reworked or changed. A CLASSIFIED PROCEEDING is not a DERIVATIVE
          PROCEEDING within the<br/>meaning of this Agreement. When the WORK is a musical composition or a sound
          recording, the<br/>synchronization of the WORK in time with a moving image (audio-video synchronization) will
          be<br/>considered a DERIVATIVE WORK.<br/>3. &amp;quot;RIGHT HOLDER&amp;quot; is a natural or legal person who
          grants a right to use the WORK according to<br/>the terms of this Agreement.<br/>4. &amp;quot;AUTHOR&amp;quot;
          is the natural person who created the WORK.<br/>5. &amp;quot;WORK&amp;quot; is the eligible subject of
          copyright or related rights, the right of use of which is<br/>granted under the terms of this Agreement.<br/>6.
          &amp;quot;USER&amp;quot; is a natural or legal person deriving rights from this Agreement who has not
          previously<br/>violated the terms of this Agreement, or who has received the express permission of the
          RIGHT<br/>HOLDER to exercise rights under this Agreement, despite previous violations.<br/>7. &amp;quot;ELEMENTS
          OF AGREEMENT&amp;quot; are the principles of this Agreement that are selected and<br/>indicated by the RIGHT
          HOLDER as such: recognition, non-commerciality, sharing of the shared.<br/>2. Rights of Free Use. The terms of
          this Agreement are not intended to limit any rights arising<br/>from the lawful free use, transmission,
          broadcast, first sale or other limitations of the copyright<br/>holder&amp;#39;s rights under the Copyright
          and Related Rights Act or other applicable law.</p>
        <p>3. Grant of right to use the WORK. Under the terms of this Agreement, the RIGHT HOLDER<br/>grants the USER
          free of charge the following non-exclusive rights to use the WORK worldwide:<br/>1. reproduce the WORK,
          include it in one or more COLLECTIVE WORKS, as well as reproduce it as<br/>part of them;<br/>2. create and
          reproduce DERIVATIVE WORKS;<br/>3. to distribute copies or re-records, to publicly present or perform the
          WORK, as well as to<br/>broadcast it wirelessly or transmit it by cable or other technical means, including as
          part of<br/>COLLECTIVE WORKS;<br/>4. distribute copies or re-records, publicly present, publicly perform, as
          well as broadcast<br/>wirelessly or transmit by cable or other technical means DERIVATIVE WORKS.<br/>The
          rights under this Agreement are granted for a period of up to 10 years. If none of the parties<br/>object in
          writing, the Agreement shall remain in effect for another 10 years. Automaticity<br/>applies until the
          expiration of the protection period of the work under Bulgarian law. The rights<br/>under this Agreement may
          be exercised through any known or newly created media and<br/>formats. The rights include the right to
          reproduce the WORK insofar as this is technically<br/>necessary to exercise the rights through other media and
          formats. All rights not expressly<br/>granted by the RIGHT HOLDER are reserved, including the rights under
          Section 4 Articles 2, 5 and<br/>6.<br/>4. Limitations. The rights granted under Section 3 above are subject to
          the following limitations:<br/>1. Distribution, public presentation, public performance or public broadcasting
          by wireless<br/>means, as well as transmission by cable or other technical means of the WORK is possible only
          in<br/>accordance with the terms of this Agreement. The USER is obliged to include a copy of this<br/>Agreement
          or a uniform resource identifier (hypertext link) to it and to each copy or carrier of<br/>the WORK. The USER
          may not set any additional conditions for use of the PRODUCT that change<br/>the terms of this Agreement or
          the exercise of the rights of persons using the PRODUCT. The<br/>USER cannot, on his own behalf, assign rights
          to use the WORK. He shall retain in full all notices<br/>relating to this Agreement and to the limitation of
          liability hereunder. The USER may not<br/>distribute, publicly present, publicly perform, broadcast
          wirelessly, transmit via cable or other<br/>technical means the WORK through any technological methods that
          control access to or use of<br/>the WORK in a manner inconsistent with the terms of this Agreement. These
          terms also apply to<br/>cases where the WORK is part of a COLLECTIVE WORK, regardless of whether the rights to
          the<br/>COLLECTIVE WORK are granted under the terms of this Agreement. When the USER has created<br/>a
          COLLAPSE OR DERIVATIVE WORK, he is obliged to remove any reference made pursuant to<br/>section 4 point 4 to
          the RIGHT HOLDER or the AUTHOR, when requested by them and to the<br/>extent practicable.</p>
        <p>2. USER may distribute, publicly present, publicly perform, and broadcast wirelessly, transmit via<br/>cable
          or other technical means, the DERIVATIVE WORK only in accordance with the terms of this<br/>CREATIVE COMMONS
          Agreement or any subsequent version of the Agreement , containing the<br/>ELEMENTS of the Agreement specified
          therein (for example, the Japanese adaptation of<br/>Acknowledgment-Non-Commercial-Share 2.5). USER shall
          include a copy of this Agreement, or<br/>any of the above versions, or a unique resource identifier with it
          and with each copy or medium<br/>of the DERIVATIVE WORK. The USER may not offer or impose any conditions for
          the use of the<br/>DERIVATIVE WORK that change the terms of this Agreement or the exercise of the rights
          arising<br/>from it for the persons using the WORK and is obliged to preserve in full all notices that refer
          to<br/>this Agreement and to the limitation of liability hereunder. USER may not distribute, publicly<br/>present,
          publicly perform, wirelessly broadcast, transmit via cable or other technical means the<br/>DERIVATIVE WORK,
          through any technological methods that control access to or use of the<br/>WORK in a manner that does not meet
          of the terms of this Agreement. These terms also apply<br/>to cases where the WORK is part of a COLLECTIVE
          WORK, regardless of whether the rights to the<br/>COLLECTIVE WORK are granted under the terms of this
          Agreement.<br/>3. USER may not exercise the rights granted under Section 3 above to obtain a commercial<br/>advantage
          or income. The exchange of the WORK for other copyrighted works, through digital<br/>data sharing or
          otherwise, will not be considered an act aimed at obtaining a commercial<br/>advantage or income, if no
          monetary consideration is paid in connection with the exchange of<br/>works, objects of copyright.<br/>4. If
          the USER distributes, publicly presents, publicly performs, as well as if it broadcasts<br/>wirelessly or
          transmits by cable or other technical means the WORK, it is obliged to preserve in<br/>its entirety all
          copyright designations on the WORK in a manner appropriate to the medium or<br/>means of transmission
          distribution or presentation, mentioning the name (or pseudonym,<br/>respectively) of the AUTHOR, if provided;
          and/or the name of the original author, copyright<br/>holder or other parties (eg: sponsoring organization,
          publisher or publication); the name of the<br/>WORK, if provided; if possible - the unique resource identifier
          (ERI), if it has been specified; In<br/>the case of a COLLECTIVE WORK, the designation should be made at least
          in a place where<br/>comparable acknowledgments of authorship are located and in a manner at least as obvious
          as<br/>other comparable acknowledgments of authorship.<br/>5. In case the WORK is a musical composition:<br/>a)
          Remuneration for public presentation or performance. RIGHT HOLDER reserves the exclusive<br/>right to collect,
          alone or through collective management organizations of copyright and artists&amp;#39;<br/>rights (eg
          MUSICAUTOR, ASCAP, BMI, SESAC), remuneration for public performance, public<br/>performance or use by
          wireless, via cable or other technical means of the WORK within the<br/>meaning of the provisions of Title
          One, Chapter Seven, Section III of the Copyright and Related<br/>Rights Act, if this broadcast, performance or
          use is aimed at obtaining a commercial advantage<br/>or income.</p>
        <p>b) Rights to reproduce and distribute sound recordings and remuneration due. The RIGHT<br/>HOLDER reserves
          all rights to collect, independently, through organizations for collective<br/>management of copyrights or
          through a proxy, a license fee for each copy of the sound carrier<br/>(including a cover version) created by
          the USER based on a WORK in accordance with the<br/>provisions of Art. 54 of the Copyright and Related Rights
          Act, if the USER uses this sound<br/>recording to obtain commercial advantage or income.<br/>6. Rights to
          broadcast wirelessly or to transmit by cable or other technical means and<br/>remuneration due. In the event
          that the WORK is a sound recording, the RIGHT HOLDER<br/>reserves the exclusive right to collect independently
          or through organizations for the collective<br/>management of copyrights remuneration for wireless
          broadcasting or transmission by cable or<br/>other technical means of the WORK in accordance with the
          provisions of Title One, Chapter<br/>seventh, Section III of the Copyright and Related Rights Act, if the USER
          has used this broadcast<br/>or transmission to obtain commercial advantage or income.<br/>5. Limitation of
          liability. THE RIGHT HOLDER SHALL NOT BE LIABLE TO THE USER ON ANY BASIS<br/>FOR ANY DAMAGES ARISING OUT OF
          THIS AGREEMENT OR THE USE OF THE WORK.<br/>6. Termination.<br/>1. This Agreement, together with the rights
          granted under it, is automatically terminated in case<br/>of violation of any of the terms of this Agreement
          by the USER. The rights of individuals or legal<br/>entities that have received copies of DERIVATIVES or
          COLLAPSE WORKS from the USER under<br/>this Agreement are preserved, provided that such individuals continue
          to comply with all<br/>provisions of this Agreement. Sections 1, 2, 5, 6, 7 and 8 shall survive termination of
          this<br/>Agreement.<br/>2. As long as the Agreement is not terminated according to the previous provision, the
          rights<br/>under it are granted for the maximum period permissible according to the applicable law,<br/>automatically
          renewable an unlimited number of times, (within the duration of the term of<br/>protection of the copyright or
          related rights on the WORK, according to the applicable law).<br/>Nevertheless, the RIGHT HOLDER reserves the
          right to make the WORK available for use under<br/>different contractual terms or to stop distribution of the
          WORK at any time, provided that this<br/>does not terminate the rights granted under this Agreement.<br/>7.
          Miscellaneous.<br/>1. Upon distribution or public presentation, including by wireless means, via cable or
          other<br/>technical means, of the WORK or COMPLETE WORK by the USER, the RIGHT HOLDER shall offer<br/>to all
          to whom the WORK reaches, a contract for the use of the WORK under the same<br/>conditions as provided of the
          USER according to this Agreement.<br/>2. If any provision of this Agreement is held to be invalid, this shall
          not affect the validity or<br/>enforceability of the remainder of the terms of this Agreement.</p>
        <p>3. A waiver of rights under or acceptance of a deviation from the terms of this Agreement will<br/>not be
          considered validly executed, except in cases where it is made in writing and signed by the<br/>party in whose
          sphere the negative effect of such waiver or deviation is .<br/>4. This Agreement covers all matters agreed
          upon by the parties with respect to the WORK<br/>provided for use. There are no covenants, agreements,
          representations or warranties with<br/>respect to the PRODUCT other than those set forth in this Agreement.
          THE RIGHT HOLDER is not<br/>bound by any additional terms that may appear in correspondence or messages from
          the USER.<br/>This Agreement may be amended only with the express written consent of the RIGHT HOLDER<br/>and
          the USER.<br/>8. Governing Law. The provisions of the legislation in force in the Republic of Bulgaria apply
          to<br/>all issues not settled by this Agreement.<br/>9. Disputes. All disputes arising out of or relating to
          this Agreement, including disputes arising<br/>out of or relating to its interpretation, invalidity,
          non-performance or termination, as well as<br/>disputes to fill gaps in the Agreement or adapt it to new
          circumstances, shall be subject to the<br/>jurisdiction of the competent courts in the Republic of Bulgaria.
        </p>
      </div>
      <div class="text-start" v-if="$root.$i18n.locale === 'bg'">
        <p dir="ltr">ВСИЧКИ ПРАВА ЗА ИЗПОЛЗВАНЕ НА ПРОИЗВЕДЕНИЕТО СЕ ПРЕДОСТАВЯТ СЪГЛАСНО УСЛОВИЯТА НА ТОЗИ ДОГОВОР НА
          &bdquo;КРИЕЙТИВ КОМЪНС&ldquo; (&bdquo;ДОГОВОРА&ldquo;). ПРОИЗВЕДЕНИЕТО Е ПОД ЗАКРИЛАТА НА ПРИЛОЖИМОТО ПРАВО И
          УСЛОВИЯТА НА НАСТОЯЩИЯ ДОГОВОР. ПРОИЗВЕДЕНИЕТО НЕ МОЖЕ ДА СЕ ИЗПОЛЗВА ПО НАЧИНИ, НЕУРЕДЕНИ ОТ НАСТОЯЩИЯ
          ДОГОВОР.</p>
        <p dir="ltr">ПОЛЗВАТЕЛЯТ ПРИЕМА И СЕ СЪГЛАСЯВА ДА БЪДЕ ОБВЪРЗАН С УСЛОВИЯТА НА ТОЗИ ДОГОВОР ЧРЕЗ ИЗПОЛЗВАНЕ НА
          ПРОИЗВЕДЕНИЕТО ПО КАКЪВТО И ДА Е НАЧИН.</p>
        <p dir="ltr">1. Определения</p>
        <ol>
          <li dir="ltr">
            <p dir="ltr">&bdquo;СБОРНО ПРОИЗВЕДЕНИЕ&ldquo; е произведение, като периодично издание, антология или
              енциклопедия, което включва ПРОИЗВЕДЕНИЕТО в своята непроменена цялост, заедно с други обекти,
              представляващи отделни и независими произведения. СБОРНОТО ПРОИЗВЕДЕНИЕ, няма да се счита за ПРОИЗВОДНО
              ПРОИЗВЕДЕНИЕ за целите на този Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">&bdquo;ПРОИЗВОДНО ПРОИЗВЕДЕНИЕ&ldquo; е приспособено, променено или преработено ПРОИЗВЕДЕНИЕ,
              като например превод, музикален аранжимент, драматизация, романизация, екранизация, звукозапис,
              художествена репродукция, съкратено издание, резюме или каквато и да е друга форма, в която ПРОИЗВЕДЕНИЕТО
              може да бъде преработено или променено. СБОРНОТО ПРОИЗВЕДЕНИЕ не е ПРОИЗВОДНО ПРОИЗВЕДЕНИЕ по смисъла на
              този Договор. Когато ПРОИЗВЕДЕНИЕТО представлява музикална композиция или звукозапис, синхронизацията на
              ПРОИЗВЕДЕНИЕТО във времево отношение с движеща се картина (синхронизация аудио &ndash; видео) ще се счита
              за ПРОИЗВОДНО ПРОИЗВЕДЕНИЕ.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">&bdquo;НОСИТЕЛ НА ПРАВОТО&ldquo; е физическо или юридическо лице, което предоставя право за
              използване на ПРОИЗВЕДЕНИЕТО, съгласно условията на този Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">&bdquo;АВТОР&ldquo; е физическото лице, създало ПРОИЗВЕДЕНИЕТО.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">&bdquo;ПРОИЗВЕДЕНИЕ&ldquo; е годен обект на авторски или сродни права, правото на използване
              върху който се предоставя съгласно условията на този Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">&bdquo;ПОЛЗВАТЕЛ&ldquo; е физическо или юридическо лице, черпещо права от този Договор, което
              не е нарушавало преди това условията по този Договор, или което е получило изричното разрешение на
              НОСИТЕЛЯ НА ПРАВОТО да упражнява права съгласно този Договор, въпреки предходни нарушения.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">&bdquo;ЕЛЕМЕНТИ НА ДОГОВОРА&ldquo; са принципите на настоящия Договор, които са избрани и
              посочени от НОСИТЕЛЯ НА ПРАВОТО като такива: признание, некомерсиалност, споделяне на споделеното.</p>
          </li>
        </ol>
        <p dir="ltr">2. Права на свободно използване. Условията на този Договор не целят да ограничат каквито и да било
          права, произтичащи от допустимото по закон свободно използване, предаване, излъчване, първата продажба или
          други ограничения на правата на носителя на авторските или сродните права съгласно Закона за авторското право
          и сродните му права или друго приложимо право.</p>
        <p dir="ltr">3. Предоставяне на право за използване на ПРОИЗВЕДЕНИЕТО. При условията на настоящия Договор,
          НОСИТЕЛЯТ НА ПРАВОТО предоставя на ПОЛЗВАТЕЛЯ безвъзмездно следните неизключителни права за използване на
          ПРОИЗВЕДЕНИЕТО за цял свят:</p>
        <ol>
          <li dir="ltr">
            <p dir="ltr">да възпроизвежда ПРОИЗВЕДЕНИЕТО, да го включва в едно или повече СБОРНИ ПРОИЗВЕДЕНИЯ, както и
              да го възпроизвежда като част от тях;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">да създава и да възпроизвежда ПРОИЗВОДНИ ПРОИЗВЕДЕНИЯ;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">да разпространява копия или презаписи, да представя или изпълнява публично ПРОИЗВЕДЕНИЕТО,
              както и да го излъчва по безжичен път или предава чрез кабел или друго техническо средство, включително и
              като част от СБОРНИ ПРОИЗВЕДЕНИЯ;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">да разпространява копия или презаписи, да представя публично, да изпълнява публично, както и да
              излъчва по безжичен път или предава чрез кабел или друго техническо средство ПРОИЗВОДНИ ПРОИЗВЕДЕНИЯ.</p>
          </li>
        </ol>
        <p dir="ltr">Правата по този Договор се предоставят за срок до 10 години. Ако никоя от страните не възрази
          писмено, Договорът продължава действието си за срок от още 10 години. Автоматизмът се прилага до изтичане на
          срока на закрила на произведението по българското право. Правата по този Договор могат да бъдат упражнявани
          чрез всякакви известни или новосъздадени носители и формати. Правата включват правото за преработване на
          ПРОИЗВЕДЕНИЕТО, доколкото това е технически необходимо за упражняване на правата чрез други носители и
          формати. Всички права, които не са изрично предоставени от НОСИТЕЛЯ НА ПРАВОТО, се запазват, включително
          правата по Раздел 4 членове 2, 5 и 6.</p>
        <p dir="ltr">4. Ограничения. Правата, предоставени съгласно Раздел 3 по-горе, са предмет на следните
          ограничения:</p>
        <ol>
          <li dir="ltr">
            <p dir="ltr">Разпространението, публичното представяне, публичното изпълнение или публичното излъчване по
              безжичен път, както и предаването чрез кабел или друго техническо средство на ПРОИЗВЕДЕНИЕТО е възможно
              само съгласно условията на този Договор. ПОЛЗВАТЕЛЯТ е длъжен да включи копие на този Договор или единен
              ресурсен идентификатор (хипертекстова връзка) към него и към всяко копие или носител на ПРОИЗВЕДЕНИЕТО.
              ПОЛЗВАТЕЛЯТ не може да поставя каквито и да е допълнителни условия за използване на ПРОИЗВЕДЕНИЕТО, които
              променят условията на този Договор или упражняването на правата на ползващите ПРОИЗВЕДЕНИЕТО лица.
              ПОЛЗВАТЕЛЯТ не може от свое име да преотстъпва права за използване на ПРОИЗВЕДЕНИЕТО. Той е длъжен да
              запазва в цялост всички уведомления, които се отнасят до този Договор и до ограничаване на отговорността
              по него. ПОЛЗВАТЕЛЯТ не може да разпространява, представя публично, изпълнява публично, излъчва по
              безжичен път, предава чрез кабел или друго техническо средство ПРОИЗВЕДЕНИЕТО посредством каквито и да е
              технологични методи, които контролират достъпа до или използването на ПРОИЗВЕДЕНИЕТО по начин, който не
              съответства на условията на този Договор. Тези условия се отнасят и за случаите, когато ПРОИЗВЕДЕНИЕТО е
              част от СБОРНО ПРОИЗВЕДЕНИЕ, независимо дали правата върху СБОРНОТО ПРОИЗВЕДЕНИЕ са предоставени съгласно
              условията на този Договор. Когато ПОЛЗВАТЕЛЯТ е създал СБОРНО ИЛИ ПРОИЗВОДНО ПРОИЗВЕДЕНИЕ, той е длъжен да
              отстрани всякаква препратка поставена съгласно раздел 4 точка 4 към НОСИТЕЛЯ НА ПРАВОТО или към АВТОРА,
              когато те са поискали това, и доколкото е практически осъществимо.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">ПОЛЗВАТЕЛЯТ може да разпространява, да представя публично, да изпълнява публично, както и да
              излъчва по безжичен път, да предава чрез кабел или чрез друго техническо средство, ПРОИЗВОДНОТО
              ПРОИЗВЕДЕНИЕ само съгласно условията на този Договор на &bdquo;КРИЕЙТИВ КОМЪНС&ldquo; или на последваща
              версия на Договора, съдържаща посочените в него ЕЛЕМЕНТИ на Договора (например японската адаптация на
              Признание-Некомерсиално-Споделяне на споделеното 2.5). ПОЛЗВАТЕЛЯТ е длъжен да включи копие на този
              Договор, или на някоя от горепосочените версии, или единен ресурсен идентификатор към него и към всяко
              копие или носител на ПРОИЗВОДНОТО ПРОИЗВЕДЕНИЕ. ПОЛЗВАТЕЛЯТ не може да предлага или налага каквито и да е
              условия за използване на ПРОИЗВОДНОТО ПРОИЗВЕДЕНИЕ, които променят условията на този Договор или
              упражняването на правата, произтичащи от него за ползващите ПРОИЗВЕДЕНИЕТО лица и е длъжен да запазва в
              цялост всички уведомления, които се отнасят до този Договор и до ограничаване на отговорността по него.
              ПОЛЗВАТЕЛЯТ не може да разпространява, да представя публично, изпълнява публично, излъчва по безжичен път,
              предава чрез кабел или друго техническо средство ПРОИЗВОДНОТО ПРОИЗВЕДЕНИЕ, посредством каквито и да е
              технологични методи, които контролират достъпа до или използването на ПРОИЗВЕДЕНИЕТО по начин, който не
              отговаря на условията на този Договор. Тези условия се отнасят и за случаите, когато ПРОИЗВЕДЕНИЕТО е част
              от СБОРНО ПРОИЗВЕДЕНИЕ, независимо дали правата върху СБОРНОТО ПРОИЗВЕДЕНИЕ са предоставени съгласно
              условията на този Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">ПОЛЗВАТЕЛЯТ не може да упражнява правата, предоставени съгласно Раздел 3 по-горе, за получаване
              на търговско предимство или доход. Размяната на ПРОИЗВЕДЕНИЕТО за други произведения, обект на авторски
              права, посредством съвместно ползване на цифрови данни или по друг начин няма да се смята за действие,
              насочено към получаване на търговско предимство или доход, ако не е налице заплащане на парични
              възнаграждения във връзка с размяна на произведения, обекти на авторски права.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Ако ПОЛЗВАТЕЛЯТ разпространява, представя публично, изпълнява публично, както и ако излъчва по
              безжичен път или предава чрез кабел или друго техническо средство ПРОИЗВЕДЕНИЕТО, той е длъжен да запазва
              в цялост всички обозначения за авторски права върху ПРОИЗВЕДЕНИЕТО по начин, съответен на носителя или
              средството за разпространение или представяне, като упомене името (или съответно псевдонима) на АВТОРА,
              ако е предоставено; и/или името на оригиналния автор, носител на авторските права или други страни
              (примерно: спонсорираща организация, издателство или издание); наименованието на ПРОИЗВЕДЕНИЕТО, ако е
              предоставено; при възможност &ndash; единния ресурсен идентификатор (ЕРИ), ако е бил указан; В случаите на
              СБОРНО ПРОИЗВЕДЕНИЕ обозначението следва да бъде направено поне на място, където са разположени сравними
              признания за авторство и по начин, поне толкова явен, колкото и други сравними признания на авторство.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">В случай че ПРОИЗВЕДЕНИЕТО представлява музикална композиция:</p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">а) Възнаграждение за публично представяне или изпълнение. НОСИТЕЛЯТ НА ПРАВОТО запазва
                  изключителното право да събира, самостоятелно или чрез организации за колективно управление на
                  авторски права и правата на изпълнителите (напр. MUSICAUTOR, ASCAP, BMI, SESAC), възнаграждение за
                  публично представяне, публично изпълнение или използване по безжичен път, чрез кабел или друго
                  техническо средство на ПРОИЗВЕДЕНИЕТО по смисъла на разпоредбите на Дял първи, Глава седма, Раздел ІІІ
                  от Закона за авторското право и сродните му права, ако това излъчване, изпълнение или използване е
                  насочено към получаване на търговско предимство или доход.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">б) Права за възпроизвеждане и разпространение на звукозаписи и дължимо възнаграждение.
                  НОСИТЕЛЯТ НА ПРАВОТО запазва всички права да събира, самостоятелно, чрез организации за колективно
                  управление на авторските права или чрез пълномощник, лицензионно възнаграждение за всеки екземпляр от
                  звуконосителя (включително кавър версия), създаден от ПОЛЗВАТЕЛЯ въз основа на ПРОИЗВЕДЕНИЕ съобразно
                  разпоредбите на чл. 54 от Закона за авторското право и сродните му права, ако ПОЛЗВАТЕЛЯТ използва
                  този звукозапис за получаване на търговско предимство или доход.</p>
              </li>
            </ul>
          </li>
          <li dir="ltr">
            <p dir="ltr">Права за излъчване по безжичен път или за предаване чрез кабел или друго техническо средство и
              дължимо възнаграждение. В случай, че ПРОИЗВЕДЕНИЕТО представлява звукозапис, НОСИТЕЛЯТ НА ПРАВОТО запазва
              изключителното право да събира самостоятелно или чрез организации за колективно управление на авторските
              права възнаграждение за излъчване по безжичен път или за предаване чрез кабел или друго техническо
              средство на ПРОИЗВЕДЕНИЕТО съгласно разпоредбите на Дял първи, Глава седма, Раздел ІІІ от Закона за
              авторското право и сродните му права, ако ПОЛЗВАТЕЛЯТ е използвал това излъчване или предаване за
              получаване на търговско предимство или доход.</p>
          </li>
        </ol>
        <p dir="ltr">5. Ограничение на отговорността. НОСИТЕЛЯТ НА ПРАВОТО НЕ НОСИ ОТГОВОРНОСТ ПРЕД ПОЛЗВАТЕЛЯ НА
          КАКВОТО И ДА Е ОСНОВАНИЕ ЗА КАКВИТО И ДА Е ВРЕДИ, ПРОИЗТИЧАЩИ ОТ НАСТОЯЩИЯ ДОГОВОР ИЛИ ОТ ИЗПОЛЗВАНЕТО НА
          ПРОИЗВЕДЕНИЕТО.</p>
        <p dir="ltr">6. Прекратяване.</p>
        <ol>
          <li dir="ltr">
            <p dir="ltr">Настоящият Договор, заедно с правата, предоставени по него, се прекратява автоматично при
              нарушение на което и да е от условията на настоящия Договор от страна на ПОЛЗВАТЕЛЯ. Правата на
              физическите или юридическите лица, които са получили копия от ПРОИЗВОДНИ или СБОРНИ ПРОИЗВЕДЕНИЯ от
              ПОЛЗВАТЕЛЯ, съгласно настоящия Договор се запазват, при положение че тези лица продължават да спазват
              всички разпоредби на настоящия Договор. Раздели 1, 2, 5, 6, 7 и 8 продължават действието си и след
              прекратяването на настоящия Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Доколкото Договорът не е прекратен съгласно предходната разпоредба, правата по него се
              предоставят за максимално допустимия съгласно приложимото право срок, автоматично подновяем неограничен
              брой пъти, (в рамките на времетраенето на срока на закрила на авторското или сродните права върху
              ПРОИЗВЕДЕНИЕТО, съгласно приложимото право). Независимо от това, НОСИТЕЛЯТ НА ПРАВОТО си запазва
              възможността да предостави ПРОИЗВЕДЕНИЕТО за използване при различни договорни условия или да спре
              разпространението на ПРОИЗВЕДЕНИЕТО по всяко време при положение, че това не прекратява правата,
              предоставени по този Договор.</p>
          </li>
        </ol>
        <p dir="ltr">7. Разни.</p>
        <ol>
          <li dir="ltr">
            <p dir="ltr">При разпространение или публично представяне, включително по безжичен път, чрез кабел или друго
              техническо средство,на ПРОИЗВЕДЕНИЕТО или СБОРНОТО ПРОИЗВЕДЕНИЕ от ПОЛЗВАТЕЛЯ, НОСИТЕЛЯТ НА ПРАВОТО
              предлага на всички, до които достига ПРОИЗВЕДЕНИЕТО, договор за използването на ПРОИЗВЕДЕНИЕТО при същите
              условия, които са предоставени на ПОЛЗВАТЕЛЯ съгласно настоящия Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Ако някоя от разпоредбите на настоящия Договор се окаже недействителна, това няма да повлияе
              върху действителността или възможността за принудително изпълнение на останалата част от условията на този
              Договор.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Отказ от права по или приемане на отклонение от условията на този Договор няма да се считат за
              валидно извършени, освен в случаите, когато това е направено в писмена форма и е подписано от страната, в
              чиято сфера е негативният ефект от този отказ или това отклонение.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Този Договор обхваща всички въпроси, по които страните са постигнали съгласие по отношение на
              предоставеното за използване ПРОИЗВЕДЕНИЕ. Не съществуват никакви други уговорки, споразумения, декларации
              или гаранции по отношение на ПРОИЗВЕДЕНИЕТО, освен определените с този Договор. НОСИТЕЛЯТ НА ПРАВОТО не е
              обвързан от каквито и да било допълнителни уговорки, които могат да се появят в кореспонденция или
              съобщения от ПОЛЗВАТЕЛЯ. Настоящият Договор може да бъде изменян единствено с изричното писмено съгласие
              на НОСИТЕЛЯ НА ПРАВОТО и ПОЛЗВАТЕЛЯ.</p>
          </li>
        </ol>
        <p dir="ltr">8. Приложимо право. За всички неуредени от настоящия Договор въпроси се прилагат разпоредбите на
          действащото законодателство в Република България.</p>
        <p dir="ltr">9. Спорове. Всички спорове, породени от този Договор или отнасящи се до него, включително
          споровете, породени от или отнасящи се до неговото тълкуване, недействителност, неизпълнение или прекратяване,
          както и споровете за попълване на празноти в Договора или приспособяването му към нововъзникнали
          обстоятелства, са подсъдни на компетентните съдилища в Република България.</p>
        <p dir="ltr">&bdquo;КРИЕЙТИВ КОМЪНС&ldquo; не е страна по този Договор и не носи каквато и да е отговорност във
          връзка с ПРОИЗВЕДЕНИЕТО. &bdquo;Криейтив Комънс&ldquo; не носи отговорност пред ПОЛЗВАТЕЛЯ или пред трети лица
          на каквото и да е основание за каквито и да е вреди във връзка с този Договор. Ако &bdquo;Криейтив Комънс&ldquo;
          изрично посочи себе се като НОСИТЕЛ НА ПРАВОТО по настоящия Договор, тогава &bdquo;Криейтив Комънс&ldquo; ще
          има всички права и задължения на НОСИТЕЛЯ НА ПРАВОТО.</p>
        <p dir="ltr">Освен за ограничената цел да посочи, че ПРОИЗВЕДЕНИЕТО е предоставено за използване съгласно
          настоящия Договор, никоя от страните няма права да използва търговската марка &bdquo;Creative Commons&ldquo;
          или която и да е свързана с нея търговска марка или знак, без предварителното писмено съгласие от страна на
          &bdquo;Криейтив Комънс&ldquo;. Всяко разрешено използване следва да бъде в съответствие с действащите към този
          момент указания на &bdquo;Криейтив Комънс&ldquo; за използване на търговската марка, които биха могли да бъдат
          публикувани на уеб-страницата му или достъпни по друг начин при поискване.</p>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: 'PrivacyPolicyView',
  components: {},
  props: {},
  data() {
    return {}
  },


}
</script>

<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background-color: $color-grey-100;
  padding-top: 100px;
  color: $color-green-dark;

}

.page-title {
  @include title-h4-bold;
  text-transform: uppercase;
  margin-bottom: $spacing-8;
}


@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 30px;

  }
  .paragraph-text {
    padding: 0 300px;
  }
  .page-title {
    @include title-h2-bold;
  }
}

@media screen and (min-width: 1500px) {


}
</style>