<template>
  <div class="component-wrapper ">
    <div class="date-wrapper">
      <div class="next-game-date"> {{ nextGameDate }}</div>
      <div class="next-game-day-hour"> {{ nextGameDay }} | {{ nextGameHour }}</div>
    </div>
    <div class="teams-wrapper d-flex justify-content-between align-items-center">
      <div class="home-team">
        <div class="team-logo" :style="{ backgroundImage: `url(${homeImgSrc})` }"></div>
      </div>
      <div class="result-separator"> {{ homeScore }}:{{ awayScore }}</div>
      <div class="away-team">
        <div class="team-logo" :style="{ backgroundImage: `url(${awayImgSrc})` }"></div>
      </div>
    </div>
    <div class="stadium-competition-wrapper">
      <div class="d-flex align-items-center">
        <StadiumIcon v-if="stadium"/>
        <div v-else>--</div>
        <div class="stadium ms-2" v-if="stadium"> {{ $t(stadium) }}</div>
      </div>

      <div class="d-flex align-items-center">
        <LeagueIcon v-if="competition"/>
        <div class="competition ms-2" v-if="competition"> {{ $t(competition) }}</div>
      </div>

    </div>


  </div>
</template>

<script>


import LeagueIcon from "@/components/Common/Icons/LeagueIcon.vue";
import StadiumIcon from "@/components/Common/Icons/StadiumIcon.vue";

export default {
  name: 'NextGamesCard',
  components: {StadiumIcon, LeagueIcon,},
  props: {
    nextGameDate: {
      type: String
    },
    nextGameDay: {
      type: String
    },
    nextGameHour: {
      type: String
    },
    stadium: {
      type: String
    },
    competition: {
      type: String
    },
    homeImgSrc: {
      type: String
    },
    awayImgSrc: {
      type: String
    },
    homeScore: {
      type: String
    },
    awayScore: {
      type: String
    },

  }
}
</script>

<style lang="scss" scoped>
.component-wrapper {
  background: $color-white;
  color: $color-green-dark;
  @include body-16-regular;
  text-transform: none;
  border-radius: 30px;
  text-align: left;
  min-width: 280px;

  .team-logo {
    width: 70px;
    height: 70px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .stadium-competition-wrapper, .date-wrapper, .teams-wrapper {
    padding: 20px 30px;
  }

  .date-wrapper {
    border-bottom: 1px solid $color-grey-100;

    .next-game-date {
      @include title-h3-bold;
    }
  }

  .result-separator {
    font-size: 30px;

  }

}


@media screen and (min-width: 768px) {

  .component-wrapper {
    min-width: 350px;
    @include body-18-regular;

    .team-logo {
      width: 75px;
      height: 75px;
    }

    .date-wrapper {
      .next-game-date {
        @include title-h2-bold;
      }
    }

    .result-separator {
      font-size: 70px;
    }
  }

}

@media screen and (min-width: 1440px) {

  .component-wrapper {
    min-width: 380px;

    .team-logo {
      width: 100px;
      height: 100px;
    }


  }

}


</style>
