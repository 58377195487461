<template>
  <svg
      :width="width"
      :height="height"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-svg"
  >
    <circle
        cx="7"
        cy="7"
        r="6"
        stroke="none"
        stroke-width="2"
        fill="none"
        class="icon-path-circle"
    />
    <path
        d="M14.365 14.7725C14.7916 15.1232 15.4218 15.0617 15.7725 14.635C16.1232 14.2084 16.0617 13.5782 15.635 13.2275L14.365 14.7725ZM11.072 12.0654L14.365 14.7725L15.635 13.2275L12.3421 10.5204L11.072 12.0654Z"
        class="icon-path"
    />
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
  props: {
    primaryColor: {
      type: String,
      default: '#0E4127', // Default primary color
    },
    hoverColor: {
      type: String,
      default: '#FFFFFF', // Default hover color
    },
    width: {
      type: Number,
      default: 17,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>

<style scoped lang="scss">
.icon-svg {
  transition: fill 0.3s ease;
}

.icon-path {
  fill: var(--primary-color, #0E4127); /* Default fill color */
}

.icon-path-circle {
  stroke: var(--primary-color, #0E4127); /* Default fill color */
}

.menu-item:hover .icon-svg .icon-path-circle {
  stroke: var(--hover-color, #FFFFFF); /* Fill color on parent hover */
}

.menu-item:hover .icon-svg .icon-path {
  fill: var(--hover-color, #FFFFFF); /* Fill color on parent hover */


}
</style>
