<template>
  <div class="carousel-wrapper">
    <Carousel>
      <Slide v-for="(item, index) in carouselItems" :key="index">
        <div class="carousel__item">
          <div class="carousel-image"
               :style="{ backgroundImage: `url(/storage/${item.content.gallery[0]})` }"></div>
          <div class="carousel-content">
            <div class="carousel-title">{{ item.content.title }}</div>
            <div class="carousel-button">
              <router-link :to="{name: 'OneNewsView', params: {id: item.id }}">
                <MainButton :theme="'solid-green'" :button-text="$t('read-more')" :icon="'arrow'" :size="'large'"/>
              </router-link>

            </div>
            <div class="carousel-pagination-number d-none d-md-block">{{ index + 1 }}<span>/{{
                carouselItems.length
              }}</span>
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <div class="carousel-pagination">
          <Pagination/>
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Carousel, Pagination, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
import MainButton from "@/components/Common/Buttons/MainButton.vue";

export default defineComponent({
  name: 'IndexCarousel',
  components: {
    MainButton,
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    carouselItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },

})
</script>

<style lang="scss">
.carousel-wrapper {
  position: relative;

  .carousel-content {
    z-index: 9999;
    position: absolute;
    bottom: 100px;
    padding: 0 20px;
    color: $color-green-dark;
    width: 100%;

    .carousel-button {
      margin-top: $spacing-3;


    }

    .carousel-title {
      text-transform: uppercase;
      font-family: $font-black;
      font-size: 30px;
      line-height: 30px;
      text-align: left;

    }

  }

  .carousel-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 94vh;
    width: 100%;
    position: relative;
    transition: 0.5s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .carousel-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%); /* Adjusted opacity */
    z-index: 1;
  }

  .carousel__item {
    width: 100%;
    color: var(--vc-clr-white);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .carousel-pagination {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, 8%);
  }
}

@media screen and (min-width: 768px) {
  .carousel-wrapper {
    .carousel-image {
      height: 94.5vh;
    }

    .carousel-content {
      bottom: 100px;
      left: 50px;
      padding: 0;

      .carousel-button {
        width: 220px;
        transition: 0.3s;

        &:hover {
          width: 230px;
        }
      }

      .carousel-title {
        max-width: 80%;
      }

      .carousel-pagination-number {
        font-size: 50px;
        line-height: 30px;
        position: fixed;
        right: 5%;
        bottom: 5%;

        span {
          font-size: 30px;
        }
      }
    }

    .carousel-pagination {
      position: absolute;
      bottom: 6%;
      left: 15%;
      transform: translate(-15%, 6%);
    }

    .carousel__item {
      border-radius: 20px;
      margin: 0 5px;
    }
  }
}


</style>
