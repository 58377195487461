<template>
  <div>
    <input :placeholder="label" :type="type" :value="modelValue" class="input" @input="handleInput">
  </div>

</template>

<script>
export default {
  name: 'MainInput',
  props: {
    type: {
      type: String,
    },
    label: {
      type: String,
    },
    theme: {
      type: String
    },
    modelValue: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);
    }
  }
}
</script>


<style lang="scss" scoped>
input {

  border: none;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  background: $color-grey-100;

}


.input:hover {
  border: none;
}

.input:active {
  border: none;
}

.input:focus {
  outline: none;
}
</style>