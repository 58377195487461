<template>
  <div class="carousel-wrapper">
    <Carousel v-bind="settings" :breakpoints="breakpoints">
      <Slide v-for="(item, index) in carouselItems" :key="index">
        <div class="carousel__item">
          <NextGamesCard
              :competition="item.competition"
              :stadium="item.stadium"
              :nextGameDate="item.date"
              :nextGameDay="$t(item.day)"
              :nextGameHour="item.hour"
              :homeImgSrc="item.homeTeamLogo"
              :awayImgSrc="item.awayTeamLogo"
              :homeScore="item.homeScore"
              :awayScore="item.awayScore"
          />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Carousel, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
import NextGamesCard from "@/components/Components/NextGamesCard/NextGamesCard.vue";


export default defineComponent({
  name: 'NextGamesCarousel',
  components: {
    NextGamesCard,
    Carousel,
    Slide,
  },
  props: {
    carouselItems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1.25,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1.9,
        snapAlign: 'center',
      },
      900: {
        itemsToShow: 2.3,
        snapAlign: 'center',
      },
      1050: {
        itemsToShow: 2.8,
        snapAlign: 'center',
      },
      1250: {
        itemsToShow: 3.3,
        snapAlign: 'center',
      },
      // 1024 and up
      1800: {
        itemsToShow: 4.5,
        snapAlign: 'start',
      },
    },
  }),

})
</script>

<style lang="scss" scoped>
.carousel-wrapper {
  position: relative;

  .carousel-content {
    z-index: 9999;
    position: absolute;
    bottom: 100px;
    padding: 0 20px;
    color: $color-green-dark;


    .carousel-button {
      margin-top: $spacing-3;

    }

    .carousel-title {
      text-transform: uppercase;
      font-family: $font-black;
      font-size: 30px;
      line-height: 30px;
      text-align: left;

    }

  }

  .carousel-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    width: 100%;
    position: relative;
    transition: 0.5s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .carousel-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%); /* Adjusted opacity */
    z-index: 1;
  }

  .carousel__item {
    width: 100%;
    color: var(--vc-clr-white);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .carousel-pagination {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, 8%);
  }
}

@media screen and (min-width: 768px) {
  .carousel-wrapper {
    .carousel-image {
      height: 900px;
    }

    .carousel-content {
      bottom: 100px;
      left: 50px;
      padding: 0;

      .carousel-button {
        width: 40%;
        transition: 0.3s;

        &:hover {
          width: 40.5%;
        }
      }

      .carousel-title {
        max-width: 80%;
      }

      .carousel-pagination-number {
        font-size: 50px;
        line-height: 30px;
        position: fixed;
        right: 5%;
        bottom: 5%;

        span {
          font-size: 30px;
        }
      }
    }

    .carousel-pagination {
      position: absolute;
      bottom: 6%;
      left: 15%;
      transform: translate(-15%, 6%);
    }

    .carousel__item {
      border-radius: 8px;
      margin: 0 5px;
    }
  }
}


</style>
