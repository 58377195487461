<template>
  <div class="site-header d-md-flex align-items-center">
    <div class="d-md-flex align-items-center">
      <SiteHeader/>
    </div>
  </div>
  <div v-if="isLoading">
    <div class="preloader d-flex justify-content-center align-items-center">
      <img src="/static/preloader.gif " alt=""/>
    </div>
  </div>
  <router-view></router-view>
  <div class="site-footer">
    <SiteFooter/>
  </div>
</template>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 9998;
}
.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

@media screen and (min-width: 768px) {
  .site-header {
    position: fixed;
    left: 20px;
    top: 20px;
    bottom: 20px;
    width: auto;
  }
}

@media screen and (min-width: 1450px) {
  .site-header {
    left: 60px;
  }
}
</style>

<script>
import SiteHeader from "@/components/Components/SiteHeader/SiteHeader.vue";
import SiteFooter from "@/components/Components/SiteFooter/SiteFooter.vue";

export default {
  components: {
    SiteFooter,
    SiteHeader
  },
  watch: {
    '$route'() {
      window.scrollTo({top: 0});
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.utils.isLoading;
    }
  }
}
</script>
