<template>
  <div class="page-wrapper">
    <div v-for="(item, index) in games" :key="index">
      <div class="games-card">
        <GamesCard
            :competition="item.competition"
            :stadium="item.stadium"
            :nextGameDate="item.date"
            :nextGameDay="$t(item.day)"
            :nextGameHour="item.hour"
            :homeImgSrc="item.homeTeamLogo"
            :awayImgSrc="item.awayTeamLogo"
            :homeScore="item.homeScore"
            :awayScore="item.awayScore"
        />
      </div>

    </div>

  </div>

</template>

<script>

import GamesCard from "@/components/Components/GamesCard/GamesCard.vue";


export default {
  name: 'GameList',
  components: {GamesCard,},
  props: {},
  data() {
    return {
      games: null,
    }
  },
  async mounted() {
    this.loadGames();
  },
  watch: {
    async '$route'() {
      await this.loadGames();
    }
  },
  methods: {
    async loadGames() {
      const currentDate = new Date();
      const futureDate = new Date(currentDate.getTime() + 120 * 60000); // Add 120 minutes
      let vm = this;

      this.games = (await this.$store.dispatch("nextGames/getAllGames")).filter(game => {
        const [day, month] = game.date.split(".");
        const [hours, minutes] = game.hour.split(":");

        const gameDate = new Date(game.year, parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));

        if (vm.$route.name === "GamesPrevious") {
          return gameDate < futureDate;
        } else {
          return gameDate > futureDate;
        }
      });

      if (vm.$route.name === "GamesNext") {
        this.games.sort((a, b) => a - b).reverse();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.page-wrapper {
  font-family: $font-regular;
  background-color: $color-grey-100;
  padding-top: 20px;
  color: $color-green-dark;
}


.games-card {
  padding: 20px 0;
}


@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 30px;

  }


}

@media screen and (min-width: 1500px) {


}
</style>