<template>
  <article v-bind:class="{
    'player-thumbnail': true,

  }">
    <div class="player-thumbnail-info d-flex">
      <div>
        <div class="player-thumbnail-title">{{ title }}</div>
        <div class="player-thumbnail-date">{{ category }}</div>
      </div>
      <div class="ms-auto player-number"> {{ number }}</div>
    </div>
    <div class="thumbnail-container">

      <div :style="`background-image: url(${image})`" class="thumbnail-image"></div>

    </div>

  </article>
</template>

<script>


export default {
  name: 'OnePlayerThumbnail',
  components: {},
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    number: {
      type: String
    }
  },
  computed: {
    image() {
      return this.$props.imgSrc ? '/storage/' + this.$props.imgSrc : '/static/placeholder.png';
    }
  }
}
</script>

<style lang="scss" scoped>
.player-thumbnail {
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  font-family: $font-regular;
  color: $color-green-dark;

  .thumbnail-container {
    overflow: hidden;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    position: relative;
    height: 400px;
    width: 100%;
    padding-top: $spacing-2;

    .thumbnail-image {
      transition: 0.3s ease-in-out;
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
    }
  }

  .player-thumbnail-info {
    background: $color-white;
    border-bottom: 1px solid $color-grey-200;
    text-align: left;
    padding: $spacing-2;
    min-height: 110px;
    transition: 0.3s ease-in-out;

    .player-thumbnail-date {
      font-size: 12px;

    }

    .player-number {
      @include title-h1-bold;
    }

    .player-thumbnail-title {
      @include title-h4-bold;
      text-transform: uppercase;
      max-width: 90%;
    }
  }

}

@media screen and (min-width: 768px) {
  .player-thumbnail {

    .thumbnail-container {

      height: 400px;
      width: 100%;
    }


    &:hover {
      .thumbnail-container, .player-thumbnail-info {
        background: $color-green-main;
        transition: 0.3s ease-in-out;
        color: $color-white;
      }


    }
  }
}

</style>