<template>
  <div v-if="news" class="page-wrapper  ">
    <div class="container-custom">
      <div class="heading">
        <OneNewsPost :content="news.content.content" :date="news.date"
                     :image-url="news.content.gallery ? news.content.gallery[0] : null"
                     :title="news.content.title"/>
      </div>
    </div>
    <div class="more-news-wrapper">
      <div class="container-custom">
        <div class="page-subtitle ">{{ $t('all-posts') }}</div>
      </div>
      <div class="grid-carousel">
        <SelectedPostsGridCarousel :news-data="moreNews"/>
      </div>
    </div>
  </div>
</template>

<script>


import SelectedPostsGridCarousel from "@/components/Components/SelectedPostsGridCarousel/SelectedPostsGridCarousel.vue";
import OneNewsPost from "@/components/Components/OneNewsPost/OneNewsPost.vue";

export default {
  name: 'OneNewsView',
  components: {
    OneNewsPost,
    SelectedPostsGridCarousel,
  },
  watch: {
    async '$route'() {
      await this.loadNews();
    }
  },
  data() {
    return {
      news: null,
      moreNews: []
    };
  },
  async mounted() {
    await this.loadNews()
  },
  methods: {
    async loadNews() {
      const id = this.$route.params.id;

      try {
        await this.$store.dispatch("utils/showPreloader");
        this.news = await this.$store.dispatch("news/getSingleNews", {id: id});
        const result = await this.$store.dispatch("news/getNews", {limit: 4});
        this.moreNews = result.data;
      } catch (err) {
        console.log(err);
      } finally {
        await this.$store.dispatch("utils/hidePreloader");
      }
    }
  }
};

</script>

<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background: $color-grey-100;
  padding-top: 100px;
}

.heading {

  padding: 20px 0px;
}

.page-subtitle {
  @include title-h4-bold;
  padding: $spacing-5 0;
}

@media screen and (min-width: 760px) {
  .page-wrapper {
    padding-top: 30px;
  }

  .grid-carousel {
    padding: 0 20px 100px 120px;
  }


}

@media screen and (min-width: 1700px) {
  .heading {

    padding: 20px 300px;
  }
  .grid-carousel {
    padding: 25px 60px 100px 160px;
  }
}
</style>