<script>
export default {
  name: 'ModalTeamSelect',
}

</script>

<template>
  <div class="page-wrapper   ">
    <div class="inner-wrapper">
      <div class="row   ">
        <router-link :to="{ name: 'TeamView'}" class="col-md-6 ">
          <div class="image-wrapper" @click="$emit('close-modal')">
            <div class="image-left "></div>
            <div class="title mt-auto"> {{ $t('first-team') }}</div>
          </div>

        </router-link>
        <router-link :to="{ name: 'SecondTeamView'}" class="col-md-6  image-wrapper">
          <div class="image-wrapper" @click="$emit('close-modal')">
            <div class="image-right "></div>
            <div class="title  mt-auto"> {{ $t('second-team') }}</div>
          </div>

        </router-link>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-wrapper {
  position: relative;
  margin-top: 20px;
}

.title {
  @include title-h1-black;
  padding: 75px;
  color: $color-white;
  position: absolute;
  bottom: 0;
}

.inner-wrapper {
  background: $color-green-dark;
  height: 95vh;
  padding: 50px;
  border-radius: 30px;
  position: relative;

}

.image-wrapper {
  border-radius: 30px;
  overflow: hidden;

}

.image-left, .image-right {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
  width: 100%;
  transition: 0.6s ease-in-out;
  overflow: hidden;
  position: relative;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 60%;
}

.image-right:before, .image-left:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: $color-green-dark;
  opacity: 70%;
}

.image-left {
  background-image: url("~@/assets/images/team-select-left-bw.png");
}

.image-right {
  background-image: url("~@/assets/images/team-select-right-bw.png");
  transition: 0.3s ease-in-out;
}

.image-left:hover, .image-right:hover {
  transform: scale(1.1);
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  opacity: 100%;
}


</style>