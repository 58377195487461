<template>
  <div class="page-wrapper" v-if="news">
    <div class="container-custom" v-if="showLatestNews">
      <div class="page-title ">{{ $t('news') }}</div>
      <div class="page-subtitle">{{ $t('latest-posts') }}</div>
      <div class="row ">
        <div class="col-md-3 gy-3 gy-lg-0" v-for="(item, index) in lastNews" :key="index">
          <OneNewsPostThumbnail
              :id="item.id"
              :content="item.content.content"
              :title="item.content.title"
              :date="item.content.date"
              :img-src="item.content.hasOwnProperty('gallery') ? item.content.gallery[0] : null"
              :category="item.content.category"/>
        </div>
        
      </div>
    </div>
    <div class="container-custom">
      <div class="page-subtitle ">{{ $t('all-posts') }}</div>
    </div>
    <div class="all-posts-navigation">
      <div class="d-flex d-none d-md-flex container-custom">
        <div v-for="(item, index) in categories" :key="index" class="button-desktop">
          <router-link :to="{name: item.route}">
            <MainButton
                :size="'medium'"
                :theme="item.id === selectedCategoryId ? 'solid-green' : 'solid-white'"
                :button-text="item.name"/>
          </router-link>
        </div>
      </div>
      <Carousel v-if="isMobile" class="d-md-none"
                v-bind="settings">butt
        <Slide v-for="(item, index) in categories" :key="index">
          <div class="carousel__item">
            <MainButton :size="'medium'" :theme="'solid-white'" :button-text="item.name"
                        @click="selectNewsCategory(item.id)"/>
          </div>
        </Slide>
      </Carousel>
    </div>
    <div class="row g-3 container-custom pb-5">
      <div class="col-12" v-for="(item, index) in news" :key="index">
        <OneNewsPostThumbnail
            :type="'horizontal'"
            :id="item.id"
            :content="item.content.content"
            :title="item.content.title"
            :date="item.content.date"
            :img-src="item.content.hasOwnProperty('gallery') ? item.content.gallery[0] : null"
            :category="item.content.category"/>
      </div>
    </div>
    <div class="pagination-wrapper d-flex justify-content-center">
      <PaginationComponent
          v-on:page-changed="handlePageChange"
          :total-pages="totalPages"
          :current-page="currentPage"/>
    </div>
  </div>
</template>

<script>
import OneNewsPostThumbnail from "@/components/Components/OneNewsPostThumbnail/OneNewsPostThumbnail.vue";
import MainButton from "@/components/Common/Buttons/MainButton.vue";
import {Carousel, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
import PaginationComponent from "@/components/Components/PaginationComponent/PaginationComponent.vue";

export default {
  name: 'NewsView',
  components: {PaginationComponent, MainButton, OneNewsPostThumbnail, Carousel, Slide},
  data() {
    return {
      news: [],
      lastNews: [],
      categories: [],
      currentPage: 1,
      totalPages: 0,
      selectedCategoryId: null,
      newsPerPage: 5,
      isMobile: false, // Track whether the screen is mobile
      settings: {
        itemsToShow: 2.2,
        snapAlign: 'start',
      },
      showLatestNews: false
    };
  },
  watch: {
    async '$route'() {
      this.setCategoryIdFromRouteName();
      await this.loadNewsByCategory();
    }
  },
  async mounted() {
    if (this.$route.name === 'NewsView') {
      this.showLatestNews = true;
    }

    this.categories = [
      {id: null, route: 'NewsView', name: this.$t('all-news')},
      {id: 'first-team', route: 'NewsFirstTeam', name: this.$t('first-team')},
      {id: 'academy', route: 'NewsAcademy', name: this.$t('academy')},
      {id: 'history', route: 'NewsHistory', name: this.$t('history')}
    ];

    this.setCategoryIdFromRouteName();
    await this.loadNewsByCategory();

    // Check screen size on mount
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768; // Define mobile screen size threshold
    },
    async loadNewsByCategory() {
      const offset = (this.currentPage - 1) * this.newsPerPage
      const search = this.$route.query.s ?? null;

      let params = {
        offset: offset,
        limit: this.newsPerPage,
      }

      if (this.selectedCategoryId) {
        params['categoryId'] = this.selectedCategoryId;
      }

      if (search) {
        params['search'] = search;
      }

      try {
        this.news = [];
        await this.$store.dispatch("utils/showPreloader");
        const result = await this.$store.dispatch("news/getNews", params);
        this.news = result.data;
        this.totalPages = Math.ceil(result.total / this.newsPerPage);
      } catch (err) {
        console.log(err);
      } finally {
        await this.$store.dispatch("utils/hidePreloader");
      }

      if (this.$route.name === 'NewsView' && !this.$route.query.s) {
        this.showLatestNews = true;
        this.lastNews = this.news.slice(0, 4);
      } else {
        this.showLatestNews = false;
      }
    },
    setCategoryIdFromRouteName() {
      this.categories.forEach((item) => {
        if (item.route === this.$route.name) {
          this.selectedCategoryId = item.id
        }
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadNewsByCategory()
    }
  }
};
</script>

<style scoped lang="scss">
.page-wrapper {
  background: $color-grey-100;
  padding-top: 100px;

  .page-title {
    @include title-h3-bold;
    text-transform: uppercase;
    margin-bottom: $spacing-2;
  }

  .page-subtitle {
    @include title-h4-bold;
    padding: $spacing-5 0;
  }

  .carousel__item {
    width: 100%;
    margin: 0 10px;
  }

  .pagination-wrapper {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 30px;

    .page-title {
      margin-bottom: $spacing-5;
    }

    .button-desktop {
      margin: 0 10px;
    }
  }
}

</style>
