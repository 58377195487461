<template>
  <svg
      :width="width"
      :height="height"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-svg"
  >
    <path
        d="M4.47349 22C4.24297 21.9488 4.00793 21.9096 3.78042 21.8463C1.80819 21.2947 0.590797 20.0091 0.108662 18.0288C0.031822 17.7138 0.00922192 17.3792 0.00771524 17.0537C0.000181883 13.9897 -0.00433813 10.9273 0.00620857 7.86486C0.0137419 5.52281 1.66355 3.50179 3.95369 3.02253C4.31831 2.94567 4.69799 2.92004 5.07164 2.91703C6.52709 2.90497 7.98253 2.911 9.43948 2.91251C9.98942 2.91251 10.3435 3.18831 10.4294 3.66757C10.5122 4.12874 10.2109 4.5869 9.75137 4.68486C9.62631 4.71199 9.49372 4.71953 9.36566 4.71953C7.84844 4.72405 6.33122 4.71199 4.814 4.73008C3.37965 4.74666 2.02967 5.97645 1.85038 7.40067C1.82326 7.62372 1.80819 7.84978 1.80819 8.07434C1.80518 11.0418 1.80367 14.0093 1.80819 16.9753C1.8097 18.5337 2.84026 19.8041 4.3635 20.1267C4.58197 20.1734 4.81099 20.193 5.03397 20.193C8.04883 20.1975 11.0622 20.199 14.077 20.1945C15.8353 20.1915 17.238 18.8366 17.2697 17.0733C17.2968 15.5903 17.2757 14.1058 17.2787 12.6228C17.2787 12.5007 17.2877 12.3756 17.3118 12.2566C17.3977 11.8346 17.806 11.5226 18.2204 11.5497C18.6829 11.5814 19.0415 11.916 19.0731 12.3651C19.0912 12.6107 19.0867 12.8564 19.0867 13.102C19.0837 14.4826 19.1108 15.8646 19.0671 17.2451C19.0189 18.7823 18.3289 20.0212 17.128 20.9691C16.4214 21.5268 15.6108 21.8508 14.7158 21.9578C14.6782 21.9623 14.6405 21.9834 14.6044 21.997H4.47349V22Z"
        class="icon-path"
    />
    <path
        d="M21 6.08345C20.9442 6.33514 20.8945 6.58683 20.8312 6.837C20.4094 8.50537 18.856 9.87382 17.161 10.1541C15.1029 10.4947 13.0719 9.38853 12.204 7.5363C11.3829 5.78203 11.8078 3.55604 13.2361 2.24938C14.0994 1.46116 15.1044 1.02712 16.2721 0.999987C18.4658 0.95176 20.3356 2.38954 20.8704 4.52058C20.9216 4.72404 20.9578 4.93202 21 5.1385V6.08195V6.08345ZM19.1965 5.61324C19.201 4.07448 17.9399 2.8055 16.4046 2.79947C14.8573 2.79344 13.5796 4.06544 13.5842 5.60721C13.5872 7.14445 14.8558 8.41193 16.3896 8.41344C17.9249 8.41494 19.192 7.14898 19.1965 5.61173V5.61324Z"
        class="icon-path"
    />
    <path
        d="M11.0469 15.2648C10.5346 15.2648 6.85837 15.2708 6.3461 15.2632C5.96039 15.2572 5.65905 15.0884 5.49332 14.7297C5.33964 14.3952 5.38785 14.0741 5.61536 13.7878C5.77055 13.5919 5.97545 13.4683 6.23008 13.4668C7.33598 13.4623 11.6059 13.4547 12.7118 13.4698C13.1442 13.4758 13.4938 13.8511 13.5224 14.2761C13.5525 14.7252 13.2708 15.1276 12.8474 15.2256C12.7359 15.2512 12.6184 15.2617 12.5024 15.2617C12.0172 15.2663 11.5321 15.2632 11.0484 15.2617L11.0469 15.2648Z"
        class="icon-path"
    />
    <path
        d="M11.0212 12.0591C10.5165 12.0591 6.84621 12.0622 6.34147 12.0591C5.80962 12.0561 5.3998 11.6567 5.40583 11.1519C5.41185 10.653 5.81414 10.2597 6.33695 10.2582C7.36902 10.2536 11.5636 10.2536 12.5957 10.2582C13.132 10.2597 13.5283 10.653 13.5253 11.1669C13.5223 11.6809 13.126 12.0561 12.5806 12.0591C12.0608 12.0606 11.5425 12.0591 11.0227 12.0591H11.0212Z"
        class="icon-path"
    />
  </svg>
 

</template>

<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: '#0E4127', // Default primary color
    },
    hoverColor: {
      type: String,
      default: '#FFFFFF', // Default hover color
    },
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 22,
    },
  },
};
</script>

<style scoped lang="scss">
.icon-svg {
  transition: fill 0.3s ease;
}

.icon-path {
  fill: var(--primary-color, #0E4127); /* Default fill color */
}

.menu-item:hover .icon-svg .icon-path {
  fill: var(--hover-color, #FFFFFF); /* Fill color on parent hover */
}
</style>
