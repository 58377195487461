<template>
  <div class="season-cards-wrapper">
    <div class="  season-cards-container">
      <div class="row g-md-0">
        <div class="col-md-4  left-column-wrapper">
          <div class="d-flex justify-content-center align-items-center">
            <SeasonCardsText/>
          </div>
          <div class="button-container">
            <router-link class="d-md-flex align-items-center " :to="{name: 'ContactsView'}">
              <div class="d-flex justify-content-center">
                <CircleButton :theme="'solid-white'" :size="'large'"/>
              </div>
              <div class="ms-2 mt-2 mt-md-0 button-placeholder"> {{ $t('learn-more-for-membership') }}</div>
            </router-link>
          </div>
        </div>
        <div class="col-md-8 g-md-0 right-column-wrapper">
          <div class="flag-image">

          </div>
          <div class="membership-content d-flex justify-content-center align-items-center">
            <div class="title-container ">
              <div class="membership-title"> {{ $t('index-membership-title') }}</div>
              <div class="membership-subtitle"> {{ $t('index-membership-subtitle') }}</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>

import SeasonCardsText from "@/components/Common/Icons/SeasonCardsText.vue";
import CircleButton from "@/components/Common/Buttons/CircleButton.vue";

export default {
  name: 'SeasonCardsComponent',
  components: {CircleButton, SeasonCardsText},
}
</script>

<style lang="scss" scoped>
.season-cards-wrapper {
  margin-top: 50px;
}

.season-cards-container {
  background: $color-green-dark;
  border-radius: 60px;
  overflow: hidden;
  color: $color-white;

  .left-column-wrapper {
    padding: 40px;
    box-shadow: 0 4px 80.5px 0 rgba(0, 166, 81, 0.8);
    background: $color-green-main;
    border-bottom-right-radius: 60px;
    border-bottom-left-radius: 60px;
    padding-bottom: 100px;
    position: relative;

    .button-container {
      position: absolute;
      bottom: -15%;
      right: 50%;
      width: 220px;
      transform: translateX(50%);
      z-index: 100;

      .button-placeholder {
        @include body-14-regular;
        font-family: $font-bold;
        line-height: 18px;
        width: 100%;
      }
    }

  }

  .right-column-wrapper {
    position: relative;
    height: 500px;

    .membership-content {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 40px;
      z-index: 9;
      top: 0;
      left: 0;

      .title-container {
        margin-top: 100px;
      }

      .membership-title {
        @include title-h2-bold;
        text-transform: uppercase;
        font-family: $font-black;

      }

      .membership-subtitle {
        @include title-h4-bold;
        font-family: $font-regular;
      }
    }


    .flag-image {
      background: no-repeat bottom url("@/assets/images/flag-waving.png");
      background-size: contain;
      mix-blend-mode: overlay;
      height: 100%;
      width: 100%;
      opacity: 100%;

    }


  }
}

@media screen and (min-width: 768px) {
  .season-cards-wrapper {
    padding: 0 20px 0 120px;
  }

  .season-cards-container {
    .left-column-wrapper {
      border-bottom-right-radius: 60px;
      border-top-right-radius: 60px;
      border-bottom-left-radius: 0;
      padding: 50px;

      .button-container {
        bottom: auto;
        top: 50%;
        right: -170px;
        width: 220px;
        transform: translateY(-50%);

        .button-placeholder {
          @include body-12-bold;
          width: 80px;
        }
      }
    }

    .right-column-wrapper {
      height: auto;


      .membership-content {
        padding: 0;

        .title-container {
          margin-top: 0;
          padding-left: 150px;
        }

        .membership-title {
          width: 100%;
          @include title-h3-bold;
        }

        .membership-subtitle {
          width: 90%;
          @include body-16-regular;
        }

      }

      .flag-image {
        background-position: right;
      }
    }
  }
}


@media screen and (min-width: 1050px) {


  .season-cards-container {

    .right-column-wrapper {


      .membership-content {

        .title-container {
          margin-top: 0;
          padding-left: 200px;
        }

        .membership-title {
          width: 85%;
          @include title-h2-bold;
        }

        .membership-subtitle {
          width: 80%;
          @include title-h5-regular
        }

      }

    }
  }
}

@media screen and (min-width: 1400px) {
  .season-cards-wrapper {
    padding: 0 60px 0 160px;
  }

  .season-cards-container {

    .right-column-wrapper {

      .membership-content {
        .title-container {
          padding-left: 300px;
        }

        .membership-title {
          width: 80%;
          @include title-h1-bold;
        }

        .membership-subtitle {
          @include title-h4-regular;
          width: 60%;
        }

      }

    }
  }
}
</style>
