<template>
  <div class="standings-wrapper" v-if="standings">
    <TableComponent :standings="standings"/>
  </div>
</template>

<script>

import TableComponent from "@/components/Components/TableComponent/TableComponent.vue";

export default {
  name: 'GamesStandings',
  components: {TableComponent},
  props: {},
  data() {
    return {
      standings: [],
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("utils/showPreloader");
      this.standings = await this.$store.dispatch("nextGames/getStandings");
    } catch (err) {
      console.log(err)
    } finally {
      await this.$store.dispatch("utils/hidePreloader");
    }
  },
}
</script>

<style lang="scss">


.standings-wrapper {;
  padding: 50px 0;
}


.bh-table-responsive table.bh-table-striped tbody tr:nth-child(odd) {
  background-color: $color-grey-200;
}

@media screen and (min-width: 768px) {


}

@media screen and (min-width: 1500px) {


}
</style>