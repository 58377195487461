<template>
  <div class="number-container d-flex flex-row">
    <div
        class="numbers"
        v-for="page in totalPages"
        :key="page"
        :class="{ 'number-highlight': page === currentPage }"
        @click="changePage(page)"
    >
      {{ page }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.number-container {
  border-radius: 224px;
  background-color: $color-white;
  color: $color-green-dark;
  text-align: center;
  overflow: hidden;
  @include body-16-bold;

  .numbers {
    padding: 15px;
    cursor: pointer;

    &:hover {
      background: $color-green-main;
      color: #fff;
    }

    &:first-child {
      padding-left: 25px;
    }
    &:last-child {
      padding-left: 25px;
    }
  }
}

.number-highlight {
  font-weight: 700;
  color: rgba(192, 255, 111, 1);
}

@media screen and (min-width: 768px) {
  .number-container {
    .numbers {
      padding: 20px;
    }
  }
}
</style>
