<template>
  <div v-if="isModalVisible" class="modal-wrapper">
    <div class="modal-backdrop " @click="hideModal"></div>
    <div class="modal-body">
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactFormModal',
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
      this.$emit('onClose');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  padding-top: 20px;

  .modal-title {
    font-family: $font-bold;
  }
}

.modal-wrapper {
  .modal-body {
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 30px;
    z-index: 999;
    background-color: #fff;
    width: 500px;
    transform: translate(-50%, -50%);
    padding: 50px
  }

  .modal-backdrop {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(1, 1, 1, 0.3);
  }
}


@media screen and (min-width: 1024px) {

}

</style>