import axios from "axios";

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async getAcademy({state},) {
            if (!state) {
                return;
            }
            const response = await axios.get('/api/pages/academy');
            return response.data;
        }
    }
};
