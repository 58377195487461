<template>
  <svg class="black icon" :width="width" :height="height" :viewBox="`0 0 ${parseFloat(width)} ${parseFloat(height)}`"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.04">
      <rect x="-47.0098" y="4.02051" width="202.4" height="70.9678" rx="12" stroke="black" stroke-width="8"/>
      <rect x="28.9902" y="101.414" width="202.4" height="70.9678" rx="12" stroke="black" stroke-width="8"/>
      <rect x="-47.0098" y="197.492" width="202.4" height="70.9678" rx="12" stroke="black" stroke-width="8"/>
      <circle cx="-14.8477" cy="28.875" r="12.4375" stroke="black" stroke-width="8"/>
      <circle cx="61.1523" cy="136.438" r="12.4375" stroke="black" stroke-width="8"/>
      <circle cx="-14.8477" cy="233.438" r="12.4375" stroke="black" stroke-width="8"/>
      <path d="M15.8359 20.1084H87.0651" stroke="black" stroke-width="8" stroke-linecap="round"/>
      <path d="M91.8359 129.862H163.065" stroke="black" stroke-width="8" stroke-linecap="round"/>
      <path d="M18.0273 226.862H89.2565" stroke="black" stroke-width="8" stroke-linecap="round"/>
      <path d="M15.8359 33.2583H45.4234" stroke="black" stroke-width="8" stroke-linecap="round"/>
      <path d="M91.8359 143.012H121.423" stroke="black" stroke-width="8" stroke-linecap="round"/>
      <path d="M18.0273 240.012H47.6148" stroke="black" stroke-width="8" stroke-linecap="round"/>
      <path
          d="M195.074 48.6001C195.074 50.257 196.417 51.6001 198.074 51.6001C199.731 51.6001 201.074 50.257 201.074 48.6001L195.074 48.6001ZM200.196 5.93295C199.024 4.76137 197.124 4.76137 195.953 5.93295L176.861 25.0248C175.689 26.1964 175.689 28.0959 176.861 29.2675C178.033 30.439 179.932 30.439 181.104 29.2675L198.074 12.2969L215.045 29.2675C216.216 30.439 218.116 30.439 219.287 29.2675C220.459 28.0959 220.459 26.1964 219.287 25.0248L200.196 5.93295ZM201.074 48.6001L201.074 8.05426L195.074 8.05426L195.074 48.6001L201.074 48.6001Z"
          fill="black"/>
      <path
          d="M201.074 209C201.074 207.343 199.731 206 198.074 206C196.417 206 195.074 207.343 195.074 209L201.074 209ZM195.953 251.667C197.124 252.839 199.024 252.839 200.196 251.667L219.287 232.575C220.459 231.404 220.459 229.504 219.287 228.333C218.116 227.161 216.216 227.161 215.045 228.333L198.074 245.303L181.104 228.333C179.932 227.161 178.033 227.161 176.861 228.333C175.689 229.504 175.689 231.404 176.861 232.575L195.953 251.667ZM195.074 209L195.074 249.546L201.074 249.546L201.074 209L195.074 209Z"
          fill="black"/>
    </g>
  </svg>
  <svg class="white icon" :width="width" :height="height" :viewBox="`0 0 ${parseFloat(width)} ${parseFloat(height)}`"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <rect x="-47.0098" y="4.02051" width="202.4" height="70.9678" rx="12" stroke="white" stroke-width="8"/>
      <rect x="28.9902" y="101.414" width="202.4" height="70.9678" rx="12" stroke="white" stroke-width="8"/>
      <rect x="-47.0098" y="197.492" width="202.4" height="70.9678" rx="12" stroke="white" stroke-width="8"/>
      <circle cx="-14.8477" cy="28.875" r="12.4375" stroke="white" stroke-width="8"/>
      <circle cx="61.1523" cy="136.438" r="12.4375" stroke="white" stroke-width="8"/>
      <circle cx="-14.8477" cy="233.438" r="12.4375" stroke="white" stroke-width="8"/>
      <path d="M15.8359 20.1084H87.0651" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M91.8359 129.862H163.065" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M18.0273 226.862H89.2565" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M15.8359 33.2583H45.4234" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M91.8359 143.012H121.423" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M18.0273 240.012H47.6148" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path
          d="M195.074 48.6001C195.074 50.257 196.417 51.6001 198.074 51.6001C199.731 51.6001 201.074 50.257 201.074 48.6001L195.074 48.6001ZM200.196 5.93295C199.024 4.76137 197.124 4.76137 195.953 5.93295L176.861 25.0248C175.689 26.1964 175.689 28.0959 176.861 29.2675C178.033 30.439 179.932 30.439 181.104 29.2675L198.074 12.2969L215.045 29.2675C216.216 30.439 218.116 30.439 219.287 29.2675C220.459 28.0959 220.459 26.1964 219.287 25.0248L200.196 5.93295ZM201.074 48.6001L201.074 8.05426L195.074 8.05426L195.074 48.6001L201.074 48.6001Z"
          fill="white"/>
      <path
          d="M201.074 209C201.074 207.343 199.731 206 198.074 206C196.417 206 195.074 207.343 195.074 209L201.074 209ZM195.953 251.667C197.124 252.839 199.024 252.839 200.196 251.667L219.287 232.575C220.459 231.404 220.459 229.504 219.287 228.333C218.116 227.161 216.216 227.161 215.045 228.333L198.074 245.303L181.104 228.333C179.932 227.161 178.033 227.161 176.861 228.333C175.689 229.504 175.689 231.404 176.861 232.575L195.953 251.667ZM195.074 209L195.074 249.546L201.074 249.546L201.074 209L195.074 209Z"
          fill="white"/>
    </g>
  </svg>


</template>

<script>
export default {
  name: 'StandingsIcon',
  props: {
    width: {
      type: [String, Number],
      default: '236',
    },
    height: {
      type: [String, Number],
      default: '273',
    },
    stroke: {
      type: String,
      default: '#000000',
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.icon {
  transition: fill 0.3s, stroke 0.3s ease-in-out;
  width: 100%;
  height: auto;
}

.icon-path {
  transition: fill 0.3s ease-in-out;
}

.white {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .icon-path {
  stroke: #FFFFFF;
}

.component-wrapper:hover .black {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .white {
  display: block;
  transition: fill 0.3s ease-in-out;
}
</style>
