<script>
import SearchIcon from "@/components/Common/Icons/SearchIcon.vue";
import MainInput from "@/components/Common/Inputs/MainInput.vue";

export default {
  name: 'ModalTeamSelect',
  components: {
    MainInput,
    SearchIcon
  },
  data() {
      return {
        keyword: ''
      }
  },
  methods: {
    search() {
      this.$emit('search', this.keyword)
    }
  }
}
</script>

<template>
  <div class="modal-wrapper">
    <div class="d-flex align-items-center">
      <MainInput @keydown.enter="search" :label="$t('search')" v-model="keyword" class="input"/>
      <div class="icon" @click="search">
        <SearchIcon/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-wrapper {
  margin-top: 100px;
  position: relative;
  background: white;
  width: 100%;
  padding: 20px 100px;
  border-radius: 30px;

  .input {
    width: 100%;
    margin-right: 50px;
  }

  .icon {
    cursor: pointer;
  }
}
</style>