<script>
export default {
  name: 'AcademyView',
  data() {
    return {
      coaches: [],
      coordinators: [],
      director: []
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("utils/showPreloader");
      const response = await this.$store.dispatch("academy/getAcademy");
      this.academy = response.data;
      this.coaches = response.data.filter((item) => {
        return item.content.type === 'coach'
      })
      this.coordinators = response.data.filter((item) => {
        return item.content.type === 'coordinator'
      })
      this.director = response.data.filter((item) => {
        return item.content.type === 'director'
      })
    } catch (err) {
      console.log(err);
    } finally {
      await this.$store.dispatch("utils/hidePreloader");
    }

  },
}
</script>

<template>
  <div class="page-wrapper ">
    <div class="heading d-flex justify-content-center align-items-center">
      <div class="heading-content ">
        <div class="breadcrumb d-flex justify-content-center">
          <div class="breadcrumb-item"> {{ $t('home') }}</div>
          <div class="breadcrumb-item"> {{ $t('academy') }}</div>
        </div>
        <div class="title"> {{ $t('academy') }}</div>
        <div class="subtitle"> {{ $t('academy-subtitle') }}</div>
      </div>
    </div>
    <div class="container-custom">
      <div class="body">
        <p class=""> {{ $t('academy-paragraph-1') }} </p>
        <p class=""> {{ $t('academy-paragraph-2') }} </p>
        <div class="text-center"> {{ $t('contacts') }}: <span
            class="text-decoration-underline"> academy.beroe1916@gmail.com </span></div>
        <!--        <div class="academy-team-wrapper row g-2" v-for="(item, index) in academy" :key="index">
                  <div class="col-md-4">
                    <div class="coach-photo-wrapper d-none d-md-block ">
                      <div class="coach-photo" :style="`background-image: url(/mock-storage/${ item.coachPhoto})`">
                      </div>
                      <div class="mt-auto coach-info">
                        <div class="coach-name"> {{ item.coach }}</div>
                        <div class="coach-email"> {{ item.email }}</div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="team-photo-wrapper d-flex "
                         :style="`background-image: url(/mock-storage/${ item.teamPhoto})`">
                      <div class="mt-auto team-name"> {{ item.team }}</div>
                    </div>
                  </div>

                  <div class="col-md-4 coach-photo-wrapper d-md-none">
                    <div class="coach-photo" :style="`background-image: url(${ item.coachPhoto})`">
                    </div>
                    <div class="mt-auto coach-info">
                      <div class="coach-name"> {{ item.coach }}</div>
                      <div class="coach-email"> {{ item.email }}</div>
                    </div>
                  </div>
                </div>-->
      </div>
      <div class="academy-wrapper">
        <div class="academy-container">
          <div class="d-flex academy-item" v-for="(item, index) in coaches" :key="index">
            <div class="me-auto team-name"> {{ item.content.team }}</div>
            <div> {{ item.content.coach }}</div>
          </div>
        </div>
        <div class="academy-container">
          <div class="d-flex academy-item" v-for="(item, index) in coordinators"
               :key="index">
            <div class="me-auto team-name"> {{ item.content.team }}</div>
            <div> {{ item.content.coach }}</div>
          </div>
        </div>
        <div class="academy-container">
          <div class="d-flex academy-item" v-for="(item, index) in director" :key="index">
            <div class="me-auto team-name"> {{ item.content.team }}</div>
            <div> {{ item.content.coach }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-wrapper {
  background: $color-grey-100;
}

.breadcrumb {
  @include body-16-regular;
  --bs-breadcrumb-divider-color: $color-white;
  --bs-breadcrumb-item-padding-x: 0;

}

.heading {
  background: $color-green-main;
  height: 500px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .heading-content {
    position: relative;
    z-index: 22;
    text-align: center;
    color: $color-white;

    .title {
      @include title-h1-bold;
      text-transform: uppercase;
      margin-bottom: $spacing-3;
    }

    .subtitle {
      @include title-h5-regular;
      text-transform: uppercase;
    }
  }
}

.heading:before {
  content: "";
  position: absolute;
  background: url("~@/assets/images/academy-heading.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 100%;
}

.body {
  padding: 100px 0;

  p {
    text-align: center;
  }

  .academy-team-wrapper {
    margin: 20px 0;

    .team-photo-wrapper, .coach-photo {
      min-height: 200px;
      width: 100%;
      background: no-repeat center;
      background-size: cover;
      border-radius: 30px;
      padding: 30px;

      .team-name {
        @include title-h3-bold;
        color: $color-white;
      }
    }

    .coach-photo-wrapper {
      background-color: $color-white;
      min-height: 400px;
      text-align: center;
      border-radius: 30px;
      color: $color-green-dark;
      padding: 30px;

      .coach-info {
        position: relative;
        z-index: 11;
      }

      .coach-name {
        @include title-h4-bold;
        font-family: $font-black;
        text-transform: uppercase;
      }

      .coach-photo {
        min-height: 230px;
        background-size: contain;
        position: relative;
        margin-bottom: 50px;
      }

      .coach-photo:before {
        content: "";
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 60%, rgba(255, 255, 255, 1) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
  }


}

.academy-wrapper {
  padding-bottom: 50px;

  .academy-container {
    background: $color-white !important;
    border-radius: 30px;
    padding: 20px;
    @include body-16-bold;
    text-transform: uppercase;
    margin-top: 30px;

    .academy-item {
      margin: 20px 0;
    }
  }
}


@media screen and (min-width: 700px) {
  .heading {
    height: 600px;
  }
  .body {
    p {
      padding: 0 150px;
    }

    .academy-team-wrapper {
      .team-photo-wrapper, .coach-photo {
        min-height: 400px;
      }
    }

  }
  .academy-wrapper {
    padding: 0 100px;

    .academy-container {
      padding: 30px 50px;
      @include title-h5-bold;
    }
  }
}

@media screen and (min-width: 1600px) {

  .heading {
    height: 700px;
  }
  .body {
    p {
      padding: 0 250px;
    }

  }
}


</style>