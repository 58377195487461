import axios from "axios";

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async getSingleNews({state}, payload) {
            if (!state) {
                return;
            }
            const response = await axios.get('/api/page/' + payload.id);
            return response.data;
        },

        async getNews({state}, payload = {}) {
            if (!state) {
                return;
            }

            const offset = payload.offset ?? 0;
            const limit = payload.limit ?? 10;
            const categoryId = payload.categoryId ?? null;
            const search = payload.search ?? null;
            const highlighted = payload.highlight ?? null;

            let url = '/api/pages/news?offset=' + offset + '&limit=' + limit;

            if (categoryId) {
                url += '&filter[category]=' + categoryId
            }
            if (search) {
                url += '&search[content]=' + search + '&search[title]=' + search;
            }
            if (highlighted) {
                url += '&filter[highlight]=1';
            }

            if (Object.prototype.hasOwnProperty.call(payload,'exclude')) {
                const excludedStr = payload.exclude.join(',');
                url = url + excludedStr
            }

            const response = await axios.get(url);
            return response.data;
        },
    }
};
