<template>
  <svg class="black icon" :viewBox="`0 0 ${width} ${height}`" :width="width"
       :height="height" viewBox="0 0 224 305" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.04">
      <path
          d="M126.596 -37.8341C120.212 -36.3925 103.242 -33.2622 86.437 -32.2737C65.4309 -31.038 53.0743 -34.745 51.2209 -24.2419C49.3674 -13.7388 45.6604 1.70686 49.3674 10.9743C53.0743 20.2417 63.5775 14.0634 69.7558 15.2991C75.934 16.5347 84.5836 11.5921 82.1123 26.42C79.641 41.2478 77.1697 52.3687 69.1379 59.7827C61.1062 67.1966 58.017 77.0818 67.9023 83.8779C77.7875 90.674 86.4371 96.8523 113.004 91.2919C134.257 86.8435 153.162 79.965 159.959 77.0818C156.869 73.7868 151.803 64.8488 156.252 55.4579C160.7 46.0669 174.169 33.422 180.347 28.2735C180.347 35.0696 181.459 49.6503 185.907 53.6044C189.013 56.3647 193.503 55.0704 196.982 53.1265C199.613 51.6566 202.27 50.1708 205.093 49.1159C212.205 46.4585 221.1 41.3227 219.888 34.4517C218.493 26.5459 213.706 7.08111 211.087 -3.25465C210.777 -4.47715 210.636 -5.75581 210.085 -6.89065C208.022 -11.1482 199.56 -18.88 176.64 -26.0954"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M129.068 -26.0953C125.361 -21.7705 120.665 -9.66112 131.539 4.17822C145.131 21.4774 157.488 6.64953 159.959 2.94256C161.936 -0.02301 167.373 -11.0615 169.845 -16.2101"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M53.0745 -27.331C47.1021 -28.3607 33.4275 -26.7132 26.5079 -11.8853C17.8583 6.6495 10.4443 27.0378 11.68 38.7765C12.9157 50.5153 1.17686 50.5153 -1.29445 54.8401C-3.76577 59.1648 -4.38359 80.171 -0.0587998 86.3493C4.26599 92.5275 17.8583 85.7314 24.6544 80.171C31.4505 74.6105 22.801 67.1966 28.9792 63.4896C35.1575 59.7827 34.5397 59.1648 32.0684 50.5153C29.5971 41.8657 29.5971 40.0122 47.5141 10.3565"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M186.525 54.8401C186.525 57.9292 188.379 73.9927 190.85 82.6423C193.321 91.2919 190.85 88.8206 186.525 94.9989C182.2 101.177 180.347 110.445 188.996 117.859C197.646 125.272 203.825 130.215 209.385 111.68C214.945 93.1454 213.092 105.502 210.003 93.7632C206.914 82.0245 216.799 70.2858 216.799 62.8718C216.799 57.2364 216.387 50.9272 215.563 47.4261"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M65.4314 80.7888C62.9601 83.8779 58.5117 95.9873 60.4888 119.712C62.4658 143.436 65.0197 162.136 66.0495 168.52L109.297 163.578C113.416 155.958 121.901 138.123 122.89 127.744C124.125 114.769 126.597 136.393 130.921 143.189C135.246 149.985 134.011 159.871 142.66 156.782C149.58 154.31 177.258 145.455 190.233 141.336C183.643 126.92 168.485 94.381 160.577 79.5531"
          stroke="black" class="icon-path" stroke-width="8"/>
      <path
          d="M68.5215 169.138C68.9334 178.612 71.4871 200.03 78.4067 209.915C87.0563 222.271 93.2346 219.182 109.916 216.711C113.005 206.208 118.566 198.794 114.859 186.437C111.893 176.552 107.857 167.902 106.209 164.813"
          stroke="black" class="icon-path" stroke-width="8"/>
      <path
          d="M145.132 158.017C149.251 167.491 158.106 187.302 160.578 190.762C163.667 195.087 169.845 193.233 172.934 195.087C175.405 196.57 187.556 196.94 193.322 196.94C196.617 194.057 201.725 184.831 195.794 170.992C189.863 157.152 185.908 147.102 184.673 143.807"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M114.24 186.437C117.947 183.966 126.72 179.888 132.157 183.348C138.953 187.673 135.246 183.966 136.482 197.558C137.471 208.432 138.953 231.333 139.571 241.424C139.777 245.543 137.347 252.669 125.979 248.22C111.769 242.66 113.005 219.182 112.387 209.915"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path d="M159.96 191.998L154.399 251.309L170.463 255.016C178.907 243.689 196.288 214.24 198.265 187.055"
            :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M151.927 251.309C149.868 252.957 145.625 257.487 145.131 262.43C144.513 268.608 143.278 272.315 156.87 279.729C167.744 285.66 187.761 295.381 196.411 299.5C199.706 296.205 204.69 288.008 198.265 281.583C190.233 273.551 179.73 258.723 172.316 254.398"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path d="M145.75 223.507H138.336" class="icon-path" :stroke="stroke" stroke-width="8" stroke-linecap="round"/>
      <path d="M143.278 195.087H135.864" class="icon-path" :stroke="stroke" stroke-width="8" stroke-linecap="round"/>
      <path d="M166.427 287.761L165.519 290.85" class="icon-path" :stroke="stroke" stroke-width="8"
            stroke-linecap="round"/>
      <path d="M149.663 277.876L148.755 280.965" class="icon-path" :stroke="stroke" stroke-width="8"
            stroke-linecap="round"/>
      <path d="M187.968 297.646L187.06 300.735" class="icon-path" :stroke="stroke" stroke-width="8"
            stroke-linecap="round"/>
      <circle cx="38.2479" cy="260.577" r="39.2479" class="icon-path" :stroke="stroke" stroke-width="8"/>
      <path
          d="M57.2386 229.361L57.2388 229.362L57.2386 229.361ZM57.3218 229.557L63.2861 243.597L51.7764 253.608L38.6988 245.755L42.1261 230.891L57.3218 229.557ZM41.914 230.909L41.9146 230.909L41.914 230.909Z"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M65.6811 274.751L65.681 274.752L65.6811 274.751ZM65.6312 274.958L62.054 289.787L46.8456 290.967L41.0234 276.868L52.6335 266.974L65.6312 274.958ZM52.4521 266.862L52.4526 266.863L52.4521 266.862Z"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
      <path
          d="M12.7228 275.813L12.7226 275.812L12.7228 275.813ZM12.5886 275.647L2.97343 263.805L11.2647 251.001L26.0043 254.93L26.8225 270.162L12.5886 275.647ZM27.0211 270.086L27.0206 270.086L27.0211 270.086Z"
          :stroke="stroke" class="icon-path" stroke-width="8"/>
    </g>
  </svg>
  <svg class="white icon" :viewBox="`0 0 ${width} ${height}`" :width="width" :height="height" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path
          d="M126.586 -37.834C120.202 -36.3924 103.232 -33.2621 86.4273 -32.2735C65.4211 -31.0379 53.0646 -34.7448 51.2111 -24.2418C49.3576 -13.7387 45.6506 1.70699 49.3576 10.9744C53.0646 20.2418 63.5677 14.0635 69.746 15.2992C75.9243 16.5348 84.5739 11.5922 82.1026 26.4201C79.6312 41.248 77.1599 52.3689 69.1282 59.7828C61.0964 67.1967 58.0073 77.082 67.8925 83.8781C77.7778 90.6742 86.4273 96.8524 112.994 91.292C134.247 86.8436 153.153 79.9652 159.949 77.082C156.86 73.7869 151.794 64.849 156.242 55.458C160.69 46.067 174.159 33.4221 180.337 28.2736C180.337 35.0697 181.449 49.6504 185.898 53.6045C189.003 56.3648 193.493 55.0705 196.972 53.1267C199.603 51.6567 202.26 50.1709 205.083 49.1161C212.195 46.4587 221.091 41.3228 219.878 34.4519C218.483 26.546 213.697 7.08123 211.077 -3.25453C210.767 -4.47703 210.626 -5.75569 210.076 -6.89053C208.012 -11.1481 199.55 -18.8798 176.63 -26.0953"
          stroke="white" stroke-width="8"/>
      <path
          d="M129.058 -26.0952C125.351 -21.7704 120.656 -9.661 131.529 4.17834C145.122 21.4775 157.478 6.64965 159.95 2.94268C161.927 -0.022888 167.363 -11.0614 169.835 -16.21"
          stroke="white" stroke-width="8"/>
      <path
          d="M53.0647 -27.3309C47.0924 -28.3606 33.4178 -26.7131 26.4981 -11.8852C17.8485 6.64962 10.4346 27.0379 11.6702 38.7767C12.9059 50.5154 1.16709 50.5154 -1.30422 54.8402C-3.77553 59.165 -4.39336 80.1711 -0.0685654 86.3494C4.25623 92.5277 17.8486 85.7316 24.6447 80.1711C31.4408 74.6107 22.7912 67.1967 28.9695 63.4898C35.1477 59.7828 34.5299 59.165 32.0586 50.5154C29.5873 41.8658 29.5873 40.0123 47.5043 10.3566"
          stroke="white" stroke-width="8"/>
      <path
          d="M186.515 54.8402C186.515 57.9293 188.369 73.9929 190.84 82.6424C193.311 91.292 190.84 88.8207 186.515 94.999C182.191 101.177 180.337 110.445 188.987 117.859C197.636 125.273 203.815 130.215 209.375 111.68C214.936 93.1455 213.082 105.502 209.993 93.7633C206.904 82.0246 216.789 70.2859 216.789 62.872C216.789 57.2365 216.377 50.9273 215.553 47.4263"
          stroke="white" stroke-width="8"/>
      <path
          d="M65.4216 80.7889C62.9503 83.878 58.502 95.9874 60.479 119.712C62.4561 143.437 65.0099 162.136 66.0397 168.52L109.288 163.578C113.406 155.958 121.891 138.123 122.88 127.744C124.116 114.769 126.587 136.393 130.912 143.189C135.236 149.986 134.001 159.871 142.65 156.782C149.57 154.31 177.249 145.455 190.223 141.336C183.633 126.92 168.476 94.3811 160.567 79.5532"
          stroke="white" stroke-width="8"/>
      <path
          d="M68.5117 169.138C68.9236 178.612 71.4773 200.03 78.397 209.915C87.0465 222.271 93.2248 219.182 109.906 216.711C112.995 206.208 118.556 198.794 114.849 186.437C111.883 176.552 107.847 167.903 106.199 164.813"
          stroke="white" stroke-width="8"/>
      <path
          d="M145.121 158.017C149.24 167.491 158.095 187.302 160.567 190.762C163.656 195.087 169.834 193.233 172.923 195.087C175.395 196.57 187.545 196.94 193.312 196.94C196.607 194.057 201.714 184.831 195.783 170.992C189.852 157.152 185.898 147.102 184.662 143.807"
          stroke="white" stroke-width="8"/>
      <path
          d="M114.23 186.437C117.937 183.966 126.711 179.888 132.147 183.348C138.944 187.673 135.237 183.966 136.472 197.558C137.461 208.432 138.944 231.333 139.561 241.424C139.767 245.543 137.337 252.668 125.969 248.22C111.759 242.66 112.995 219.182 112.377 209.915"
          stroke="white" stroke-width="8"/>
      <path d="M159.949 191.998L154.389 251.309L170.452 255.016C178.896 243.689 196.277 214.24 198.254 187.055"
            stroke="white" stroke-width="8"/>
      <path
          d="M151.918 251.309C149.858 252.957 145.616 257.487 145.121 262.43C144.504 268.608 143.268 272.315 156.86 279.729C167.734 285.66 187.752 295.381 196.401 299.5C199.696 296.205 204.68 288.008 198.255 281.583C190.223 273.551 179.72 258.723 172.306 254.398"
          stroke="white" stroke-width="8"/>
      <path d="M145.74 223.507H138.326" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M143.267 195.087H135.854" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M166.419 287.761L165.511 290.85" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M149.653 277.876L148.745 280.965" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <path d="M187.958 297.646L187.05 300.735" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <circle cx="38.2382" cy="260.577" r="39.2479" stroke="white" stroke-width="8"/>
      <path
          d="M57.2288 229.361L57.229 229.361L57.2288 229.361ZM57.3121 229.557L63.2763 243.597L51.7667 253.608L38.6891 245.755L42.1163 230.891L57.3121 229.557ZM41.9043 230.909L41.9048 230.909L41.9043 230.909Z"
          stroke="white" stroke-width="8"/>
      <path
          d="M65.6713 274.751L65.6712 274.752L65.6713 274.751ZM65.6214 274.958L62.0443 289.787L46.8358 290.967L41.0136 276.868L52.6237 266.974L65.6214 274.958ZM52.4423 266.862L52.4428 266.862L52.4423 266.862Z"
          stroke="white" stroke-width="8"/>
      <path
          d="M12.7131 275.812L12.7128 275.812L12.7131 275.812ZM12.5789 275.647L2.96366 263.805L11.255 251.001L25.9945 254.93L26.8127 270.162L12.5789 275.647ZM27.0114 270.085L27.0109 270.086L27.0114 270.085Z"
          stroke="white" stroke-width="8"/>
    </g>
  </svg>


</template>

<script>
export default {
  name: 'PlayerIcon',
  props: {
    width: {
      type: [String, Number],
      default: '224',
    },
    height: {
      type: [String, Number],
      default: '305',
    },
    stroke: {
      type: String,
      default: '#000000',
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.icon {
  transition: fill 0.3s, stroke 0.3s ease-in-out;
  width: 100%;
  height: auto;
}

.white {
  display: none;
  transition: fill 0.3s ease-in-out;
}


.component-wrapper:hover .black {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .white {
  display: block;
  transition: fill 0.3s ease-in-out;
}
</style>
