<script setup>

</script>

<template>
  <svg width="65" height="15" viewBox="0 0 65 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_65_1633)">
      <path d="M8.43642 3.84766H4.42578V14.6737H8.43642V3.84766Z" fill="#220F48"/>
      <path d="M4.42553 0H0V3.84783H4.42553V0Z" fill="#EB174F"/>
      <path
          d="M17.6329 3.58691C18.8775 3.58691 19.8456 3.97822 20.6063 4.76083C21.3669 5.54344 21.7818 6.71735 21.7818 8.21735V14.6739H18.0477V8.67387C18.0477 8.08691 17.9094 7.69561 17.5637 7.36952C17.2871 7.10865 16.8722 6.913 16.319 6.913C15.7658 6.913 15.2818 7.04344 14.936 7.43474C14.6594 7.82604 14.5212 8.34778 14.5212 8.93474V14.6739H10.7871V3.913H14.5212V5.0217C15.2126 4.04344 16.1807 3.58691 17.6329 3.58691Z"
          fill="#220F48"/>
      <path
          d="M30.771 7.30418H28.42V10.4346C28.42 10.8911 28.6274 11.2172 28.9732 11.3477C29.3189 11.4781 29.9413 11.4781 30.771 11.4781V14.739C28.5583 14.9998 26.9679 14.8042 26.0689 14.152C25.17 13.4998 24.6859 12.3259 24.6859 10.565V7.30418H23.0264V3.91287H24.6859V1.95635L28.42 0.847656V3.91287H30.771V7.30418Z"
          fill="#220F48"/>
      <path
          d="M35.6116 10.6304C35.9573 11.4782 36.8563 11.9347 38.101 11.9347C38.9307 11.9347 39.6222 11.6739 40.1754 11.1521L42.8722 12.9782C41.7659 14.3478 40.1063 15 38.0318 15C36.0956 15 34.5744 14.4782 33.468 13.3695C32.3616 12.326 31.7393 10.9565 31.7393 9.32604C31.7393 7.69561 32.2924 6.32604 33.3988 5.21735C34.5052 4.10865 35.9573 3.58691 37.6861 3.58691C39.3456 3.58691 40.7286 4.10865 41.835 5.21735C42.9414 6.32604 43.4946 7.63039 43.4946 9.26083C43.4946 9.71735 43.4254 10.1739 43.3563 10.5652L35.6116 10.6304ZM35.5424 8.15213H39.8297C39.5531 7.17387 38.8616 6.65213 37.6861 6.65213C36.5797 6.58691 35.8882 7.10865 35.5424 8.15213Z"
          fill="#220F48"/>
      <path
          d="M48.819 5.86974C49.0265 5.15234 49.4413 4.6306 50.0637 4.2393C50.686 3.848 51.3775 3.65234 52.2073 3.65234V7.6306C51.3775 7.50017 50.5477 7.6306 49.8562 8.08713C49.1647 8.54365 48.819 9.26104 48.819 10.1741V14.6741H45.085V3.91321H48.819V5.86974Z"
          fill="#220F48"/>
      <path
          d="M61.2662 3.913H65.0003V14.7391H61.2662V13.6956C60.5056 14.5434 59.3992 15 58.0854 15C56.5641 15 55.3194 14.4782 54.3514 13.3695C53.3141 12.2608 52.8301 10.8913 52.8301 9.32604C52.8301 7.76083 53.3141 6.32604 54.3514 5.28257C55.3194 4.17387 56.5641 3.58691 58.0854 3.58691C59.4684 3.58691 60.5056 4.04344 61.2662 4.89126V3.913ZM57.1865 11.0217C57.6014 11.4782 58.1545 11.6739 58.9152 11.6739C59.6758 11.6739 60.1599 11.4782 60.6439 11.0217C61.0588 10.5652 61.2662 10.0434 61.2662 9.32604C61.2662 8.60865 61.0588 8.0217 60.6439 7.63039C60.229 7.17387 59.6758 6.97822 58.9152 6.97822C58.1545 6.97822 57.6705 7.17387 57.1865 7.63039C56.7716 8.08691 56.5641 8.60865 56.5641 9.32604C56.5641 10.0434 56.7716 10.5652 57.1865 11.0217Z"
          fill="#220F48"/>
    </g>
    <defs>
      <clipPath id="clip0_65_1633">
        <rect width="65" height="15" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped lang="scss">

</style>