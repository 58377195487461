<template>
  <div class="page-wrapper">
    <div class=" index-heading   ">
      <div class="row g-0 g-lg-3 ">
        <div class=" col-md-6 mb-5 mb-md-0">
          <IndexCarousel :carousel-items="carousel"/>
        </div>
        <div class="col-md-6  card-navigation ">
          <div class="row g-3">
            <div class="col-xl-6 " v-for="(item, index) in items" :key="index">
              <router-link :to="{name: item.routeName}">
                <IndexHeadingCardNavigation :title="item.title" :subtitle="item.subtitle" :icon="item.icon"/>
              </router-link>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-stadium-wrapper d-flex justify-content-center align-items-center ">
      <div class="index-stadium-content w-100">
        <div class="index-stadium-subtitle"> {{ $t('index-stadium-subtitle') }}</div>
        <div class="the-world-is-beroe-svg">
          <TheWorldsIsBeroeSVG/>
        </div>

        <div class="index-fixtures container-custom">
          <IndexNextPreviousFixture :competition="nextLastGame.competition"
                                    :nextGameDate="nextLastGame.nextGameDate"
                                    :nextGameDay="nextLastGame.nextGameDay"
                                    :nextGameHour="nextLastGame.nextGameHour"
                                    :lastGameDate="nextLastGame.lastGameDate"
                                    :lastGameDay="nextLastGame.lastGameDay"
                                    :lastGameHour="nextLastGame.lastGameHour"
                                    :nextHomeTeamName="nextLastGame.nextHomeTeamName"
                                    :nextHomeTeamLogo="nextLastGame.nextHomeTeamNameLogo"
                                    :nextAwayTeamName="nextLastGame.nextAwayTeamName"
                                    :nextAwayTeamLogo="nextLastGame.nextAwayTeamNameLogo"
                                    :lastHomeTeamName="nextLastGame.lastHomeTeamName"
                                    :lastHomeTeamLogo="nextLastGame.lastHomeTeamLogo"
                                    :lastAwayTeamName="nextLastGame.lastAwayTeamName"
                                    :lastAwayTeamLogo="nextLastGame.lastAwayTeamLogo"
                                    :scoreHome="nextLastGame.scoreHome"
                                    :scoreAway="nextLastGame.scoreAway"
          />
        </div>

      </div>
    </div>
    <div class="index-next-games-wrapper ">
      <div class="d-md-flex align-items-center  container-custom">
        <div class="title me-auto">
          {{ $t('index-next-games-title') }}
        </div>
        <div class="button mt-4 mt-md-0 ">
          <router-link :to="{name: 'GamesNext'}">
            <MainButton :theme="'solid-green'" :size="'large'" :button-text="$t('all-games')" :icon="'arrow'"/>
          </router-link>

        </div>
      </div>
      <div class="next-games-carousel">
        <NextGamesCarousel :carousel-items="nextGames"/>

      </div>
      <SeasonCardsComponent/>
      <div class="title mt-5 mb-5 container-custom">
        {{ $t('index-latest-news-title') }}
      </div>
      <div class="index-latest-news">
        <IndexLatestNews :news="news"/>

      </div>

      <div class="title mt-5 mb-5 container-custom">
        {{ $t('index-players-title') }}
      </div>
      <div class="index-players-wrapper ">
        <div class="container-custom ">
          <IndexBeroeFontOutline/>
        </div>
        <div class="index-players-image"></div>
      </div>

      <div class=" container-custom  position-relative z-1">
        <div class="index-players-carousel">
          <IndexPlayersCarousel :players="players"/>
        </div>

      </div>
      <div class="index-history-wrapper">
        <div class="index-history-image">
          <div class="d-flex justify-content-center beroe-anniversary-logo">
            <BeroeAnniversaryLogo/>
          </div>
          <div class="d-flex justify-content-center beroe-1916-text">
            <Beroe1916Icon/>
          </div>

        </div>
      </div>
      <div class="history-button-wrapper">
        <div class="text-center mb-3"> {{ $t('index-team-history-title') }}</div>
        <router-link :to="{name: 'HistoryView'}">
          <div class="d-flex justify-content-center">
            <CircleButton :theme="'solid-green'" :size="'large'"/>
          </div>
        </router-link>

      </div>


    </div>

  </div>

</template>

<script>
import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import IndexHeadingCardNavigation
  from "@/components/Components/IndexHeadingCardNavigation/IndexHeadingCardNavigation.vue";
import IndexNextPreviousFixture from "@/components/Components/IndexNextPreviousFixture/IndexNextPreviousFixture.vue";
import MainButton from "@/components/Common/Buttons/MainButton.vue";
import NextGamesCarousel from "@/components/Components/NextGamesCarousel/NextGamesCarousel.vue";
import CircleButton from "@/components/Common/Buttons/CircleButton.vue";
import IndexLatestNews from "@/components/Components/IndexLatestNews/IndexLatestNews.vue";
import IndexBeroeFontOutline from "@/components/Common/Icons/IndexBeroeFontOutline.vue";
import IndexPlayersCarousel from "@/components/Components/IndexPlayersCarousel/IndexPlayersCarousel.vue";
import BeroeAnniversaryLogo from "@/components/Common/Icons/BeroeAnniversaryLogo.vue";

import Beroe1916Icon from "@/components/Common/Icons/Beroe1916Icon.vue";
import TheWorldsIsBeroeSVG from "@/components/Common/Icons/BigSVGS/TheWorldsIsBeroeSVG.vue";
import ContactsView from "@/views/ContactsView.vue";
import SeasonCardsComponent from "@/components/Components/SeasonCardsComponent/SeasonCardsComponent.vue";

export default {
  computed: {
    ContactsView() {
      return ContactsView
    }
  },
  components: {
    SeasonCardsComponent,
    TheWorldsIsBeroeSVG,
    Beroe1916Icon,
    BeroeAnniversaryLogo,
    IndexPlayersCarousel,
    IndexBeroeFontOutline,
    IndexLatestNews,
    CircleButton,
    NextGamesCarousel,
    MainButton,
    IndexNextPreviousFixture,
    IndexHeadingCardNavigation,
    IndexCarousel
  },
  data() {
    return {
      carousel: [],
      items: [],
      nextLastGame: {},
      nextGames: [],
      news: [],
      players: [],
    };
  },
  async mounted() {
    const response = await this.$store.dispatch("news/getNews", {
      highlight: true
    });
    this.carousel = response.data;

    this.items = [
      {
        routeName: 'GamesNext',
        title: this.$t('index-heading-title-1'),
        subtitle: this.$t('index-heading-subtitle-1'),
        icon: 'player',
      },
      {
        routeName: 'GamesStandings',
        title: this.$t('index-heading-title-2'),
        subtitle: this.$t('index-heading-subtitle-2'),
        icon: 'standings'
      },
      {
        routeName: 'TeamView',
        title: this.$t('index-heading-title-3'),
        subtitle: this.$t('index-heading-subtitle-3'),
        icon: 'pitch'
      },
      {
        routeName: 'HistoryView',
        title: this.$t('index-heading-title-4'),
        subtitle: this.$t('index-heading-subtitle-4'),
        icon: 'tshirt'
      }
    ];

    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 120 * 60000); // Add 120 minutes

    this.nextGames = (await this.$store.dispatch("nextGames/getAllGames")).filter(game => {
      const [day, month] = game.date.split(".");
      const [hours, minutes] = game.hour.split(":");
      const gameDate = new Date(game.year, parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
      return gameDate > futureDate;
    });

    this.lastGames = (await this.$store.dispatch("nextGames/getAllGames")).filter(game => {
      const [day, month] = game.date.split(".");
      const [hours, minutes] = game.hour.split(":");
      const gameDate = new Date(game.year, parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
      return gameDate < futureDate;
    });

    this.nextGames.sort((a, b) => a - b).reverse();
    const nextGame = this.nextGames[0];
    const lastGame = this.lastGames[0];

    console.log(lastGame)

    this.nextLastGame = {
      competition: nextGame.competition,
      nextGameDate: nextGame.date,
      nextGameDay: nextGame.day,
      nextGameHour: nextGame.hour,
      nextHomeTeamName: nextGame.homeTeam,
      nextHomeTeamNameLogo: nextGame.homeTeamLogo,
      nextAwayTeamName: nextGame.awayTeam,
      nextAwayTeamNameLogo: nextGame.awayTeamLogo,
      lastGameDate: lastGame.date,
      lastGameDay: lastGame.day,
      lastGameHour: lastGame.hour,
      lastAwayTeamName: lastGame.awayTeam,
      lastHomeTeamName: lastGame.homeTeam,
      lastHomeTeamLogo: lastGame.homeTeamLogo,
      lastAwayTeamLogo: lastGame.awayTeamLogo,
      scoreHome: '3',
      scoreAway: '0',
    }
    // this.nextGames = await this.$store.dispatch("nextGames/getNextGames");
    const newsResult = await this.$store.dispatch("news/getNews", {limit: 4});
    this.news = newsResult.data;
    const playersResult = await this.$store.dispatch("players/getPlayers", 1);
    this.players = playersResult.data;
  },
};

</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.page-wrapper {
  background: #0a2d1b;
}

.title {
  @include title-h4-bold;
  text-align: center;
}

.index-heading {
  padding: 50px 0;
  background: $color-grey-200;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.card-navigation {
  padding: 0 20px;
}

.index-stadium-wrapper {
  background: no-repeat left $color-green-dark url("@/assets/images/index-stadium-photo.png");
  background-size: cover;
  height: 1200px;
  text-transform: uppercase;
  font-family: $font-black;
  color: $color-white;
  text-align: center;
  padding-top: $spacing-4;

  .index-stadium-subtitle {
    font-size: 18px;
    letter-spacing: 5px;
    margin-bottom: $spacing-3;
  }


  .index-stadium-title-solid {
    font-size: 44px;
  }

  .index-fixtures {
    margin-top: 100px;
  }
}

.index-next-games-wrapper {
  background: $color-grey-100;
  padding: 50px 0 0;
  border-top-right-radius: 70px;
  border-top-left-radius: 70px;

  .next-games-carousel {
    padding-left: 20px;
    margin-top: $spacing-10;
  }


}

.index-players-wrapper {
  position: relative;
  padding-bottom: 50px;

  .index-players-image {
    background: no-repeat center url("~@/assets/images/players-index.png");
    background-size: cover;
    height: 450px;
    width: 100%;
    position: absolute;
    bottom: -55%;
    left: 50%;
    transform: translate(-50%, 55%);
  }
}

.index-players-carousel {
  box-shadow: 0 0 156px rgba(0, 0, 0, 0.13);
  border-radius: 30px;
  overflow: hidden;

}

.index-history-wrapper {
  height: 1300px;
  position: relative;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  overflow: hidden;

  .index-history-image {
    background: no-repeat center url("~@/assets/images/footer-stadium.png");
    background-size: cover;
    margin-top: 250px;
    height: 100%;
    width: 900px;

    .beroe-anniversary-logo {
      width: 100%;
      transform: translateY(-110%);
      position: absolute;
    }

    .beroe-1916-text {
      width: 100%;
      transform: translateY(85%);
      position: absolute;
    }

  }
}

.history-button-wrapper {
  transform: translateY(-70%);
  @include title-h3-bold;
  text-shadow: 0 0 50px rgba(0, 0, 0, 1);
  color: $color-white;

}

@media screen and (min-width: 768px) {
  .button {
    width: 200px;
    transition: 0.3s ease-in-out;

    &:hover {
      width: 220px;
    }
  }
  .title {
    @include title-h3-regular;
    text-align: left;
  }
  .index-heading, {
    padding: 20px 20px 100px 120px;
    border-bottom-right-radius: 70px;
    border-bottom-left-radius: 70px;
  }
  .card-navigation {
    padding: 0;
  }
  .index-stadium-wrapper {
    height: 850px;

    .the-world-is-beroe-svg, .index-stadium-subtitle {
      padding-left: 100px;
    }

    .index-stadium-subtitle {
      font-size: 14px;
      letter-spacing: 20px;
    }

    .index-stadium-title-outline {
      font-size: 180px;
      color: transparent; /* Make the fill transparent */
      line-height: 150px;
      -webkit-text-stroke: 1px white; /* Apply a 3px white stroke to the text */
    }

    .index-stadium-title-solid {
      font-size: 115px;
    }
  }
  .index-next-games-wrapper {
    .next-games-carousel {
      padding-left: 120px;
    }


  }
  .index-latest-news {
    padding: 0 20px 0 120px;
  }
  .index-players-wrapper {
    position: relative;
    padding-bottom: 100px;

    .index-players-image {
      background: no-repeat center url("~@/assets/images/players-index.png");
      background-size: contain;
      height: 90%;
      width: 90%;
      position: absolute;
      bottom: -10%;
      left: 50%;
      transform: translate(-50%, 10%);
    }
  }
  .index-history-wrapper {
    .index-history-image {
      width: 100%;

      .beroe-anniversary-logo {
        transform: translateY(-110%);
      }

      .beroe-1916-text {
        transform: translateY(55%);
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .index-stadium-wrapper {

    .index-stadium-subtitle {
      font-size: 20px;
      letter-spacing: 25px;
    }
  }
}

@media screen and (min-width: 1450px) {
  .index-heading {
    padding: 25px 60px 100px 160px;
  }
  .index-latest-news {
    padding: 0 60px 0 160px;
  }
  .index-stadium-wrapper {
    height: 1100px;

    .index-stadium-subtitle {
      font-size: 24px;
      letter-spacing: 30px;
    }
  }
  .index-next-games-wrapper {
    .next-games-carousel {
      padding-left: 160px;
    }
  }
  .index-next-games-wrapper {
    .next-games-carousel {
      padding-left: 160px;
    }


  }
}

</style>