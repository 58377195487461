<script>
import BeroeAnniversaryLogoBig from "@/components/Common/Icons/BeroeAnniversaryLogoBig.vue";
import HistoryContentComponent from "@/components/Components/HistoryContentComponent/HistoryContentComponent.vue";
import SelectedPostsGridCarousel from "@/components/Components/SelectedPostsGridCarousel/SelectedPostsGridCarousel.vue";

export default {
  name: 'HistoryView',
  components: {SelectedPostsGridCarousel, HistoryContentComponent, BeroeAnniversaryLogoBig},
  data() {
    return {
      history: [],
      historyThumbnails: [],
      achievements: [],
      currentPosition: 0, // Track the current position in the timeline
      selectedHistoryItem: null,
      news: null,
    };
  },
  computed: {
    progressBarHeight() {
      return this.history.length ? `${100 / this.history.length}%` : '0'; // Compute height dynamically
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("utils/showPreloader");
      const newsResponse = await this.$store.dispatch("news/getNews", {limit: 4, categoryId: 'history'});
      this.news = newsResponse.data;
      const response = await this.$store.dispatch("history/getHistory");
      this.history = response.data;
    } catch (err) {
      console.log(err)
    } finally {
      await this.$store.dispatch("utils/hidePreloader");
    }

    this.historyThumbnails = [
      {
        competition: this.$t('bulgarian-cup'),
        competitionIcon: '',
        backgroundImage: 'history-thumb-1.png',
        cupImage: 'bg-cup-vector.png',
        achievements: [
          {
            title: `🥇 ${this.$t('winner')}`,
            years: '2010, 2013'
          }
        ]
      },
      {
        competition: this.$t('bulgarian-league'),
        competitionIcon: '',
        backgroundImage: 'history-thumb-2.png',
        cupImage: 'bg-league-vector.png',
        achievements: [
          {
            title: `🥇 ${this.$t('champion')}`,
            years: '1986'
          },
          {
            title: `🥈 ${this.$t('second-place')}`,
            years: '2015'
          },
          {
            title: `🥉 ${this.$t('third-place')}`,
            years: '1972, 2016'
          },
        ]
      },
      {
        competition: this.$t('bulgarian-supercup'),
        competitionIcon: '',
        backgroundImage: 'history-thumb-3.png',
        cupImage: 'bg-supercup-vector.png',
        achievements: [
          {
            title: `🥇 ${this.$t('winner')}`,
            years: '2013'
          }
        ]

      },
    ]

  },
  methods: {
    onDropdownChange() {
      // Handle dropdown selection change
    }
  }
}
</script>

<template>
  <div class="page-wrapper" v-if="history">
    <div class="heading d-flex justify-content-center align-items-center">
      <div class="heading-content ">
        <div class="breadcrumb d-flex justify-content-center">
          <div class="breadcrumb-item"> {{ $t('home') }}</div>
          <div class="breadcrumb-item"> {{ $t('history') }}</div>
        </div>
        <div class="title"> {{ $t('history-heading-line-1') }}</div>
        <div class="title"> {{ $t('history-heading-line-2') }}</div>
      </div>
    </div>
    <div class="container-custom">
      <div class="body">
        <div class="academy-team-wrapper row g-3 ">

          <div class="col-md-4" v-for="(item, index) in historyThumbnails" :key="index">
            <div class="history-thumb-wrapper" :style="`background-image: url(/static/${ item.backgroundImage})`">
              <div class="coach-info">
                <div class="d-flex align-items-center">
                  <div class="coach-name"> {{ item.competition }}</div>
                  <div class="cup-image" :style="`background-image: url(/static/${ item.cupImage})`"></div>
                </div>
                <div class="achievements" v-for="(achievement, index) in item.achievements" :key="index">
                  <div> {{ achievement.title }}: <span>{{ achievement.years }}</span></div>
                </div>
              </div>
              <div class="coach-photo">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="background-stadium-wrapper d-md-flex flex-column">
      <div class="container-custom d-md-flex flex-column flex-grow-1">
        <div class="row mt-auto">
          <div class="col-md-6  d-md-flex ">
            <div class="mt-auto background-stadium-content">
              <BeroeAnniversaryLogoBig/>
              <div class="title mt-4">{{ $t('history-stadium-title-line-1') }}</div>
              <div class="title">{{ $t('history-stadium-title-line-2') }}</div>
              <div class="subtitle">{{ $t('history-stadium-subtitle') }}</div>
            </div>

          </div>
          <div class="col-md-6 ">
            <div class="footballer-image"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-custom">
      <div class="all-achievements-wrapper">
        <div class="title"> {{ $t('all-achievements') }}</div>
        <div class="row">
          <div class="col-md-5 col-xxl-4">
            <div class="subtitle"> {{ $t('achievements-bulgaria') }}</div>
            <div> {{ $t('achievements-bulgaria-line-1') }}</div>
            <div> {{ $t('achievements-bulgaria-line-2') }}</div>
            <div> {{ $t('achievements-bulgaria-line-3') }}</div>
            <div> {{ $t('achievements-bulgaria-line-4') }}</div>
            <div> {{ $t('achievements-bulgaria-line-5') }}</div>
            <div> {{ $t('achievements-bulgaria-line-6') }}</div>
            <div> {{ $t('achievements-bulgaria-line-7') }}</div>
            <div> {{ $t('achievements-bulgaria-line-8') }}</div>
            <div> {{ $t('achievements-bulgaria-line-9') }}</div>
            <div> {{ $t('achievements-bulgaria-line-10') }}</div>
            <div> {{ $t('achievements-bulgaria-line-11') }}</div>
            <div> {{ $t('achievements-bulgaria-line-12') }}</div>
          </div>
          <div class="col-md-5 col-xxl-4 mt-4 mt-md-0">
            <div class="subtitle"> {{ $t('achievements-europe') }}</div>
            <div> {{ $t('achievements-europe-line-1') }}</div>
            <div> {{ $t('achievements-europe-line-2') }}</div>
            <div> {{ $t('achievements-europe-line-3') }}</div>
            <div> {{ $t('achievements-europe-line-4') }}</div>
            <div> {{ $t('achievements-europe-line-5') }}</div>
            <div> {{ $t('achievements-europe-line-6') }}</div>
            <div> {{ $t('achievements-europe-line-7') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-custom">
      <div class="timeline-wrapper d-none d-md-flex">

        <div class="timeline-progress ">
          <div
              v-for="(item, index) in history"
              :key="index"
              class="progress-bar"
              :style="{ height: progressBarHeight }">

          </div>
          <div
              class="timeline-circle"
              :style="{ top: currentPosition * (100 / history.length) + '%' }"
          ></div>
        </div>
        <div class="content">
          <div v-for="(item, index) in history" :key="index">
            <HistoryContentComponent
                :numbers="item.content.numbers"
                :content="item.content.content"
                :imageUrl="item.content.gallery ?? []"
                :videoUrl="item.content.videoUrl"
            />
          </div>
        </div>
      </div>
      <div class="dropdown-content d-md-none">
        <div class="choose-year"> {{ $t('choose-year') }}</div>
        <select v-model="selectedHistoryItem" @change="onDropdownChange">
          <option v-for="(item, index) in history" :key="index" :value="index">
            {{ item.numbers }}
          </option>
        </select>
        <div v-if="selectedHistoryItem !== null">
          <HistoryContentComponent
              :numbers="history[selectedHistoryItem].content.numbers"
              :content="history[selectedHistoryItem].content.content"
              :imageUrl="history[selectedHistoryItem].content.imageUrl"
              :videoUrl="history[selectedHistoryItem].content.videoUrl"
          />
        </div>
      </div>
    </div>

    <div class="news-wrapper" v-if="news">
      <div class="container-custom">
        <div class="title"> {{ $t('news-from-history-title') }}</div>
        <SelectedPostsGridCarousel :news-data="news"/>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">


.page-wrapper {
  background: $color-grey-100;
}

.breadcrumb {
  @include body-16-regular;
  --bs-breadcrumb-divider-color: $color-white;
  --bs-breadcrumb-item-padding-x: 0;

}


.heading {
  background: $color-green-main;
  height: 500px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .heading-content {
    position: relative;
    z-index: 22;
    text-align: center;
    color: $color-white;

    .title {
      @include title-h1-bold;
      text-transform: uppercase;

    }

  }
}

.heading:before {
  content: "";
  position: absolute;
  background: url("~@/assets/images/history-heading.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 100%;
}

.body {
  padding: 100px 0;


  .history-thumb-wrapper {
    background-color: $color-white;
    min-height: 500px;
    width: 100%;
    border-radius: 30px;
    color: $color-green-dark;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;


    .coach-info {
      position: relative;
      z-index: 11;
      padding: 50px;

    }

    .cup-image {
      height: 100px;
      width: 80px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .achievements {
      @include body-16-bold;

      span {
        text-decoration: underline;
      }
    }

    .coach-name {
      @include title-h3-bold;
      padding-right: 20px;
      font-family: $font-black;
    }


    .coach-photo:before {
      content: "";
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.5) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

}

.background-stadium-wrapper {
  background: url("~@/assets/images/history-stadium.png") bottom no-repeat;
  background-size: cover;
  height: 1000px;
  width: 100%;
  position: relative;

  .background-stadium-content {
    color: $color-green-dark;
    padding: 120px 0;
    position: relative;
    z-index: 22;

    .title {
      text-transform: uppercase;
      @include title-h1-black;
    }

    .subtitle {
      @include title-h5-regular;
    }
  }

  .footballer-image {
    background: url("~@/assets/images/history-petkov-mobile.png") right no-repeat;
    background-size: contain;
    height: 550px;
    width: 100%;
    position: absolute;
    z-index: 22;
    bottom: -5%;
    left: 50%;
    transform: translate(-50%, 5%);


  }
}

.background-stadium-wrapper:before {
  content: "";
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.5) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.all-achievements-wrapper {
  color: $color-green-dark;
  padding: 25px;
  background: $color-white;
  border-radius: 20px;
  position: relative;
  z-index: 23;
  margin-top: $spacing-10;

  .title {
    @include title-h4-bold;
    margin-bottom: $spacing-3;
  }

  .subtitle {
    @include body-18-bold;
  }
}

.dropdown-content {
  padding: 50px 0;

  .choose-year {
    @include body-16-bold;
    margin-top: 20px;
  }

  select {
    width: 100%;
    margin: 20px 0 40px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid $color-grey-200;

    &:focus {
      outline: none;
    }

    &:hover {
      outline: none;
    }
  }
}

.news-wrapper {
  margin-top: $spacing-10;
  padding-bottom: $spacing-5;

  .title {
    @include title-h5-regular;
    margin-bottom: $spacing-4;
  }

}

@media screen and (min-width: 800px) {
  .timeline-wrapper {

    position: relative; /* Position relative for the circle */
    .timeline-progress {
      position: relative;
      width: 20px; /* Width of the progress bar */
      background-color: $color-green-main; /* Color of the progress bar */
      margin-right: 20px; /* Space between the timeline and content */
    }

    .progress-bar {
      position: absolute;
      width: 100%; /* Full width */
      background-color: $color-green-main; /* Color of each progress segment */

    }

    .timeline-circle {
      position: absolute;
      width: 36px; /* Diameter of the circle */
      height: 36px; /* Diameter of the circle */
      background-color: $color-green-light; /* Color of the circle */
      border-radius: 50%; /* Make it a circle */
      border: 5px solid $color-grey-200;
      left: -12px; /* Center the circle on the progress bar */
      margin-top: 25px;
      transition: top 0.3s ease; /* Smooth transition when moving */

    }

    .content {
      flex-grow: 1; /* Allow content to take the remaining space */
      padding: 0 50px;
    }
  }
  .heading {
    height: 600px;
  }
  .body {
    .coach-photo-wrapper {
      min-height: 650px;
    }
  }
  .background-stadium-wrapper {
    height: 700px;

    .footballer-image {
      background-image: url("~@/assets/images/history-petkov.png");
      position: relative;

    }
  }
  .all-achievements-wrapper {
    transform: translateY(-10%);
    margin-top: 0;
    padding: 50px;
  }
}


@media screen and (min-width: 1600px) {
  .heading {
    height: 700px;
  }


}


</style>