<script>
export default {
  name: "YoutubeIcon"
}
</script>

<template>
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.6651 -0.000976562C12.8869 0.133464 15.0981 0.236695 17.3035 0.409547C19.081 0.548788 20.6026 2.05564 20.8503 3.76815C21.2083 6.2401 21.2758 8.71444 20.9968 11.1952C20.9301 11.7858 20.8552 12.3867 20.6808 12.9533C20.1969 14.5266 18.7733 15.6149 17.0896 15.7053C14.7846 15.8294 12.478 15.9958 10.1713 15.999C8.12555 16.0022 6.07567 15.8438 4.03319 15.6893C2.25404 15.5549 0.734116 14.0432 0.486418 12.3315C0.129273 9.85958 0.0642624 7.38524 0.338293 4.90449C0.448564 3.90259 0.548137 2.89589 1.20482 2.04444C1.96931 1.05214 2.97327 0.477567 4.24715 0.392742C6.38673 0.250299 8.52796 0.128662 10.6651 -0.000976562ZM10.5219 14.5002C12.636 14.3873 14.6011 14.3041 16.5629 14.1705C18.2277 14.0568 19.0835 13.2806 19.2761 11.8314C19.611 9.31622 19.611 6.80426 19.2794 4.28831C19.1148 3.03753 18.2886 2.12286 17.1521 1.99322C16.637 1.9348 16.1202 1.88279 15.6026 1.84678C11.8196 1.5835 8.03997 1.6003 4.26525 1.98602C3.20533 2.09405 2.31329 2.87509 2.13719 3.89779C2.02939 4.52438 1.9545 5.15897 1.89772 5.79196C1.71915 7.79656 1.80638 9.79556 2.0549 11.7914C2.22359 13.1486 3.02018 14.0048 4.40926 14.1153C6.49042 14.2817 8.57815 14.3801 10.5202 14.4986L10.5219 14.5002ZM7.55198 8.01503C7.55198 7.41805 7.54539 6.82027 7.55362 6.22329C7.57337 4.77486 9.13445 3.79216 10.4577 4.46036C11.6649 5.06934 12.8392 5.75435 13.9732 6.48497C15.0422 7.17318 15.0603 8.86888 13.9946 9.56909C12.845 10.3245 11.6485 11.0263 10.4124 11.6385C9.10811 12.2851 7.56514 11.2752 7.55198 9.84678C7.54622 9.23619 7.55115 8.62561 7.55115 8.01503H7.55198ZM9.19123 8.03823C9.19123 8.5952 9.18711 9.15217 9.19287 9.70914C9.19781 10.1845 9.49241 10.3565 9.91622 10.1165C10.893 9.56349 11.8657 9.00332 12.8326 8.43435C13.2507 8.18868 13.2474 7.89419 12.8285 7.64852C11.8608 7.08035 10.8889 6.51858 9.91292 5.96481C9.48583 5.72234 9.19699 5.88879 9.19205 6.36813C9.18629 6.9251 9.1904 7.48207 9.1904 8.03904L9.19123 8.03823Z"
        fill="black"/>
  </svg>


</template>

<style scoped lang="scss">

</style>