import {createRouter, createWebHistory} from "vue-router";
import HomeView from "@/views/HomeView";
import NewsView from "@/views/NewsView.vue";
import OneNewsView from "@/views/OneNewsView.vue";
import TeamView from "@/views/TeamView.vue";
import OnePlayerView from "@/views/OnePlayerView.vue";
import AcademyView from "@/views/AcademyView.vue";
import HistoryView from "@/views/HistoryView.vue";
import ContactsView from "@/views/ContactsView.vue";
import Games from "@/views/Games/Games.vue";
import GamesList from "@/views/Games/GamesList.vue";
import GamesStandings from "@/views/Games/GamesStandings.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import DataProtectionView from "@/views/DataProtectionView.vue";
import RightsOfUseView from "@/views/RightsOfUseView.vue";

const paths = {
    "NewsView": {en: "/news", bg: "/новини"},
    "NewsFirstTeam": {en: "first-team", bg: "първи-отбор"},
    "NewsSecondTeam": {en: "second-team", bg: "втори-отбор"},
    "NewsAcademy": {en: "academy", bg: "академия"},
    "NewsHistory": {en: "history", bg: "история"},
    "OneNewsView": {en: "/news/:id", bg: "/новини/:id"},
    "OnePlayerView": {en: "/players/:id", bg: "/играчи/:id"},
    "TeamView": {en: "/team", bg: "/отбор"},
    "SecondTeamView": {en: "/second-team", bg: "/втори-отбор"},
    "AcademyView": {en: "/academy/", bg: "/академия"},
    "HistoryView": {en: "/history/", bg: "/история"},
    "ContactsView": {en: "/contacts/", bg: "/контакти"},
    "PrivacyPolicyView": {en: "/privacy-policy/", bg: "/политика-за-поверителност"},
    "DataProtectionView": {en: "/data-protection/", bg: "/защита-на-личните-данни"},
    "RightsOfUseView": {en: "/rights-of-use/", bg: "/права-за-ползване"},
    "Games": {en: "/games/", bg: "/срещи"},
    "GamesPrevious": {en: "previous", bg: "изминали"}, // sub path to Games
    "GamesNext": {en: "next", bg: "предстоящи"},  // sub path to Games
    "GamesStandings": {en: "standings", bg: "класиране"},  // sub path to Games
}


const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: getPath("NewsView"),
        name: "NewsView",
        component: NewsView,
        children: [
            {
                path: getPath("NewsFirstTeam"),
                name: "NewsFirstTeam",
                component: NewsView,
            },
            {
                path: getPath("NewsSecondTeam"),
                name: "NewsSecondTeam",
                component: NewsView,
            },
            {
                path: getPath("NewsAcademy"),
                name: "NewsAcademy",
                component: NewsView,
            },
            {
                path: getPath("NewsHistory"),
                name: "NewsHistory",
                component: NewsView,
            }
        ]
    },
    {
        path: getPath("OneNewsView"),
        name: "OneNewsView",
        component: OneNewsView,
    },
    {
        path: getPath("OnePlayerView"),
        name: "OnePlayerView",
        component: OnePlayerView,
    },
    {
        path: getPath("TeamView"),
        name: "TeamView",
        component: TeamView,
    },
    {
        path: getPath("SecondTeamView"),
        name: "SecondTeamView",
        component: TeamView,
    },
    {
        path: getPath("AcademyView"),
        name: "AcademyView",
        component: AcademyView,
    },
    {
        path: getPath("HistoryView"),
        name: "HistoryView",
        component: HistoryView,
    },
    {
        path: getPath("ContactsView"),
        name: "ContactsView",
        component: ContactsView,
    },
    {
        path: getPath("PrivacyPolicyView"),
        name: "PrivacyPolicyView",
        component: PrivacyPolicyView,
    },
    {
        path: getPath("DataProtectionView"),
        name: "DataProtectionView",
        component: DataProtectionView,
    },
    {
        path: getPath("RightsOfUseView"),
        name: "RightsOfUseView",
        component: RightsOfUseView,
    },

    {
        path: getPath("Games"),
        name: "Games",
        component: Games,
        children: [
            {
                path: getPath("GamesPrevious"),
                name: "GamesPrevious",
                component: GamesList,
            },
            {
                path: getPath("GamesNext"),
                name: "GamesNext",
                component: GamesList,
            },
            {
                path: getPath("GamesStandings"),
                name: "GamesStandings",
                component: GamesStandings,
            },
        ]
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


function getPath(name) {
    const lang = 'bg';
    return encodeURI(paths[name][lang]);
}

export default router;
