export default {
    "title": "Beroe 1916",
    "search": "Search",
    "news": "News",
    "team": "Team",
    "academy": "Academy",
    "history": "History",
    "more": "More",
    "read-more": "Read more",
    "index-heading-title-1": "Matches",
    "index-heading-title-2": "Standings",
    "index-heading-title-3": "The Team",
    "index-heading-title-4": "History",
    "index-heading-subtitle-1": "View all past and upcoming matches",
    "index-heading-subtitle-2": "Where Beroe is ranked",
    "index-heading-subtitle-3": "All Beroe players",
    "index-heading-subtitle-4": "Over 100 years green tradition",
    "index-stadium-subtitle": "One city, one team",
    "index-stadium-title-outline-1": "No mine",
    "index-stadium-title-outline-2": "No yours",
    "index-stadium-title-solid": "The world is Beroe",
    "next-game": "Upcoming match",
    "last-game": "Last match",
    "index-next-games-title": "Upcoming matches",
    "all-games": "All matches",
    "index-membership-title": "Be loyal to your favorite team",
    "index-membership-subtitle": "Buy your season ticket and don’t miss a match of your favorite team",
    "learn-more-for-membership": "Learn more about membership",
    "position": "Position",
    "birth-date": "Date of birth",
    "birth-place": "Place of birth",
    "nationality": "Nationality",
    "height": "Height",
    "weight": "Weight",
    "index-players-title": "Players",
    "index-latest-news-title": "Latest news",
    "index-team-history-title": "Club history",
    "professional-football-club": "Professional football club",
    "beroe-stara-zagora": "Beroe Stara Zagora",
    "follow-us": "Follow us",
    "first-team": "First team",
    "second-team": "Second team",
    "fixtures": "Fixtures",
    "standings": "Standings",
    "footer-copyright": "PFC Beroe © 2024",
    "privacy-policy": "Privacy policy",
    "terms-and-conditions": "Terms and conditions",
    "gdpr": "Data protection",
    "contacts": "Contacts",
    "developed-with": "Developed with",
    "by": "by",
    "all-news": "All news",
    "all-posts": "Everything else",
    "latest-posts": "Latest posts",
    "academy-subtitle": "The future is in the young Beroe players",
    "home": "Home",
    "academy-paragraph-1": "Over the years, the Beroe school has proven to be one of the most successful in Bulgaria, producing many top-class athletes. Currently, the club has over 200 children and juniors, distributed into 11 teams. The youngest trainees are 7 years old. The school is represented by teams in two Elite groups in Bulgaria, as well as a team in the Third South-East League.",
    "academy-paragraph-2": "The youth academy of Beroe has won many honors on both national and international levels. The most recent significant achievement was the Bulgarian Football Union Cup for senior youth in 2014.",
    "history-heading-line-1": "Over 100 years",
    "history-heading-line-2": "of history",
    "bulgarian-cup": "Bulgarian Cup",
    "bulgarian-supercup": "Bulgarian Supercup",
    "bulgarian-league": "Bulgarian Champion",
    "winner": "Winner",
    "champion": "Champion",
    "second-place": "Second place",
    "third-place": "Third place",
    "history-stadium-title-line-1": "One city,",
    "history-stadium-title-line-2": "one team",
    "history-stadium-subtitle": "Stara Zagora at 8000, Beroe at 100",
    "all-achievements": "All achievements",
    "achievements-bulgaria": "In Bulgaria by years",
    "achievements-bulgaria-line-1": "1968 – Finalist for the Soviet Army Cup",
    "achievements-bulgaria-line-2": "1972 – Third place in the Bulgarian Championship",
    "achievements-bulgaria-line-3": "1973 – Finalist for the Soviet Army Cup",
    "achievements-bulgaria-line-4": "1979 – Finalist for the Soviet Army Cup",
    "achievements-bulgaria-line-5": "1980 – Finalist for the Soviet Army Cup",
    "achievements-bulgaria-line-6": "1986 – Bulgarian Champion",
    "achievements-bulgaria-line-7": "2010 – Bulgarian Cup Winner",
    "achievements-bulgaria-line-8": "2010 – Finalist for the Bulgarian Supercup",
    "achievements-bulgaria-line-9": "2013 – Bulgarian Cup Winner",
    "achievements-bulgaria-line-10": "2013 – Bulgarian Supercup Winner",
    "achievements-bulgaria-line-11": "2015 – A Football Group Runner-up",
    "achievements-bulgaria-line-12": "2016 – Third place in A Football Group",
    "achievements-europe": "In Europe by years",
    "achievements-europe-line-1": "1968 – Balkan Cup Winner",
    "achievements-europe-line-2": "1969 – Balkan Cup Winner",
    "achievements-europe-line-3": "1973 – UEFA Cup Round of 16",
    "achievements-europe-line-4": "1974 – Cup Winners' Cup Quarterfinalist",
    "achievements-europe-line-5": "1980 – Cup Winners' Cup Round of 16",
    "achievements-europe-line-6": "1982 – Balkan Cup Winner",
    "achievements-europe-line-7": "1984 – Balkan Cup Winner",
    "choose-year": "Choose a year",
    "news-from-history-title": "News from the 'History' category",
    "contacts-heading-title": "Send an inquiry",
    "contacts-heading-subtitle": "Fill out the contact form correctly, and our team will get in touch with you as soon as possible",
    "your-name": "Your name",
    "your-phone-email": "Phone/Email",
    "your-message": "Your message",
    "send-now": "Send now",
    "contact-us": "Contact us",
    "send-inquiry": "Send inquiry",
    "fan-shop": "Fan shop",
    "fan-shop-title": "Fan shop",
    "previous-games": "Previous matches",
    "next-games": "Upcoming matches",
    "table-team": "Club",
    "table-matches": "M",
    "table-wins": "W",
    "table-draws": "D",
    "table-loses": "L",
    "table-goal-difference": "GD",
    "table-points": "P",
    "address": "Address",
    "phone-email": "Phone/Email",
    "working-time": "Working hours",
    "office-1-title": "Administrative office",
    "office-1-address": "Stara Zagora 6000, Beroe Square 1",
    "office-1-email": "office@beroeclub.com",
    "office-2-title": "Press Officer",
    "office-2-name": "Lora Nikolaeva",
    "office-2-email": "lora@beroeclub.com",
    "office-3-title": "Fan coordinator",
    "office-3-name": "Alexander Mechev",
    "fan-shop-address": "Stara Zagora 6000,\nZahari Knyazheski 81",
    "fan-shop-phone": "088 716 1916",
    "fan-shop-email": "beroefanshop@gmail.com",
    "days-hours-open": "Monday to Friday – 11:00 – 19:16",
    "days-hours-closed": "Saturday and Sunday – closed",
    "goalkeepers": "Goalkeepers",
    "defenders": "Defenders",
    "midfielders": "Midfielders",
    "forwards": "Forwards",
    "goalkeeper": "Goalkeeper",
    "defender": "Defender",
    "midfielder": "Midfielder",
    "forward": "Forward",
    //Days of week
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "biography": 'Biography',
    "financial-statement": 'Financial Statements',
    //Contact form
    "contact-form-error-message": "Error! Please try again later.",
    "contact-form-success-message": "Your message was sent successfully!",
    "contact-form-validation-error-message": "Please fill all fields with valid information!",
}
