<script>

import NewsIcon from "@/components/Common/Icons/NewsIcon.vue";
import BeroeLogo from "@/components/Common/Icons/BeroeLogo.vue";
import SearchIcon from "@/components/Common/Icons/SearchIcon.vue";
import AcademyIcon from "@/components/Common/Icons/AcademyIcon.vue";
import TeamIcon from "@/components/Common/Icons/TeamIcon.vue";
import HistoryIcon from "@/components/Common/Icons/HistoryIcon.vue";
import MoreIcon from "@/components/Common/Icons/MoreIcon.vue";
import LanguageButton from "@/components/Common/Buttons/LanguageButton.vue";
import SearchIconGreen from "@/components/Common/Icons/SearchIconGreen.vue";
import HamburgerIcon from "@/components/Common/Icons/HamburgerIcon.vue";
import FacebookIcon from "@/components/Common/Icons/FacebookIcon.vue";
import InstagramIcon from "@/components/Common/Icons/InstagramIcon.vue";
import ModalMain from "@/components/Components/ModalMain/ModalMain.vue";
import ModalSearch from "@/components/Components/ModalMain/ModalSearch.vue";
import ModalTeamSelect from "@/components/Components/ModalMain/ModalTeamSelect.vue";
import YoutubeIcon from "@/components/Common/Icons/YoutubeIcon.vue";

export default {
  name: "SiteHeader",
  components: {
    YoutubeIcon,
    ModalTeamSelect,
    ModalSearch,
    ModalMain,
    InstagramIcon,
    FacebookIcon,
    HamburgerIcon,
    SearchIconGreen,
    MoreIcon, HistoryIcon, TeamIcon, AcademyIcon, SearchIcon, BeroeLogo, NewsIcon, LanguageButton
  },
  data() {
    return {
      isDesktop: window.innerWidth >= 768,
      isSearchBarVisible: false,
      isMobileMenuVisible: false,
      isSubmenuVisible: false,
      activeModal: null
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {  // Updated to beforeUnmount
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth >= 768;
    },
    toggleSearchBar() {
      this.isSearchBarVisible = !this.isSearchBarVisible;
    },
    toggleMobileMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
    },
    closeMobileMenu() {
      this.isMobileMenuVisible = false;
      this.$refs.hamburgerIcon.closeMenu();
    },
    toggleSubMenu() {
      this.isSubmenuVisible = !this.isSubmenuVisible;
    },
    showModal(modalType) {
      this.activeModal = modalType; // Set the active modal type ('search' or 'team')
      this.$refs.modalComponent.showModal();
    },
    handleSearch(e) {
      this.$router.push({name: 'NewsView', query: {s: e}})
    }
  }
}
</script>

<template>
  <div class="header-wrapper" v-if="isDesktop">
    <router-link :to="{ name: 'home' }">
      <BeroeLogo/>
    </router-link>

    <div class="menu-item d-flex align-items-center justify-content-center" @click="showModal('search')">
      <div>
        <div class="d-flex justify-content-center mb-2">
          <SearchIcon/>
        </div>
        <div class="text-center">{{ $t('search') }}</div>
      </div>
    </div>

    <div class="menu-item d-flex align-items-center justify-content-center">
      <router-link :to="{name: 'NewsView'}">
        <div class="d-flex justify-content-center mb-2">
          <NewsIcon/>
        </div>
        <div class="text-center">{{ $t('news') }}</div>
      </router-link>
    </div>

    <div class="menu-item d-flex align-items-center justify-content-center">
      <router-link :to="{name: 'TeamView'}">
        <div class="d-flex justify-content-center mb-2">
          <TeamIcon/>
        </div>
        <div class="text-center">{{ $t('team') }}</div>
      </router-link>


    </div>

    <div class="menu-item d-flex align-items-center justify-content-center">
      <router-link :to="{name: 'AcademyView'}">
        <div class="d-flex justify-content-center mb-2">
          <AcademyIcon/>
        </div>
        <div class="text-center">{{ $t('academy') }}</div>
      </router-link>
    </div>

    <div class="menu-item d-flex align-items-center justify-content-center">
      <router-link :to="{name: 'HistoryView'}">
        <div class="d-flex justify-content-center mb-2">
          <HistoryIcon/>
        </div>
        <div class="text-center">{{ $t('history') }}</div>
      </router-link>
    </div>

    <div class="menu-item d-flex align-items-center justify-content-center">
      <router-link :to="{name: 'GamesNext'}">
        <div class="d-flex justify-content-center mb-2">
          <MoreIcon/>
        </div>
        <div class="text-center">{{ $t('fixtures') }}</div>
      </router-link>
    </div>

    <div class="mt-auto d-flex justify-content-center align-items-center language-button">
      <LanguageButton/>
    </div>
    <div>
      <ModalMain ref="modalComponent">
        <template v-slot="{ hideModal }">
          <div v-if="activeModal === 'search'">
            <ModalSearch @search="handleSearch"/>
          </div>
          <div v-else-if="activeModal === 'team'">
            <ModalTeamSelect @close-modal="hideModal"/>
          </div>
        </template>
      </ModalMain>
    </div>
  </div>
  <div class="header-wrapper-mobile d-md-none" v-else>
    <div class="d-flex justify-content-between align-items-center header-container-mobile ">
      <SearchIconGreen @click="toggleSearchBar"/>
      <router-link :to="{ name: 'home' }">
        <BeroeLogo :width="55" :height="55"/>
      </router-link>
      <div>
        <HamburgerIcon ref="hamburgerIcon" @toggle="toggleMobileMenu"/>
      </div>

    </div>
    <transition name="slide-down">
      <div class="d-flex search-bar-mobile" v-if="isSearchBarVisible">
        <input type="text" class="me-auto input" :placeholder="$t('search')"/>
        <SearchIconGreen/>
      </div>
    </transition>
    <transition name="slide-down">
      <div class="mobile-menu-wrapper" v-show="isMobileMenuVisible">
        <div class="language-button">
          <LanguageButton/>
        </div>

        <div>
          <router-link :to="{name: 'NewsView'}">
            <div class="menu-item" @click="closeMobileMenu"> {{ $t('news') }}</div>
          </router-link>
          <router-link :to="{name: 'TeamView'}">
            <div class="menu-item d-flex" @click="closeMobileMenu">
              <div class="me-auto">{{ $t('team') }}</div>
              <!--            <div v-show="!isSubmenuVisible">+</div>-->
              <!--            <div v-show="isSubmenuVisible">-</div>-->
            </div>
          </router-link>

          <!--          <div class="submenu-item" v-show="isSubmenuVisible">-->
          <!--            <router-link :to="{ name: 'TeamView' }">-->
          <!--              <div class="menu-item" @click="closeMobileMenu"> {{ $t('first-team') }}</div>-->
          <!--            </router-link>-->

          <!--            <router-link :to="{ name: 'SecondTeamView' }"></router-link>-->
          <!--            <router-link :to="{ name: 'TeamView' }">-->

          <!--              <div class="menu-item" @click="closeMobileMenu"> {{ $t('second-team') }}</div>-->
          <!--            </router-link>-->
          <!--          </div>-->
          <router-link :to="{name: 'AcademyView'}">
            <div class="menu-item" @click="closeMobileMenu"> {{ $t('academy') }}</div>
          </router-link>
          <router-link :to="{name: 'HistoryView'}">
            <div class="menu-item" @click="closeMobileMenu"> {{ $t('history') }}</div>
          </router-link>
          <router-link :to="{name: 'GamesNext'}">
            <div class="menu-item" @click="closeMobileMenu"> {{ $t('fixtures') }}</div>
          </router-link>
          <router-link :to="{name: 'ContactsView'}">
            <div class="menu-item" @click="closeMobileMenu"> {{ $t('contacts') }}</div>
          </router-link>
        </div>
        <div class="social-icons-wrapper d-flex justify-content-center">
          <div class="d-flex justify-content-between">
            <a href="https://www.facebook.com/pfcberoeok" class="social-icon">
              <FacebookIcon/>
            </a>
            <a href="https://www.instagram.com/pfcberoe1916/" class="social-icon">
              <InstagramIcon/>
            </a>
            <a href="https://www.youtube.com/@PFCBeroe" class="social-icon">
              <YoutubeIcon/>
            </a>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<style scoped lang="scss">

.header-wrapper-mobile {
  width: 100%;
  color: $color-green-dark;


  .header-container-mobile {
    position: relative;
    z-index: 3;
    padding: 10px 20px;
    background: $color-white;
  }

  .search-bar-mobile {
    border-top: 2px solid $color-grey-200;
    padding: 20px 20px;
    position: relative;
    z-index: 2;
    background: $color-white;

    .input {
      border: none;
      padding: 5px 10px;
      width: 80%;
    }

    .input:focus, input:hover {
      outline: none;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 0 2px $color-green-main;
    }
  }

  .mobile-menu-wrapper {
    position: relative;
    z-index: 2;
    height: 100vh;
    background: $color-white;
    transition: transform 0.5s ease-in-out;

    .language-button {
      border-bottom: 2px solid $color-grey-200;
      padding: 20px;
    }

    .menu-item {
      padding: 20px;
      font-size: 19px;
      font-family: $font-semi-bold;

    }

    .submenu-item {
      padding: 20px;
      background: $color-grey-100;
    }

    .social-icons-wrapper {
      position: absolute;
      bottom: 15%;
      width: 100%;

      .social-icon {
        margin: 0 20px;
      }
    }
  }
}

/* Slide Down Transition */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-down-enter,
.slide-down-leave-to /* .slide-down-leave-active in <2.1.8 */
{
  transform: translateY(-100%);
}

.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0%);
  transition: transform 0.5s ease-in-out;
}

@media screen and (min-width: 768px) {
  .header-wrapper {
    height: 95vh;
    background: $color-white;
    padding: 20px 5px;
    border-radius: 20px;


    @include body-12-bold;

    .menu-item {
      border-radius: 10px;
      padding: 10px;
      transition: 0.3s ease-in-out;
      min-height: 67px;
      margin: $spacing-2 0;
      cursor: pointer;

      &:hover {
        transition: 0.3s ease-in-out;
        background: $color-green-main;
        color: $color-white;
      }
    }

    .language-button {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

</style>