x
<script setup>

</script>

<template>
  <svg class="icon-svg" v-if="$root.$i18n.locale === 'bg'" width="212" height="450" viewBox="0 0 212 450" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M136.108 176.731L162.75 83.9057L163.203 82.3273H161.56H131.899H130.951L130.704 83.2417L97.335 206.737L96.9135 208.297H98.5293H159.753V254.69V255.927H160.99H189.511H190.748V254.69V208.297H197.782H198.61L198.926 207.531L210.334 179.866L210.427 179.64V179.395V177.968V176.731H209.19H190.748V125.49V124.253H189.511H188.085H187.835L187.605 124.35L160.51 135.758L159.753 136.077V136.898V176.731H136.108Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M88.7515 167.425L89.5321 167.115V166.275V83.5645V82.3273H88.295H31.5385C22.7011 82.3273 15.2405 85.2785 9.98608 90.5329C4.73169 95.7873 1.78049 103.248 1.78049 112.085V136.898V138.136H3.01763H4.44367H4.6935L4.92375 138.039L32.0186 126.63L32.7756 126.312V125.49V112.182H58.8222V146.324L2.56313 168.547L1.78332 168.855L1.7805 169.693L1.49529 254.4L1.49112 255.637L2.7283 255.642L88.2909 255.927L89.5321 255.931V254.69V196.222V194.985H88.295H86.8689H86.6307L86.4095 195.073L59.3147 205.911L58.537 206.222V207.06V226.358H32.7756V189.646L88.7515 167.425Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M165.742 371.932V418.892H139.41V400.164V399.343L138.653 399.024L111.558 387.616L111.328 387.519H111.078H109.652H108.415V388.756V447.224V448.461H109.652H166.979C175.771 448.461 183.21 445.895 188.47 441.311C193.745 436.715 196.737 430.164 196.737 422.41L196.737 422.405L196.452 351.959L196.449 351.121L195.669 350.813L139.41 328.591V304.716H187.799H188.607L188.932 303.976L200.34 278.022L200.445 277.784V277.524V276.098V274.861H199.208H109.652H108.415V276.098V349.682V350.541L109.22 350.841L165.742 371.932Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M88.7515 359.959L89.5321 359.649V358.809V276.098V274.861H88.295H31.5385C22.7011 274.861 15.2405 277.812 9.98608 283.067C4.73169 288.321 1.78049 295.782 1.78049 304.619V329.432V330.67H3.01763H4.44367H4.6935L4.92375 330.573L32.0186 319.164L32.7756 318.845V318.024V304.716H58.8222V338.858L2.56313 361.081L1.78332 361.389L1.7805 362.227L1.49529 446.934L1.49112 448.171L2.7283 448.175L88.2909 448.461L89.5321 448.465V447.224V388.756V387.519H88.295H86.8689H86.6307L86.4095 387.607L59.3147 398.445L58.537 398.756V399.594V418.892H32.7756V382.18L88.7515 359.959Z"
        stroke="white" stroke-width="2.47428"/>
    <path d="M188.043 0.576416H198.78V65H188.043V38.2642H177.306V65H166.568V0.576416H177.306V27.9564H188.043V0.576416Z"
          fill="white"/>
    <path
        d="M125.14 65V10.5621C125.14 4.5492 129.435 0.576416 135.877 0.576416H157.352V55.0143C157.352 61.0272 153.057 65 146.615 65H125.14ZM135.877 55.0143H146.615V10.5621H135.877V55.0143Z"
        fill="white"/>
    <path
        d="M84.2437 65V47.4983L94.7662 45.3508H94.9809V55.0143H105.718V40.9485L93.263 31.6071V31.285L105.718 22.4804V10.5621H94.8735V19.1519L84.4584 21.2993H84.2437V10.5621C84.2437 4.5492 88.5386 0.576416 94.9809 0.576416H116.455V26.3458L108.188 31.285V31.4997L116.455 36.4389V55.0143C116.455 61.0272 112.161 65 105.718 65H84.2437Z"
        fill="white"/>
    <path
        d="M75.9862 0.576416V20.6551H75.4493L65.2489 16.6823V10.8842H54.5117V27.9564H69.5438V28.4933L65.7858 38.2642H54.5117V54.6922H65.2489V46.9614L75.4493 42.9886H75.9862V65H43.7744V10.5621C43.7744 4.5492 48.2841 0.576416 54.9412 0.576416H75.9862Z"
        fill="white"/>
    <path
        d="M14.0322 0.576416H35.5067V24.95H34.9698L24.7694 20.9772V10.8842H14.0322V54.7996H24.7694V42.237L34.9698 38.2642H35.5067V54.2627C35.5067 60.7051 31.2118 65 24.7694 65H3.29492V11.3137C3.29492 4.87132 7.58983 0.576416 14.0322 0.576416Z"
        fill="white"/>
  </svg>
  <svg class="icon-svg" v-if="$root.$i18n.locale === 'en'" width="212" height="439" viewBox="0 0 212 439" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M136.108 165.731L162.75 72.9055L163.203 71.3271H161.56H131.899H130.951L130.704 72.2415L97.335 195.737L96.9135 197.297H98.5293H159.753V243.689V244.926H160.99H189.511H190.748V243.689V197.297H197.782H198.61L198.926 196.531L210.334 168.866L210.427 168.639V168.394V166.968V165.731H209.19H190.748V114.49V113.253H189.511H188.085H187.835L187.605 113.35L160.51 124.758L159.753 125.077V125.898V165.731H136.108Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M88.7515 156.425L89.5321 156.115V155.275V72.5642V71.3271H88.295H31.5385C22.7011 71.3271 15.2405 74.2783 9.98608 79.5327C4.73169 84.787 1.78049 92.2477 1.78049 101.085V125.898V127.135H3.01763H4.44367H4.6935L4.92375 127.038L32.0186 115.63L32.7756 115.311V114.49V101.181H58.8222V135.324L2.56313 157.547L1.78332 157.855L1.7805 158.693L1.49529 243.4L1.49112 244.637L2.7283 244.641L88.2909 244.926L89.5321 244.931V243.689V185.222V183.984H88.295H86.8689H86.6307L86.4095 184.073L59.3147 194.911L58.537 195.222V196.06V215.357H32.7756V178.646L88.7515 156.425Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M165.742 360.931V407.891H139.41V389.164V388.342L138.653 388.024L111.558 376.615L111.328 376.518H111.078H109.652H108.415V377.756V436.223V437.46H109.652H166.979C175.771 437.46 183.21 434.895 188.47 430.311C193.745 425.714 196.737 419.164 196.737 411.41L196.737 411.405L196.452 340.959L196.449 340.121L195.669 339.813L139.41 317.591V293.715H187.799H188.607L188.932 292.976L200.34 267.022L200.445 266.784V266.524V265.098V263.861H199.208H109.652H108.415V265.098V338.682V339.541L109.22 339.841L165.742 360.931Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M88.7515 348.958L89.5321 348.649V347.809V265.098V263.861H88.295H31.5385C22.7011 263.861 15.2405 266.812 9.98608 272.067C4.73169 277.321 1.78049 284.782 1.78049 293.619V318.432V319.669H3.01763H4.44367H4.6935L4.92375 319.572L32.0186 308.164L32.7756 307.845V307.024V293.715H58.8222V327.858L2.56313 350.081L1.78332 350.389L1.7805 351.227L1.49529 435.934L1.49112 437.171L2.7283 437.175L88.2909 437.46L89.5321 437.465V436.223V377.756V376.518H88.295H86.8689H86.6307L86.4095 376.607L59.3147 387.445L58.537 387.756V388.593V407.891H32.7756V371.18L88.7515 348.958Z"
        stroke="white" stroke-width="2.47428"/>
    <path
        d="M200.837 0.607666H209.7V55.9999H200.837L187.913 23.2262V55.9999H179.05V0.607666H187.913L200.837 34.3969V0.607666Z"
        fill="white"/>
    <path
        d="M143.518 55.9999V9.19346C143.518 4.02352 147.21 0.607666 152.75 0.607666H171.214V47.4141C171.214 52.584 167.521 55.9999 161.982 55.9999H143.518ZM152.75 47.2295H161.982V9.47042H152.75V47.2295Z"
        fill="white"/>
    <path
        d="M116.386 0.607666H136.143V17.9639H135.681L126.911 14.548V9.47042H117.679V20.4565L136.143 27.5652V47.968C136.143 52.7687 132.45 55.9999 126.911 55.9999H108.447V37.0742H108.908L117.679 40.5824V47.2295H126.911V33.9353L108.447 26.642V9.93203C108.447 4.30048 111.586 0.607666 116.386 0.607666Z"
        fill="white"/>
    <path
        d="M80.5918 0.607666H100.625V55.9999H91.3933V33.9353L82.1612 37.6281V55.9999H72.9292V9.83971C72.9292 4.30048 75.9758 0.607666 80.5918 0.607666ZM82.1612 27.7499L91.3933 24.1494V9.37811H82.1612V27.7499Z"
        fill="white"/>
    <path
        d="M65.6444 0.607666V17.8716H65.1828L56.4123 14.4557V9.47042H47.1803V24.1494H60.1051V24.611L56.8739 33.0121H47.1803V47.1371H56.4123V40.4901L65.1828 37.0742H65.6444V55.9999H37.9482V9.19346C37.9482 4.02352 41.8257 0.607666 47.5496 0.607666H65.6444Z"
        fill="white"/>
    <path
        d="M10.6324 0.607666H30.389V17.9639H29.9274L21.1569 14.548V9.47042H11.9249V20.4565L30.389 27.5652V47.968C30.389 52.7687 26.6962 55.9999 21.1569 55.9999H2.69287V37.0742H3.15447L11.9249 40.5824V47.2295H21.1569V33.9353L2.69287 26.642V9.93203C2.69287 4.30048 5.83176 0.607666 10.6324 0.607666Z"
        fill="white"/>
  </svg>

</template>

<style scoped lang="scss">
.icon-svg {
  max-height: 350px;
  max-width: 350px; /* Set the maximum width for mobile devices */
  height: auto; /* Maintains the aspect ratio */
}


/* Apply styles for mobile devices */
@media (min-width: 768px) {
  .icon-svg {
    max-height: 350px;
    max-width: 300px; /* Ensures the SVG scales to the container width */
  }
}

@media screen and (min-width: 1000px) {
  .icon-svg {

    max-width: 800px; /* Ensures the SVG scales to the container width */
  }
}

@media screen and (min-width: 1200px) {
  .icon-svg {
    max-height: 450px;
    max-width: 1900px; /* Ensures the SVG scales to the container width */
  }
}
</style>