<template>
  <div class="component-wrapper">
    <div class="row g-0">
      <div class="col-md-7" v-if="news.length">
        <router-link :to="{name: 'OneNewsView', params: {id: news[0].id}}">
          <div class="main-news-wrapper">
            <div class="main-title">{{ news[0].content.title }}</div>
            <!-- Sanitize and render the main news content using v-html -->
            <div class="description" v-html="sanitizeContent(news[0].content.content)"></div>
            <div class="news-image-container">
              <div class="news-image" :style="{ backgroundImage: `url(/storage/${news[0].content.gallery[0]})` }"></div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-5">
        <div
            class="more-news-wrapper"
            v-for="(item, index) in news"
            :key="index"
        >
          <router-link :to="{name: 'OneNewsView', params: {id: item.id}}">
            <div class="title">{{ item.content.title }}</div>
            <!-- Sanitize and render other news items content using v-html -->
            <div class="d-flex align-items-center">
              <div class="description" v-html="sanitizeContent(item.content.content)"></div>
              <div class="news-circle-button ms-3">
                <CircleButton/>
              </div>
            </div>


          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import CircleButton from "@/components/Common/Buttons/CircleButton.vue";

export default {
  name: 'IndexLatestNews',
  components: {CircleButton},
  props: {
    news: {
      type: Array,
      required: true,
    },
  },
  methods: {
    sanitizeContent(content) {
      // Sanitize content to remove unwanted tags
      return DOMPurify.sanitize(content, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
  border-radius: 30px;
  background: $color-white;
  color: $color-green-dark;

  .description {
    @include body-16-regular;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-height: 50px;
  }

  .more-news-wrapper {
    transition: 0.3s ease-in-out;

    .news-circle-button {
      opacity: 0;
      transition: 0.3s ease-in-out;
      margin-top: $spacing-1;
    }

    .title {
      @include title-h4-bold;

    }

    &:hover {
      transition: 0.3s ease-in-out;

      .title {
        color: $color-green-main;
      }

      .news-circle-button {
        opacity: 100%;
      }
    }
  }


  .main-title {
    @include title-h3-bold;
    margin-bottom: $spacing-2;
    transition: 0.2s ease-in-out;

    &:hover {
      color: $color-green-main;
    }
  }

  .more-news-wrapper {
    padding: 25px;
    border-bottom: 1px solid $color-grey-200;

    &:nth-of-type(4n) {
      border-bottom: none;
    }
  }

  .main-news-wrapper {
    padding: 25px;
    border-bottom: 1px solid $color-grey-200;
    height: 100%;
    transition: 0.2s ease-in-out;

    &:hover {
      .main-title {
        color: $color-green-main;
      }

    }

    .main-title {
      @include title-h3-bold;
      margin-bottom: $spacing-2;

    }

    .news-image-container {
      border-radius: 10px;
      overflow: hidden;
      height: 200px;
      margin-top: $spacing-3;
    }

    .news-image {

      width: 100%;
      height: 100%;
      background: no-repeat center;
      background-size: cover;
      transition: 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }


    }
  }
}

@media screen and (min-width: 768px) {
  .component-wrapper {
    .main-news-wrapper, .more-news-wrapper {
      padding: 25px;
    }

    .main-news-wrapper {
      border-right: 1px solid $color-grey-200;

      .news-image-container {
        border-radius: 30px;
        height: 500px;

        .news-image {
          height: 500px;
        }
      }

    }
  }

}
</style>
