<template>
  <div class="page-wrapper container-custom">
    <div class="breadcrumb">
      <div class="breadcrumb-item"> {{ $t('home') }}</div>
      <div class="breadcrumb-item"> {{ $t('contacts') }}</div>
    </div>
    <div class="page-title d-none d-md-block"> {{ $t('contact-us') }}</div>
    <div class="contacts-navigation-wrapper d-none d-md-flex">
      <a href="#heading" class="">
        <MainButton :theme="'solid-white'" :size="'medium'" :button-text="$t('send-inquiry')"/>
      </a>
      <a href="#contacts" class="button-contacts">
        <MainButton :theme="'solid-white'" :size="'medium'" :button-text="$t('contacts')"/>
      </a>
      <a href="#fanshop" class="button-contacts">
        <MainButton :theme="'solid-white'" :size="'medium'" :button-text="$t('fan-shop')"/>
      </a>
    </div>
    <div id="heading" class=" row  g-0  heading-wrapper">
      <div class="col-md-6">
        <div class="map-image"></div>
      </div>
      <div class="col-md-6 right-column">
        <div class="title"> {{ $t('contacts-heading-title') }}</div>
        <div class="subtitle"> {{ $t('contacts-heading-subtitle') }}</div>
        <div class="input-item">
          <MainInput :label="$t('your-name')" v-model="form.name"/>
        </div>
        <div class="input-item">
          <MainInput :label="$t('your-phone-email')" v-model="form.contact"/>
        </div>
        <div class="input-item">
          <TextArea :label="$t('your-message')" v-model="form.message"/>
        </div>
        <div class="button-container">
          <MainButton :theme="'solid-green'" :size="'large'" :button-text="$t('send-now')" :icon="'arrow'" @click="sendForm()"/>
        </div>
      </div>
    </div>
    <div id="contacts" class="contact-details-wrapper">
      <div class="top-row">
        <div class="title"> {{ $t('office-1-title') }}</div>
        <div class="row">
          <div class="contact-item-wrapper col-md-4">
            <div class="contact-placeholder"> {{ $t('address') }}:</div>
            <div class="contact-item"> {{ $t('office-1-address') }}</div>
          </div>
          <div class="contact-item-wrapper col-md-4">
            <div class="contact-placeholder"> Email:</div>
            <div class="contact-item"> {{ $t('office-1-email') }}</div>
          </div>
        </div>

      </div>
      <div class="middle-row row">
        <div class="col-md-4">
          <div class="title"> {{ $t('office-2-title') }}</div>
          <div class="contact-item-wrapper mt-3">
            <div class="contact-item"> {{ $t('office-2-name') }}</div>
            <div class="contact-placeholder">Email:</div>
            <div class="contact-item"> {{ $t('office-2-email') }}</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="title"> {{ $t('office-3-title') }}</div>
          <div class="contact-item-wrapper mt-3">
            <div class="contact-item"> {{ $t('office-3-name') }}</div>
          </div>
        </div>

      </div>
      <div class="bottom-row">
        <div class="ms-auto social-icons-wrapper d-md-flex align-items-center">
          <div class="text-center"> {{ $t('follow-us') }}:</div>
          <div class="d-flex justify-content-center">
            <a href="https://www.facebook.com/pfcberoeok" class="social-icon">
              <FacebookIcon/>
            </a>
            <a href="https://www.instagram.com/pfcberoe1916/" class="social-icon">
              <InstagramIcon/>
            </a>
            <a href="https://www.youtube.com/@PFCBeroe" class="social-icon">
              <YoutubeIcon/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="fanshop" class="contact-details-wrapper">
      <div class="top-row">
        <div class="title"> {{ $t('fan-shop-title') }}</div>
      </div>
      <div class="bottom-row row">
        <div class="row">
          <div class="contact-item-wrapper col-md-4">
            <div class="contact-placeholder"> {{ $t('address') }}:</div>
            <div class="contact-item"> {{ $t('fan-shop-address') }}</div>
          </div>
          <div class="contact-item-wrapper col-md-4">
            <div class="contact-placeholder"> {{ $t('phone-email') }}:</div>
            <div class="contact-item"> {{ $t('fan-shop-phone') }}</div>
            <div class="contact-item"> {{ $t('fan-shop-email') }}</div>
          </div>
          <div class="contact-item-wrapper col-md-4">
            <div class="contact-placeholder"> {{ $t('working-time') }}:</div>
            <div class="contact-item"> {{ $t('days-hours-open') }}</div>
            <div class="contact-item"> {{ $t('days-hours-closed') }}</div>
          </div>
        </div>
        <div class="mt-5 mt-md-0 social-icons-wrapper d-md-flex align-items-center">
          <div class="text-center"> {{ $t('follow-us') }}:</div>
          <div class="d-flex justify-content-center ">
            <a href="https://www.facebook.com/profile.php?id=61560429137563" class="social-icon">
              <FacebookIcon/>
            </a>
            <a href="https://www.instagram.com/beroefanshop/" class="social-icon">
              <InstagramIcon/>
            </a>
          </div>
        </div>
        <video
            ref="videoElement"
            poster="~@/assets/videos/poster-video.png"
            src="~@/assets/videos/fan-shop-video.mp4"
            class="fan-shop-video"
            loop
            muted
            controls
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <ContactFormModal ref="modalComponent">
      <div class="model">
        <div v-if="showErrorMessage">
          <div class="d-flex justify-content-center">
            <ErrorIcon/>
          </div>
          <div class="text-center text-danger mt-3">{{ $t('contact-form-error-message') }}</div>
        </div>
        <div v-if="showValidationErrorMessage">
          <div class="d-flex justify-content-center">
            <ErrorIcon/>
          </div>
          <div class="text-center text-danger mt-3">{{ $t('contact-form-validation-error-message') }}</div>
        </div>
        <div v-if="showSuccessMessage">
          <div class="d-flex justify-content-center">
            <CheckCircle/>
          </div>
          <div class="text-center text-success mt-3">{{ $t('contact-form-success-message') }}</div>
        </div>
      </div>
    </ContactFormModal>
  </div>

</template>

<script setup>
import MainButton from "@/components/Common/Buttons/MainButton.vue";
import MainInput from "@/components/Common/Inputs/MainInput.vue";
import TextArea from "@/components/Common/Inputs/TextArea.vue";
import FacebookIcon from "@/components/Common/Icons/FacebookIcon.vue";
import YoutubeIcon from "@/components/Common/Icons/YoutubeIcon.vue";
import InstagramIcon from "@/components/Common/Icons/InstagramIcon.vue";
import ErrorIcon from "@/components/Common/Icons/ErrorIcon.vue";
import CheckCircle from "@/components/Common/Icons/CheckCircle.vue";
import ContactFormModal from "@/components/Components/ModalMain/ContactFormModal.vue";

import { ref } from "vue";
import axios from "axios";
import { useReCaptcha } from 'vue-recaptcha-v3'

const form = ref({
  name: null,
  contact: null,
  message: null
})

const showSuccessMessage = ref(false)
const showErrorMessage = ref(false)
const showValidationErrorMessage = ref(false)

const modalComponent = ref()

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

function validateForm() {
  const { name, contact, message } = form.value

  return !(!name || name.trim().length < 3 ||
      !contact || contact.trim().length < 3 ||
      !message || message.trim().length < 3);
}

async function sendForm() {
  showSuccessMessage.value = false
  showErrorMessage.value = false
  showValidationErrorMessage.value = false

  if (!validateForm()) {
    showValidationErrorMessage.value = true
    modalComponent.value.showModal()
    return
  }

  await recaptchaLoaded()
  const token = await executeRecaptcha('login')

  if (!token) {
    showErrorMessage.value = true
    modalComponent.value.showModal()
    return
  }

  modalComponent.value.hideModal()

  try {
    await axios.post('/api/contact', { ...form.value, token })
    showSuccessMessage.value = true
    form.value = { name: null, contact: null, message: null }
  } catch (err) {
    showErrorMessage.value = true
  } finally {
    modalComponent.value.showModal()
  }
}

</script>

<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background-color: $color-grey-100;
  padding-top: 100px;
  color: $color-green-dark;
}

.page-title {
  @include title-h2-bold;
  text-transform: uppercase;
}

.contacts-navigation-wrapper {
  margin: $spacing-3 0;

  .button-contacts {
    margin: 0 10px;
  }
}


.heading-wrapper {
  border-radius: 30px;
  min-height: 480px;
  overflow: hidden;

  .right-column {
    padding: 20px;
    background: $color-white;

    .input-item {
      margin: $spacing-2 0;
    }

    .title {
      @include title-h4-bold;
      margin-bottom: $spacing-2;
    }

    .subtitle {
      @include body-16-regular;
      margin-bottom: $spacing-5;
    }
  }

  .map-image {
    background-image: url("@/assets/images/map-contacts.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 480px;
  }
}

.contact-details-wrapper {
  margin-top: $spacing-10;
  background: $color-white;
  border-radius: 30px;

  .top-row, .middle-row, .bottom-row {
    padding: 30px 20px;
  }

  .top-row, .middle-row {
    border-bottom: 1px solid $color-grey-200;
  }

  .title {
    @include title-h4-bold;

  }

  .contact-placeholder {
    @include body-16-regular;
    margin-top: $spacing-2;
  }

  .contact-item {
    @include title-h5-regular;
    margin-bottom: $spacing-1;
  }

  .social-icon {
    margin: 20px 30px 30px;
    cursor: pointer;
  }
}


@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 30px;

  }

  .heading-wrapper {
    min-height: 700px;

    .right-column {
      padding: 50px;
    }

    .map-image {
      min-height: 100%;
    }

    .button-container {
      max-width: 40%;
      transition: 0.3s ease-in-out;

      &:hover {
        max-width: 41%;
      }
    }
  }
  .contact-details-wrapper {
    .top-row, .middle-row, .bottom-row {
      padding: 30px 50px;
    }
  }

}

@media screen and (min-width: 1500px) {


}
</style>