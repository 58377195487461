<template>
  <div class="page-wrapper" v-if="players">
    <div class="container-custom">
      <div class="page-title">{{ $t('team') }}</div>

      <!-- Goalkeepers -->
      <div class="page-subtitle">{{ $t('goalkeepers') }}</div>
      <div class="row g-3" v-if="goalkeepers.length">
        <div v-for="(player, index) in goalkeepers" :key="index"
             class=" col-md-6 col-lg-4 col-xl-3 col-12 one-specialist">
          <router-link :to="{name: 'OnePlayerView', params: { id: player.id } }">
            <OnePlayerThumbnail
                :category="$t(player.content.position)"
                :img-src="player.content.gallery ? player.content.gallery[0] : null"
                :title="player.content.playerName"
                :number="player.content.number"
            />
          </router-link>
        </div>
      </div>

      <!-- Defenders -->
      <div class="page-subtitle">{{ $t('defenders') }}</div>
      <div class="row g-3" v-if="defenders.length">
        <div v-for="(player, index) in defenders" :key="index" class="col-md-3 col-lg-4 col-xl-3 col-12 one-specialist">
          <router-link :to="{name: 'OnePlayerView', params: { id: player.id } }">
            <OnePlayerThumbnail
                :category="$t(player.content.position)"
                :img-src="player.content.gallery ? player.content.gallery[0] : null"
                :title="player.content.playerName"
                :number="player.content.number"
            />
          </router-link>
        </div>
      </div>

      <!-- Midfielders -->
      <div class="page-subtitle">{{ $t('midfielders') }}</div>
      <div class="row g-3" v-if="midfielders.length">
        <div v-for="(player, index) in midfielders" :key="index"
             class="col-md-3 col-lg-4 col-xl-3 col-12 one-specialist">
          <router-link :to="{name: 'OnePlayerView', params: { id: player.id } }">
            <OnePlayerThumbnail
                :category="$t(player.content.position)"
                :img-src="player.content.gallery ? player.content.gallery[0] : null"
                :title="player.content.playerName"
                :number="player.content.number"
            />

          </router-link>
        </div>
      </div>

      <!-- Forwards -->
      <div class="page-subtitle">{{ $t('forwards') }}</div>
      <div class="row g-3" v-if="forwards.length">
        <div v-for="(player, index) in forwards" :key="index" class="col-md-3 col-lg-4 col-xl-3 col-12 one-specialist">
          <router-link :to="{name: 'OnePlayerView', params: { id: player.id } }">
            <OnePlayerThumbnail
                :category="$t(player.content.position)"
                :img-src="player.content.gallery ? player.content.gallery[0] : null"
                :title="player.content.playerName"
                :number="player.content.number"
            />
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import OnePlayerThumbnail from "@/components/Components/OnePlayerThumbnail/OnePlayerThumbnail.vue";

export default {
  name: 'TeamView',
  components: {
    OnePlayerThumbnail,
  },
  data() {
    return {
      players: [],
      goalkeepers: [],
      defenders: [],
      midfielders: [],
      forwards: []
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("utils/showPreloader");
      const response = await this.$store.dispatch("players/getPlayers");
      this.players = response.data;
      this.filterPlayersByPosition();
    } catch (err) {
      console.log(err);
    } finally {
      await this.$store.dispatch("utils/hidePreloader");
    }
  },
  methods: {
    filterPlayersByPosition() {
      this.goalkeepers = this.players.filter(player => player.content.position === 'goalkeeper'); // Goalkeepers
      this.defenders = this.players.filter(player => player.content.position === 'defender');   // Defenders
      this.midfielders = this.players.filter(player => player.content.position === 'midfielder'); // Midfielders
      this.forwards = this.players.filter(player => player.content.position === 'forward');    // Forwards
    }
  }
};
</script>


<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background: $color-grey-100;
  padding-top: 100px;
  color: $color-green-dark;
}

.heading {

  padding: 20px 0px;
}

.page-title {
  @include title-h3-bold;
  text-transform: uppercase;
  margin-bottom: $spacing-2;
}

.page-subtitle {
  @include title-h4-bold;
  padding: $spacing-1 0;
  margin: $spacing-3 0;
  border-bottom: 1px solid $color-grey-400;
}

@media screen and (min-width: 760px) {
  .page-wrapper {
    padding-top: 30px;
  }

  .grid-carousel {
    padding: 0 20px 100px 120px;
  }


}

@media screen and (min-width: 1700px) {
  .heading {

    padding: 20px 300px;
  }
  .grid-carousel {
    padding: 25px 60px 100px 160px;
  }
}
</style>