<template>
  <div
      class=""
      v-bind:class="{
      'main-button-wrapper': true,
      'button-size-small': size === 'small',
      'button-size-medium': size === 'medium',
      'button-size-large': size === 'large',
      'button-size-medium-large': size === 'medium-large',
      'button-theme-solid-green': theme === 'solid-green',
      'button-theme-outline-white': theme === 'outline-white',
      'button-theme-solid-white': theme === 'solid-white',
      'button-theme-solid-brown': theme === 'solid-brown',
      'button-theme-solid-white-brown': theme === 'solid-white-brown',
    }"
      @click="handleClick"
  >
    <div class="d-flex justify-content-center align-items-center">
      <div v-if="icon" class="icons-container me-2"></div>
      <div> {{ buttonText }}</div>
      <div v-if="icon" class="icons-container ms-auto">
        <ArrowIcon :width="16" :height="17" v-if="icon === 'arrow'" class="arrow-icon"/>
      </div>
    </div>
  </div>
</template>


<script>
import ArrowIcon from "@/components/Common/Icons/ArrowIcon.vue";

export default {
  name: "MainButton",
  components: {ArrowIcon},
  props: {
    buttonText: {
      type: String
    },
    theme: {
      type: String
    },
    size: {
      type: String
    },
    icon: {
      type: String
    }
  }
};
</script>


<style lang="scss" scoped>
.main-button-wrapper {

  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 10px;
  font-family: $font-bold;
  text-align: center;

  .arrow-icon {
    transition: 0.5s ease;
  }

  &:hover {
    transition: 0.5s ease-in-out;

    .arrow-icon {
      transition: 0.5s ease;
      transform: rotate(45deg);
    }
  }
}

.button-theme-solid-green {
  background: $color-green-main;
  color: $color-white;

  &:hover {
    background: $color-green-light;
    color: $color-green-dark;

  }
}

.button-theme-solid-white {
  background: $color-white;
  color: $color-green-dark;

  &:hover,
  &.button-hover { // Apply hover styles when clicked
    background: $color-green-main;
    color: $color-white;
  }
}

.button-size-large {
  font-size: 16px;
  padding: 25px 25px;
  border-radius: 50px;
}

.button-size-small {
  font-size: 10px;
  padding: 10px 15px;
}

.button-size-medium {
  font-size: 14px;
  padding: 15px 25px;

}

.button-size-medium-large {
  font-size: 16px;
  padding: 18px 35px;
  border-radius: 50px;
}


@media screen and (min-width: 768px) {

}
</style>
