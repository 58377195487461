<template>

  <div v-if="$root.$i18n.locale !== 'en'" class="d-flex lang-button align-items-center  "
       @click.prevent="setLanguage('en')">
    <LanguageIcon/>
    <div class="ms-1  ">EN</div>
  </div>
  <div v-if="$root.$i18n.locale !== 'bg'" class="d-flex lang-button align-items-center  "
       @click.prevent="setLanguage('bg')">

    <LanguageIcon/>
    <div class="ms-1 ">БГ</div>
  </div>
</template>

<script setup>
import {setLang} from '@/i18n';
import LanguageIcon from "@/components/Common/Icons/LanguageIcon.vue";

function setLanguage(lang) {
  setLang(lang)
  window.location.reload();
}
</script>

<style lang="scss" scoped>
.lang-button {
  padding: 6px 10px;
  background: $color-white;
  border-radius: 30px;
  cursor: pointer;
  @include body-18-bold;
}
</style>