<template>
  <div class="page-wrapper container-custom">
    <div class="breadcrumb">
      <div class="breadcrumb-item"> {{ $t('home') }}</div>
      <div class="breadcrumb-item"> {{ title }}</div>
    </div>
    <div class="page-title  d-md-block"> {{ title }}</div>
    <div class="contacts-navigation-wrapper  d-md-flex">
      <router-link class="button-games" :to="{name: 'GamesNext'}">
        <MainButton :theme="$t('next-games') === title ? 'solid-green' : 'solid-white'"
                    :size="'medium'"
                    :button-text="$t('next-games')"/>
      </router-link>
      <router-link class="button-games" :to="{name: 'GamesPrevious'}">
        <MainButton :theme="$t('previous-games') === title ? 'solid-green' : 'solid-white'"
                    :size="'medium'"
                    :button-text="$t('previous-games')"/>
      </router-link>
      <router-link class="button-games" :to="{name: 'GamesStandings'}">
        <MainButton :theme="$t('standings') === title ? 'solid-green' : 'solid-white'"
                    :size="'medium'"
                    :button-text="$t('standings')"/>
      </router-link>
    </div>

    <router-view></router-view>

  </div>

</template>

<script>

import MainButton from "@/components/Common/Buttons/MainButton.vue";


export default {
  name: 'ContactsView',
  components: {MainButton,},
  props: {},
  data() {
    return {
      games: null,
      title: null
    }
  },
  watch: {
    async '$route'() {
      this.loadPageInfo();
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("utils/showPreloader");
      this.games = await this.$store.dispatch("nextGames/getAllGames")
    } catch (err) {
      console.log(err)
    } finally {
      await this.$store.dispatch("utils/hidePreloader");
    }

    this.loadPageInfo();
  },
  methods: {
    loadPageInfo() {
      switch (this.$route.name) {
        case "GamesPrevious":
          this.title = this.$t('previous-games');
          break;
        case "GamesNext":
          this.title = this.$t('next-games');
          break;
        case "GamesStandings":
          this.title = this.$t('standings');
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background-color: $color-grey-100;
  padding-top: 100px;
  color: $color-green-dark;
}

.page-title {
  @include title-h4-bold;
  text-transform: uppercase;
  margin-bottom: $spacing-2;
}

.games-card {
  padding: 20px 0;
}

.button-games {
  margin: 0 10px;
}


@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 30px;

  }
  .page-title {
    @include title-h2-bold;
  }

}

@media screen and (min-width: 1500px) {


}
</style>