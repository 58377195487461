<template>
  <article v-bind:class="{
    'horizontal-wrapper': type === 'horizontal',
  }">
    <router-link :to="{name: 'OneNewsView', params: {id: id}}">
      <div class="news-thumbnail">
        <div class="thumbnail-container">
          <div :style="`background-image: url(/storage/${imgSrc})`" class="thumbnail-image">
          </div>
          <div class="arrow-hover">
            <ArrowIcon/>
          </div>
        </div>
        <div class="news-thumbnail-info d-flex align-items-center">
          <div>
            <div class="d-flex align-items-center">
              <div class="news-thumbnail-date">{{ date }}</div>
              <div class="category-wrapper mb-2 "> {{ $t(category) }}</div>
            </div>
            <h2 class="news-thumbnail-title">{{ title }}</h2>
            <div class="description mt-auto" v-html="sanitizedContent"></div>


          </div>
        </div>
      </div>
    </router-link>


  </article>
</template>

<script>

import DOMPurify from 'dompurify';
import ArrowIcon from "@/components/Common/Icons/ArrowIcon.vue";

export default {
  name: 'OneNewsPostThumbnail',
  components: {ArrowIcon},
  props: {
    id: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String
    },
    category: {
      type: String
    },
    type: {
      type: String
    }
  },
  computed: {
    sanitizedContent() {
      return DOMPurify.sanitize(this.content, {});
    },
  },
}
</script>

<style lang="scss" scoped>


.news-thumbnail {
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  font-family: $font-regular;
  background: $color-white;


  .thumbnail-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .thumbnail-image {
      width: 100%;
      transition: 0.3s ease-in-out;
      position: relative;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

    }
  }

  .category-wrapper {
    @include body-12-bold;
    font-family: $font-regular;
    background: $color-grey-100;
    padding: 5px 10px;
    border-radius: 20px;
  }

  .description {
    @include body-16-regular;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .news-thumbnail-info {
    color: $color-green-dark;
    text-align: left;
    padding: $spacing-3;
    height: 210px;

    .news-thumbnail-date {
      font-size: 12px;
      margin: $spacing-1 0;
    }

    .news-thumbnail-title {
      @include title-h5-bold;
      text-transform: uppercase;
    }
  }

  .arrow-hover {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(0%, -50%) scale(2);
  }

}


@media screen and (min-width: 768px) {
  .horizontal-wrapper {
    .news-thumbnail {
      display: flex;

    }

    .thumbnail-container {
      min-width: 500px;

      .thumbnail-image {
        height: 270px;
      }
    }
  }
  .news-thumbnail {
    position: relative;

    .thumbnail-image {
      transition: transform 0.3s ease-in-out;
      display: block;
    }


    &:hover {
      transition: 0.3s ease-in-out;
      box-shadow: inset 0 0 0 2px $color-green-main;

      .arrow-hover {
        display: block;
      }

      .thumbnail-image {
        transform: scale(1.1); /* Scales the image */
      }


      .thumbnail-image::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-green-main; /* Green overlay */
        opacity: 0.5; /* Adjust opacity as needed */
        transition: opacity 0.3s ease-in-out; /* Smooth transition of the overlay */
        z-index: 1; /* Make sure the overlay is on top of the image */
      }
    }


  }

}

@media screen and (min-width: 1500px) {
  .news-thumbnail {
    .thumbnail-container {
      .thumbnail-image {
        height: 250px;
      }
    }

  }
}
</style>