<template>

  <svg :width="width"
       :height="height" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 1.5L1.4375 15.0625" class="icon-path" :stroke="stroke" stroke-width="1.75" stroke-linecap="round"/>
    <path d="M1 1.5H15V15.5" class="icon-path" :stroke="stroke" stroke-width="1.75" stroke-linecap="round"/>
  </svg>


</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    width: {
      type: [String, Number],
      default: '27',
    },
    height: {
      type: [String, Number],
      default: '25',
    },
    stroke: {
      type: String,
      default: '#FFFFFF',
    },
  },
}
</script>

<style scoped lang="scss">
.icon-svg {
  transition: fill 0.3s, stroke 0.3s ease-in-out;
}

.icon-path {
  transition: fill 0.3s ease-in-out;
}

.main-button-wrapper:hover .icon-path {
  stroke: $color-green-dark;
}
</style>
