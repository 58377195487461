<script>
import InstagramIcon from "@/components/Common/Icons/InstagramIcon.vue";
import FacebookIcon from "@/components/Common/Icons/FacebookIcon.vue";
import BeroeLogo from "@/components/Common/Icons/BeroeLogo.vue";
import GreenHeartSmall from "@/components/Common/Icons/GreenHeartSmall.vue";
import InteraLogo from "@/components/Common/Icons/InteraLogo.vue";
import YoutubeIcon from "@/components/Common/Icons/YoutubeIcon.vue";

export default {
  name: "SiteFooter",
  components: {YoutubeIcon, InteraLogo, GreenHeartSmall, BeroeLogo, FacebookIcon, InstagramIcon}
}
</script>

<template>
  <div class="footer-wrapper container-custom ">
    <div class="first-row d-md-flex align-items-center">
      <div class="d-md-flex  align-items-center">
        <div class="d-flex justify-content-center mb-3 mb-md-0">
          <BeroeLogo/>
        </div>
        <div class="ms-3 beroe-logo text-center text-md-start mb-4 mb-md-0">
          <div> {{ $t('professional-football-club') }}</div>
          <div> {{ $t('beroe-stara-zagora') }}</div>
        </div>
      </div>
      <div class="ms-auto social-icons-wrapper d-md-flex align-items-center">
        <div class="text-center"> {{ $t('follow-us') }}:</div>
        <div class="d-flex justify-content-center">
          <a href="https://www.facebook.com/pfcberoeok" class="social-icon">
            <FacebookIcon/>
          </a>
          <a href="https://www.instagram.com/pfcberoe1916/" class="social-icon">
            <InstagramIcon/>
          </a>
          <a href="https://www.youtube.com/@PFCBeroe" class="social-icon">
            <YoutubeIcon/>
          </a>
        </div>
      </div>
    </div>
    <div class="second-row d-md-flex text-center ">
      <router-link :to="{name: 'NewsView'}">
        <div class="nav-item "> {{ $t('news') }}</div>
      </router-link>
      <router-link :to="{name: 'TeamView'}">
        <div class="nav-item"> {{ $t('team') }}</div>
      </router-link>
      <router-link :to="{name: 'AcademyView'}">
        <div class="nav-item"> {{ $t('academy') }}</div>
      </router-link>
      <router-link class="ms-auto" :to="{name: 'GamesNext'}">
        <div class="ms-auto nav-item"> {{ $t('fixtures') }}</div>
      </router-link>
      <router-link class="" :to="{name: 'GamesStandings'}">
        <div class="nav-item"> {{ $t('standings') }}</div>
      </router-link>
    </div>
    <div class="third-row d-md-flex justify-content-between align-items-end text-center ">
      <div class="nav-item"> {{ $t('footer-copyright') }}</div>
      <div class="d-md-flex">
        <a href="/static/PFCBeroeActivityReport2023.pdf">
          <div class="nav-item"> {{ $t('financial-statement') }}</div>
        </a>
        <router-link :to="{name: 'PrivacyPolicyView'}">
          <div class="nav-item"> {{ $t('privacy-policy') }}</div>
        </router-link>
        <router-link :to="{name: 'RightsOfUseView'}">
          <div class="nav-item"> {{ $t('terms-and-conditions') }}</div>
        </router-link>
        <router-link :to="{name: 'DataProtectionView'}">
          <div class="nav-item"> {{ $t('gdpr') }}</div>
        </router-link>
        <router-link class="ms-auto" :to="{name: 'ContactsView'}">
          <div class="nav-item"> {{ $t('contacts') }}</div>
        </router-link>
      </div>
      <div class="d-flex align-items-lg-end align-items-center  justify-content-center mt-5 mt-md-0">
        <div class="me-1">{{ $t('developed-with') }} <span> <GreenHeartSmall/> </span> {{ $t('by') }}:</div>
        <a href="https://intera.bg/">
          <InteraLogo/>
        </a>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-wrapper {
  background: $color-grey-100;
}

.social-icon {
  margin: 20px 30px 30px;
  cursor: pointer;
}

.nav-item {
  margin: 30px 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    color: $color-green-main;
  }
}

.first-row {
  @include body-14-regular;

  .beroe-logo {
    text-transform: uppercase;
  }
}


.third-row {
  padding: 30px 30px 100px;
  @include body-14-regular;
}

.second-row {
  @include body-16-bold;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .first-row, .second-row, {
    padding: 30px 0;
    border-bottom: 1px solid $color-grey-300;
    @include body-12-bold;
  }
  .nav-item {
    margin: 0 10px;
  }
  .second-row {
    text-transform: none;
  }
  .third-row {
    @include body-12-bold;
    font-size: 10px;
    padding: 30px 0px 100px;
  }
  .social-icon {
    margin: 20px 10px 30px;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .first-row, .second-row, {
    padding: 30px;

  }
  .third-row {
    @include body-12-bold;
    padding: 30px 30px 100px;
  }
  .second-row {
    @include body-16-bold;
  }
  .nav-item {
    margin: 0 20px;
  }
}

</style>