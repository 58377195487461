import axios from "axios";

export default {
    namespaced: true,
    state: {
        games: [],
        standing: [],
    },
    mutations: {},
    actions: {
        async getAllGames({state},) {
            if (state.games.length > 0) {
                return state.games;
            }
            const response = await axios.get('/api/league/fixtures');
            state.games = response.data
            return state.games;
        },
        async getStandings({state},) {
            if (state.standing.length > 0) {
                return state.standing;
            }
            const response = await axios.get('/api/league/standings');
            state.standing = response.data;
            return state.standing;
        },
    }
};
