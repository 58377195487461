<template>
  <article class="news-wrapper">
    <div class="news-heading">
      <h2 class="news-title">{{ title }}</h2>
      <div class="news-date">{{ date }}</div>
    </div>
    <div :style="{ backgroundImage: `url(/storage/${imageUrl})` }" class="news-image"></div>
    <div class="d-flex justify-content-center">
      <div class="news-content " v-html="content"></div>
    </div>


  </article>
</template>

<script>


export default {
  name: 'OneNewsPost',
  components: {},
  props: {
    date: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String
    },
    content: {
      type: String
    }
  },

}
</script>

<style lang="scss" scoped>

figure {
  img {
    object-fit: contain;
  }
}

.news-wrapper {
  overflow: hidden;
  font-family: $font-regular;

  .news-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    min-height: 250px;
    width: 100%;
  }

  .news-heading {
    color: $color-green-dark;
    text-align: left;

    .news-date {
      font-size: 12px;
      margin: $spacing-1 0;
    }

    .news-title {
      font-size: $subtitle-1;
      font-family: $font-bold;
    }
  }

  .news-content {
    word-break: break-all;
    :deep(img) {
      max-width: 100%;
    }
  }

}

.grey-background {
  background: $color-grey-100;
}

@media screen and (min-width: 768px) {
  .news-wrapper {
    .news-image {
      min-height: 450px;
      margin-bottom: $spacing-5;
    }

    .news-heading {
      text-align: center;


      .news-date {
        font-size: 12px;
        margin: $spacing-1 0;
      }

      .news-title {
        font-size: $title-h5;
      }
    }
  }
}

</style>