import {createStore} from 'vuex';
import news from "./news"
import indexCarousel from "@/store/indexCarousel";
import nextGames from "@/store/nextGames";
import players from "@/store/players";
import academy from "@/store/academy";
import history from "@/store/history";
import utils from "@/store/utils";

export default createStore({
    modules: {
        news,
        indexCarousel,
        nextGames,
        players,
        academy,
        history,
        utils
    },
});
