<template>
  <div class="page-wrapper">
    <div class="container-custom">
      <div class="component-wrapper" v-if="player">
        <div class="row g-0">
          <div class="col-md-7" v-if="player.hasOwnProperty('gallery') && player.gallery.length > 0">
            <div class="player-photo-wrapper">
              <div class="d-flex">
                <router-link :to="{ name: 'TeamView' }" class=" d-flex align-items-center h-100">
                  <ArrowRightIcon class="arrow" :stroke="'#00A651'"/>
                  <div class="ms-2 button"> {{ $t('back') }}</div>
                </router-link>
                <div class="ms-auto number-small">{{ player.number }}</div>
              </div>
              <div class="number-big">{{ player.number }}</div>
              <div class="player-image" :style="{ backgroundImage: `url(/storage/${player.gallery[0]})` }">
                <div class="white-gradient"></div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="player-name">{{ player.playerName }}</div>
            <div class="scrollable-content">
              <div class="player-info-wrapper ">
                <div class="">
                  <div class="player-placeholder"> {{ $t('position') }}:</div>
                  <div class="info-item"> {{ $t(player.position) }}</div>
                </div>
                <div class="">
                  <div class="player-placeholder"> {{ $t('birth-date') }}:</div>
                  <div class="info-item"> {{ player.dateOfBirth }}</div>
                </div>
                <div class="">
                  <div class="player-placeholder"> {{ $t('birth-place') }}:</div>
                  <div class="info-item"> {{ player.placeOfBirth }}</div>
                </div>
                <div class="">
                  <div class="player-placeholder"> {{ $t('nationality') }}:</div>
                  <div class="info-item"> {{ player.nationality }}</div>
                </div>
                <div class="">
                  <div class="player-placeholder"> {{ $t('height') }}:</div>
                  <div class="info-item"> {{ player.height }}</div>
                </div>


              </div>
              <div class="player-bio">
                <div class="player-placeholder"> {{ $t('biography') }}:</div>
                <div class="" v-html="player.biography"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


import ArrowRightIcon from "@/components/Common/Icons/ArrowRightIcon.vue";

export default {
  name: 'OnePlayerView',
  components: {ArrowRightIcon,},
  data() {
    return {
      player: null,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    const response = await this.$store.dispatch("players/getPlayer", id);
    this.player = response.content;
  },
  methods: {}
};
</script>


<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background: $color-grey-100;
  padding-top: 100px;
}

.button {
  color: $color-green-main;
  @include body-18-bold;
}

.arrow {
  transform: rotate(180deg) scale(0.8);
}

.component-wrapper {
  border-radius: 30px;
  background: $color-white;
  color: $color-green-dark;
  width: 100%;


  .player-name {
    @include title-h1-black;
    font-size: 45px;
    line-height: 50px;
    color: $color-green-main;
    padding: 20px;

    text-transform: uppercase;
    text-align: left;
  }

  .number-small {
    @include title-h1-bold;
    text-align: left;
    color: $color-green-main;
  }

  .number-big {
    font-size: 200px;
    line-height: 200px;
    font-family: $font-bold;
    color: $color-grey-100;
    text-align: center;
  }

  .player-info-wrapper {
    padding: 20px;
    text-align: left;
    color: $color-green-dark;

    .player-placeholder {
      @include body-18-bold;
    }

    .info-item {
      @include title-h4-bold;
      font-family: $font-black;
      margin-bottom: $spacing-2;
      text-transform: uppercase;
      color: $color-green-main;
    }
  }

  .player-photo-wrapper {
    padding: 25px;
    border-bottom: 1px solid $color-grey-200;
    height: 100%;
    position: relative;


    .player-image {
      width: 100%;
      height: 80%;
      background: no-repeat center;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);

      .white-gradient {
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 55%, rgba(255, 255, 255, 1) 100%);
        width: 100%;
        height: 100%;
      }
    }
  }

  .player-bio {
    border-top: 1px solid $color-grey-100;
    padding: 20px;

    .player-placeholder {
      @include body-18-bold;
      margin-bottom: $spacing-2;
    }
  }
}


@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 25px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }


  .component-wrapper {
    .player-photo-wrapper {
      height: 94vh;
    }

    .player-photo-wrapper, .more-news-wrapper {
      padding: 40px;
      border: none;
    }

    .player-info-wrapper {
      padding: 50px;

      .info-item {
        margin-bottom: $spacing-2;
      }
    }

    .player-name {
      @include title-h3-black;
      padding: 20px 50px;
      border-bottom: 1px solid $color-grey-200;
    }

    .number-big {
      font-size: 300px;
      line-height: 200px;
      padding-top: 50px;
      font-family: $font-bold;
      color: $color-grey-100;
    }

    .player-photo-wrapper {
      border-right: 1px solid $color-grey-200;

      .player-image {

        border-radius: 30px;
        margin-bottom: 50px;
      }
    }

    .player-bio {
      padding: 50px;
    }

    .scrollable-content {
      max-height: 80vh;
      overflow-y: auto;
    }
  }

}

@media screen and (min-width: 1200px) {
  .component-wrapper {
    .number-big {
      font-size: 500px;
      line-height: 300px;
    }

    .player-name {
      @include title-h1-black;
      padding: 50px;
      border-bottom: 1px solid $color-grey-200;
    }
  }
}
</style>