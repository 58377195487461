<script setup>

</script>

<template>
  <svg width="114" height="154" viewBox="0 0 114 154" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_490_1542)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 0H33.1782V13.6037H40.3801V0H73.4968V13.6652H80.7603V0H113.938V46.7819H33.1782V59.2775H114V89.932C114 101.504 112.461 107.352 109.076 113.938C106.983 117.94 104.028 121.941 100.458 125.511C90.9168 135.052 77.6825 142.5 70.5421 146.44L58.662 152.903C57.2462 153.703 56.6922 153.58 55.3996 152.903L43.6426 146.501C35.6404 142.131 25.9762 136.283 18.2819 129.82C9.23326 122.248 2.4622 112.892 0.800216 101.012C0.307775 97.5032 0.12311 93.1328 0.12311 89.0086V0H0Z"
            fill="#0E4127"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.21875 6.34082V89.0093C6.21875 92.641 6.34186 96.519 6.8343 100.151C8.31162 110.492 14.344 118.494 22.1615 125.081C29.4866 131.236 38.1658 136.53 46.5373 141.085L55.0319 145.578C56.0784 146.132 56.817 146.502 57.2479 146.44C57.6788 146.379 58.4174 145.948 59.033 145.64L67.4045 141.023C77.3149 135.606 87.9023 129.205 95.9045 121.141C98.7976 118.248 101.383 114.801 103.291 111.108C106.984 103.967 107.662 97.9963 107.662 89.9326V65.5568H26.9628V40.5654H107.723V6.34082H86.979V19.8829H67.2814V6.34082H46.5989V19.8214H26.9628V6.34082H6.21875Z"
            fill="#F1F1F1"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.2959 13.4809V89.0705C13.2959 92.0867 13.419 95.1029 13.7268 98.1191C14.7117 106.86 20.1901 114.247 26.7149 119.786C33.7322 125.696 41.7959 130.189 49.7981 134.56L55.9536 138.007C56.8154 138.499 57.1847 138.376 57.9849 137.945L64.0789 134.621C73.4352 129.512 83.0994 123.788 90.6707 116.216C93.0713 113.816 95.3488 110.984 96.9493 107.906C100.089 101.812 100.458 96.7033 100.458 89.9323V72.7584H19.6361V33.3016H100.458V13.4193H94.1177V26.9614H60.1393V13.4193H53.6761V26.8999H19.5745V13.4193H13.2344L13.2959 13.4809Z"
            fill="#0E4127"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.6348 79.098V89.0084C19.6348 91.7784 19.7579 94.6099 20.0657 97.3799C20.8659 104.459 25.6672 110.306 31.2071 114.985C34.7773 118.001 39.0246 120.709 42.9641 123.233C47.2114 125.88 51.2125 128.281 55.6445 130.62C56.7525 131.235 57.1218 131.235 58.2914 130.62L61.1844 129.142C65.0009 126.865 69.3097 124.403 73.2492 121.879C77.989 118.801 82.3594 115.723 86.2374 111.784C88.2071 109.814 90.1769 107.475 91.4695 105.013C94.1164 99.9036 94.2395 95.5332 94.2395 89.8702V79.098H19.6348Z"
            fill="#F1F1F1"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.7736 86.3004V89.1319C26.7736 92.5175 26.6505 96.0261 27.7585 99.227C29.1743 103.228 32.0058 106.49 35.7606 109.63C39.1462 112.461 42.8395 114.924 46.5943 117.263C49.9183 119.294 53.1192 121.141 56.4431 122.926C56.9356 123.172 56.9356 123.172 57.428 122.926C61.3676 120.71 65.4917 118.371 69.3697 115.908C73.5555 113.2 77.6181 110.307 81.1883 106.798C82.6041 105.382 84.2045 103.536 85.1278 101.751C87.1591 97.8112 87.1591 94.3641 87.1591 90.0553V86.3004H26.7736Z"
            fill="#0E4127"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M33.1182 92.5176C33.0566 94.1796 33.1798 95.5953 33.6722 96.9495C34.7802 100.027 37.3039 102.797 39.7661 104.829C42.9055 107.475 46.2294 109.568 49.6765 111.723C51.7694 113.015 53.8623 114.247 56.0167 115.478C56.6938 115.847 57.0016 115.909 57.6787 115.478C60.6949 113.877 62.8493 112.461 65.6193 110.676C69.4357 108.214 73.3752 105.629 76.5761 102.428C79.4076 99.5964 80.8849 96.5802 80.7618 92.5176H33.0566H33.1182Z"
            fill="#F1F1F1"/>
    </g>
    <defs>
      <clipPath id="clip0_490_1542">
        <rect width="114" height="153.518" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>


<style scoped lang="scss">

</style>