<template>
  <div class="component-wrapper d-md-flex">
    <div class="next-game-date-wrapper d-flex align-items-center">
      <div>
        <div class="next-game-date"> {{ nextGameDate }}</div>
        <div class="next-game-day-hour"> {{ nextGameDay }} {{ nextGameHour }}</div>
        <div class="competition" v-if="competition"> {{ $t(competition) }}</div>
      </div>

    </div>
    <div class="row g-0 w-100">
      <div class=" col-md-4 col-lg-5 col-xl-6 ">
        <div class="next-game-teams-wrapper">
          <div class="title"> {{ $t('next-game') }}:</div>
          <div class="home-team d-flex align-items-center">
            <div class="team-logo" :style="{ backgroundImage: `url(${nextHomeTeamLogo})` }"></div>
            <div class="ms-2 team-name"> {{ nextHomeTeamName }}</div>
          </div>
          <div class="mt-2 away-team d-flex align-items-center">
            <div class="team-logo" :style="{ backgroundImage: `url(${nextAwayTeamLogo})` }"></div>
            <div class="ms-2 team-name"> {{ nextAwayTeamName }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-7 col-xl-6 ">
        <div class="d-md-flex last-game-wrapper ">
          <div class="last-game-date-wrapper d-flex align-items-center">
            <div>
              <div class="last-game-date"> {{ lastGameDate }}</div>
              <div class="last-game-day-hour"> {{ lastGameDay }}</div>
              <div class="last-game-day-hour"> {{ lastGameHour }}</div>
            </div>

          </div>
          <div>
            <div class="title"> {{ $t('last-game') }}:</div>
            <div class="d-flex">
              <div class="result-wrapper">
                <div class=""> {{ scoreHome }}</div>
                <div class="mt-2"> {{ scoreAway }}</div>
              </div>
              <div class="last-game-teams">
                <div class="home-team d-flex align-items-center">
                  <div class="team-logo" :style="{ backgroundImage: `url(${lastHomeTeamLogo})` }"></div>
                  <div class="ms-2 team-name"> {{ lastHomeTeamName }}</div>
                </div>
                <div class="mt-2 away-team d-flex align-items-center">
                  <div class="team-logo" :style="{ backgroundImage: `url(${lastAwayTeamLogo})` }"></div>
                  <div class="ms-2 team-name"> {{ lastAwayTeamName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: 'IndexNextPreviousFixture',
  components: {},
  props: {
    nextGameDate: {
      type: String
    },
    nextGameDay: {
      type: String
    },
    nextGameHour: {
      type: String
    },
    lastGameDate: {
      type: String
    },
    lastGameDay: {
      type: String
    },
    lastGameHour: {
      type: String
    },
    competition: {
      type: String
    },
    nextHomeTeamName: {
      type: String
    },
    nextHomeTeamLogo: {
      type: String
    },
    nextAwayTeamName: {
      type: String
    },
    nextAwayTeamLogo: {
      type: String
    },
    lastHomeTeamName: {
      type: String
    },
    lastHomeTeamLogo: {
      type: String
    },
    lastAwayTeamName: {
      type: String
    },
    lastAwayTeamLogo: {
      type: String
    },
    scoreHome: {
      type: String
    },
    scoreAway: {
      type: String
    },
  }
}
</script>

<style lang="scss" scoped>
.component-wrapper {
  background: $color-white;
  color: $color-green-dark;
  @include body-18-regular;
  text-transform: none;
  border-radius: 30px;
  text-align: left;

}

.team-logo {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.title {
  margin: $spacing-3 0;
}

.next-game-date-wrapper {
  background: $color-green-main;
  box-shadow: 0 4px 64.5px 0 rgba(0, 166, 81, 0.8);
  padding: 40px;
  border-radius: 30px;
  color: $color-white;
  @include body-18-regular;

  .next-game-date {
    @include title-h2-bold;
  }
}

.next-game-teams-wrapper {
  padding: 20px 40px;
}

.last-game-wrapper {
  background: $color-grey-200;
  padding: 20px 40px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;

  .last-game-date-wrapper {
    background: $color-white;
    padding: 15px;
    border-radius: 15px;
    @include body-14-regular;
    margin-right: $spacing-3;

    .last-game-date {
      @include title-h3-bold;
    }
  }

  .last-game-teams {
    padding: 15px;
  }

  .result-wrapper {
    background: $color-white;
    padding: 20px 15px;
    border-radius: 10px;
    color: $color-green-main;
    @include title-h4-bold;
    font-family: $font-black;
  }
}

@media screen and (min-width: 768px) {
  .next-game-date-wrapper {
    padding: 20px;
    @include body-14-regular;

    .next-game-date {
      @include title-h4-bold;
    }

    .next-game-teams-wrapper {
      padding: 20px;
    }
  }
  .team-name {
    @include body-14-regular
  }
  .last-game-wrapper {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
    padding: 20px;

    .last-game-date-wrapper {
      padding: 20px;

      .last-game-date {
        @include title-h4-bold;
      }
    }
  }
  .next-game-teams-wrapper {
    padding: 20px 40px;
  }
}

@media screen and (min-width: 1000px) {
  .next-game-date-wrapper {
    padding: 30px 30px;
    @include body-14-regular;

    .next-game-date {
      @include title-h2-bold;
    }
  }

  .team-name {
    @include body-18-regular
  }
  .last-game-wrapper {
    padding: 20px 40px;

    .last-game-date-wrapper {
      padding: 30px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .next-game-date-wrapper {
    padding: 30px 80px;
    @include body-14-regular;

    .next-game-date {
      @include title-h2-bold;
    }
  }
  .last-game-wrapper {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
    padding: 20px 60px;

    .last-game-date-wrapper {
      padding: 50px;
    }
  }
  .next-game-teams-wrapper {
    padding: 20px 60px;
  }
}


</style>
