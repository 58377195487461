<template>
  <Carousel ref="carousel">
    <Slide v-for="player in players" :key="player.id">
      <div class="carousel__item">
        <div class="component-wrapper">
          <div class="row g-0">
            <div class="col-md-7" v-if="player.content.hasOwnProperty('gallery')">
              <div class="main-news-wrapper">
                <div class="d-flex">
                  <div class="number-small">{{ player.content.number }}</div>
                  <div class="custom-navigation ms-auto">
                    <ArrowRightIcon class="rotate-arrow navigation-item me-5" @click="prevSlide"/>
                    <ArrowRightIcon class="navigation-item" @click="nextSlide"/>
                  </div>
                </div>

                <div class="number-big">{{ player.content.number }}</div>
                <div class="player-image"
                     :style="{ backgroundImage: `url(/storage/${player.content.gallery ? player.content.gallery[0] : null})` }"></div>

                <router-link :to="{name: 'OnePlayerView', params:{ id: player.id }}">
                  <div class="d-none d-md-flex">
                    <CircleButton class="ms-auto" :theme="'solid-green'" :size="'large'" :isHovered="isHovered"/>
                  </div>
                </router-link>


              </div>
            </div>
            <div class="col-md-5">
              <router-link :to="{name: 'OnePlayerView', params:{ id: player.id }}">
                <div class="player-name">{{ player.content.playerName }}</div>
              </router-link>
              <div class="player-info-wrapper row">
                <div class="col-md-6">
                  <div class="player-placeholder"> {{ $t('position') }}</div>
                  <div class="info-item"> {{ $t(player.content.position) }}</div>
                </div>
                <div class="col-md-6">
                  <div class="player-placeholder"> {{ $t('birth-date') }}</div>
                  <div class="info-item"> {{ player.content.dateOfBirth }}</div>
                </div>
                <div class="col-md-6">
                  <div class="player-placeholder"> {{ $t('birth-place') }}</div>
                  <div class="info-item"> {{ player.content.placeOfBirth }}</div>
                </div>
                <div class="col-md-6">
                  <div class="player-placeholder"> {{ $t('nationality') }}</div>
                  <div class="info-item"> {{ player.content.nationality }}</div>
                </div>
                <div class="col-md-6">
                  <div class="player-placeholder"> {{ $t('height') }}</div>
                  <div class="info-item"> {{ player.content.height }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {Carousel, Slide} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import CircleButton from "@/components/Common/Buttons/CircleButton.vue";
import ArrowRightIcon from "@/components/Common/Icons/ArrowRightIcon.vue";

export default defineComponent({
  name: 'IndexPlayersCarousel',
  components: {
    ArrowRightIcon,
    CircleButton,
    Carousel,
    Slide,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  props: {
    players: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const carousel = ref(null); // Reference to the Carousel component

    const prevSlide = () => {
      if (carousel.value) {
        carousel.value.prev(); // Move to the previous slide
      }
    };

    const nextSlide = () => {
      if (carousel.value) {
        carousel.value.next(); // Move to the next slide
      }
    };

    return {
      carousel,
      prevSlide,
      nextSlide,
    };
  },
});
</script>


<style lang="scss" scoped>
.navigation-item {
  cursor: pointer;
}

.rotate-arrow {
  transform: rotate(180deg);
}

.component-wrapper {
  border-radius: 30px;
  background: $color-white;
  color: $color-green-dark;
  width: 100%;


  .player-name {
    @include title-h1-black;
    font-size: 45px;
    line-height: 50px;
    color: $color-green-main;
    padding: 20px;

    text-transform: uppercase;
    text-align: left;
  }

  .number-small {
    @include title-h1-bold;
    text-align: left;
    color: $color-green-main;
  }

  .number-big {
    font-size: 200px;
    line-height: 200px;
    font-family: $font-bold;
    color: $color-grey-200;
  }

  .player-info-wrapper {
    padding: 20px;
    text-align: left;
    color: $color-green-dark;

    .player-placeholder {
      @include body-18-bold;
    }

    .info-item {
      @include body-18-bold;
      font-family: $font-regular;
      margin-bottom: $spacing-3;
    }
  }

  .main-news-wrapper {
    padding: 25px;
    border-bottom: 1px solid $color-grey-200;
    height: 100%;
    position: relative;

    .player-image {
      width: 100%;
      height: 80%;
      background: no-repeat center;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.carousel__slide {

}


.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

@media screen and (min-width: 768px) {
  .component-wrapper {
    .main-news-wrapper, .more-news-wrapper {
      padding: 40px;
    }

    .player-info-wrapper {
      padding: 30px;

      .info-item {
        @include body-12-bold;
        font-family: $font-regular;
        margin-bottom: $spacing-3;
      }

      .player-placeholder {
        @include body-12-bold;

      }
    }

    .player-name {
      @include title-h3-black;
      padding: 30px;
      border-bottom: 1px solid $color-grey-200;
    }

    .number-big {
      font-size: 250px;
      line-height: 200px;
    }

    .main-news-wrapper {
      border-right: 1px solid $color-grey-400;

      .player-image {
        height: 80%;
        border-radius: 30px;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .component-wrapper {
    .player-info-wrapper {
      .info-item {
        @include body-14-regular;

      }

      .player-placeholder {
        @include body-14-bold;

      }

    }

    .player-name {
      @include title-h2-bold;
      font-family: $font-black;
    }

    .number-big {
      font-size: 350px;
      line-height: 300px;
    }
  }
}

@media screen and (min-width: 1220px) {
  .component-wrapper {
    .player-info-wrapper {
      padding: 50px;

      .info-item {
        @include body-18-regular;
        margin-bottom: $spacing-5;
      }

      .player-placeholder {
        @include body-18-bold;
      }
    }

    .player-name {
      padding: 50px;
      @include title-h1-black;

    }

    .main-news-wrapper {

      .player-image {
        height: 90%;
      }
    }

    .number-big {
      padding-top: 50px;
      font-size: 500px;
      line-height: 300px;
    }
  }
}
</style>
