<template>
  <div>
    <textarea :placeholder="label" :value="modelValue" class="textarea" @input="handleInput"/>
  </div>

</template>

<script>
export default {
  name: 'TextArea',
  props: {
    type: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  background: $color-grey-100;
  border: none;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  min-height: 200px;

}

.textarea:hover {
  border: none;
}

.textarea:active {
  border: none;
}

.textarea:focus {
  outline: none;
}

@media screen and (min-width: 768px) {

}

@media screen and (max-width: 380px) and (max-height: 680px) {

}

@media screen and (max-width: 380px) and (max-height: 740px) {

}

@media screen and (min-width: 400px) and (min-height: 900px) {

}


</style>