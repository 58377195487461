<template>
  <div class="table-container">
    <table class="responsive-table">
      <thead>
      <tr>
        <th class="first-column text-center">№</th>
        <th class="wide">{{ $t('table-team') }}</th>
        <th>{{ $t('table-matches') }}</th>
        <th>{{ $t('table-wins') }}</th>
        <th>{{ $t('table-draws') }}</th>
        <th>{{ $t('table-loses') }}</th>
        <th>{{ $t('table-goal-difference') }}</th>
        <th>{{ $t('table-points') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="table-row" v-for="(team, index) in standings" :key="team.id">
        <td class="first-column text-center">{{ index + 1 }}</td>
        <td class="wide d-flex align-items-center">
          <div :style="`background-image: url(${team.teamLogo})`" class="thumbnail-image"></div>
          <div class="ms-3">{{ team.team }}</div>
        </td>
        <td>{{ team.matches }}</td>
        <td>{{ team.wins }}</td>
        <td>{{ team.draws }}</td>
        <td>{{ team.losses }}</td>
        <td>{{ team.goalDifference }}</td>
        <td class="last-column">{{ team.points }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'TableComponent',
  props: {
    standings: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ensureEightCells(row) {
      // Pad row with empty strings if it has fewer than 8 cells
      return Array.from({length: 8}, (_, index) => ({
        value: row[index] || "",
        index,
      }));
    },
  },
}
</script>
<style lang="scss" scoped>
.table-container {
  overflow-x: auto;
  font-family: $font-regular;
  background-color: $color-white;
  padding: 20px;
  color: $color-green-dark;
  border-radius: 30px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 20px 10px;
  text-align: left;
  white-space: nowrap;
}

.thumbnail-image {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* Wide class to set the second column wider */
th.wide, td.wide {
  width: 400px; /* Adjust as needed */
}

th.first-column {
  width: 150px;
}


tr.table-row td.first-column {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

tr.table-row td.last-column {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

tbody tr:nth-child(odd) {
  background-color: $color-grey-200;

}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  th.first-column {
    width: 50px;
  }

  th, td {
    font-size: 13px;
    padding: 10px;
  }
  th.wide, td.wide {
    width: 190px; /* Adjust as needed */
  }
  .thumbnail-image {
    width: 30px;
    height: 30px;
  }

}
</style>
