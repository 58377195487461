<script setup>

</script>

<template>
  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.05412 1.16451C5.4163 0.68916 5.90446 0.293036 6.5501 0.118741C8.09332 -0.293228 9.6208 0.736695 9.93575 2.38457C10.1247 3.36696 9.8885 4.25428 9.33735 5.06237C8.59723 6.15568 7.55792 6.94793 6.59734 7.80356C6.23516 8.12046 5.87297 8.43736 5.49504 8.75425C5.11711 9.07115 4.8809 9.087 4.51871 8.7701C3.51089 7.88278 2.48732 6.99546 1.511 6.07645C0.723638 5.33174 0.109498 4.47611 0.0150148 3.35112C-0.0794683 2.21028 0.26697 1.22789 1.19605 0.546556C2.01491 -0.0555536 2.92825 -0.182313 3.85733 0.277191C4.2825 0.499021 4.62894 0.84761 5.05412 1.16451Z"
        fill="#00A651"/>
  </svg>

</template>

<style scoped lang="scss">

</style>