<script>
export default {
  name: "FacebookIcon"
}
</script>

<template>
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_165_2599)">
      <path d="M2.23454 9.03591C1.89607 9.03591 1.5832 9.03591 1.26984 9.03591C1.00429 9.03591 0.738732 9.03643 0.473175 9.03591C0.0936716 9.03486 0.00193404 8.93722 0.000968385 8.52944C2.72595e-06 7.77341 2.72595e-06 7.01789 0.000968385 6.26186C0.00145121 5.85982 0.0917403 5.7627 0.457725 5.76218C0.976767 5.76218 1.49581 5.76218 2.01485 5.76218H2.23454C2.23454 5.67655 2.23454 5.60711 2.23454 5.53819C2.23695 4.89963 2.21184 4.25951 2.24709 3.62304C2.32483 2.23001 2.82938 1.09386 4.0297 0.425543C4.56902 0.125321 5.15228 0.00209974 5.75774 0.00105549C6.36756 0.000533369 6.97689 -0.00103301 7.5867 0.00105549C7.9102 0.00262187 7.99807 0.0966044 7.99855 0.43964C8.00049 1.16957 8 1.89898 7.99855 2.62891C7.99759 2.98291 7.90682 3.08473 7.58187 3.08942C7.13525 3.09621 6.68864 3.09412 6.24202 3.10091C6.1218 3.10248 6.00061 3.11344 5.8828 3.13642C5.60903 3.18967 5.40335 3.39017 5.39514 3.68412C5.37631 4.35349 5.38934 5.02338 5.38934 5.72146C5.44873 5.72146 5.51198 5.72146 5.57523 5.72146C6.21498 5.72146 6.85473 5.72041 7.49448 5.72198C7.82377 5.72302 7.9242 5.83267 7.92468 6.19189C7.92613 6.98657 7.92613 7.78176 7.92468 8.57644C7.9242 8.91373 7.8455 9.00249 7.52683 9.0051C6.8808 9.0098 6.23526 9.00719 5.58924 9.00771C5.52454 9.00771 5.45984 9.00771 5.37245 9.00771C5.37245 9.11318 5.37245 9.19568 5.37245 9.2787C5.37245 11.3051 5.37245 13.3314 5.37245 15.3578C5.37245 15.9353 5.31354 15.999 4.78532 15.999C4.08522 15.999 3.38512 15.9995 2.68502 15.999C2.32869 15.999 2.2355 15.9003 2.23502 15.5134C2.23405 13.4416 2.23454 11.3693 2.23454 9.29749C2.23454 9.22022 2.23454 9.14294 2.23454 9.03591Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_165_2599">
        <rect width="8" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped lang="scss">

</style>