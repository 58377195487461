<template>
  <svg class="black icon" :width="width" :height="height"
       :viewBox="`0 0 ${parseFloat(width)} ${parseFloat(height)}`" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.04">
      <rect x="231.99" y="4" width="202" height="302" rx="12" transform="rotate(90 231.99 4)" stroke="black"
            stroke-width="8"/>
      <path d="M227.99 35H172.99C164.154 35 156.99 42.1634 156.99 51V160C156.99 168.837 164.154 176 172.99 176H227.99"
            stroke="black" stroke-width="8"/>
      <circle cx="-4.00977" cy="106" r="47" stroke="black" stroke-width="8"/>
      <path d="M152.99 136C136.422 136 122.99 122.569 122.99 106C122.99 89.4315 136.422 76 152.99 76" stroke="black"
            stroke-width="8"/>
      <circle cx="54.9902" cy="40" r="8" stroke="black" stroke-width="8"/>
      <circle cx="101.99" cy="164" r="8" stroke="black" stroke-width="8"/>
      <path d="M75.9902 40C112.99 51 75.4902 106.5 139.99 105" stroke="black" stroke-width="8" stroke-linecap="round"
            stroke-dasharray="16 16"/>
      <path d="M122.99 164C148.99 164.5 180.99 145.5 191.49 123" stroke="black" stroke-width="8" stroke-linecap="round"
            stroke-dasharray="16 16"/>
    </g>
  </svg>

  <svg class="white icon" :width="width" :height="height"
       :viewBox="`0 0 ${parseFloat(width)} ${parseFloat(height)}`" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <rect x="231.99" y="4" width="202" height="302" rx="12" transform="rotate(90 231.99 4)" stroke="white"
            stroke-width="8"/>
      <path d="M227.99 35H172.99C164.154 35 156.99 42.1634 156.99 51V160C156.99 168.837 164.154 176 172.99 176H227.99"
            stroke="white" stroke-width="8"/>
      <circle cx="-4.00977" cy="106" r="47" stroke="white" stroke-width="8"/>
      <path d="M152.99 136C136.422 136 122.99 122.569 122.99 106C122.99 89.4315 136.422 76 152.99 76" stroke="white"
            stroke-width="8"/>
      <circle cx="54.9902" cy="40" r="8" stroke="white" stroke-width="8"/>
      <circle cx="101.99" cy="164" r="8" stroke="white" stroke-width="8"/>
      <path d="M75.9902 40C112.99 51 75.4902 106.5 139.99 105" stroke="white" stroke-width="8" stroke-linecap="round"
            stroke-dasharray="16 16"/>
      <path d="M122.99 164C148.99 164.5 180.99 145.5 191.49 123" stroke="white" stroke-width="8" stroke-linecap="round"
            stroke-dasharray="16 16"/>
    </g>
  </svg>


</template>

<script>
export default {
  name: 'PitchIcon',
  props: {
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    stroke: {
      type: String,
      default: '#000000',
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.icon {
  transition: fill 0.3s, stroke 0.3s ease-in-out;
  width: 100%;
  height: auto;
}

.icon-path {
  transition: fill 0.3s ease-in-out;
}

.white {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .icon-path {
  stroke: #FFFFFF;
}

.component-wrapper:hover .black {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .white {
  display: block;
  transition: fill 0.3s ease-in-out;
}
</style>
