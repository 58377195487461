<template>
  <div
      class="component-wrapper"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
  >
    <div class="icon-container">
      <div class="icon-container">
        <div class="player-icon">
          <PlayerIcon v-if="icon === 'player'" :width="playerIconWidth" :height="playerIconHeight"/>
        </div>

        <div class="standings-icon">
          <StandingsIcon v-if="icon === 'standings'" :width="standingsIconWidth"
                         :height="standingsIconHeight"/>
        </div>
        <div class="pitch-icon">
          <PitchIcon v-if="icon === 'pitch'" :width="pitchIconWidth" :height="pitchIconHeight"/>
        </div>
        <div class="tshirt-icon">
          <TshirtIcon v-if="icon === 'tshirt'" :width="tshirtIconWidth" :height="tshirtIconHeight"/>
        </div>
      </div>
    </div>
    <div class="component-content-container ">
      <div class="d-flex component-content align-items-center">
        <div class="me-auto">
          <div class="title"> {{ title }}</div>
          <div class="subtitle"> {{ subtitle }}</div>
        </div>
        <div class="card-navigation-button">
          <CircleButton :theme="'solid-green'" :isHovered="isHovered"/>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import CircleButton from "@/components/Common/Buttons/CircleButton.vue";
import PlayerIcon from "@/components/Common/Icons/PlayerIcon.vue";
import StandingsIcon from "@/components/Common/Icons/StandingsIcon.vue";
import PitchIcon from "@/components/Common/Icons/PitchIcon.vue";
import TshirtIcon from "@/components/Common/Icons/TshirtIcon.vue";

export default {
  name: 'IndexHeadingCardNavigation',
  components: {TshirtIcon, PitchIcon, StandingsIcon, PlayerIcon, CircleButton},
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    icon: {
      type: String
    },
  },
  data() {
    return {
      isHovered: false,
      isMobile: window.innerWidth <= 768,
    };
  },
  computed: {
    // Separate width and height for each icon
    playerIconWidth() {
      return this.isMobile ? 400 : 500; // Set mobile and default sizes for PlayerIcon
    },
    playerIconHeight() {
      return this.isMobile ? 305 : 305;
    },
    standingsIconWidth() {
      return this.isMobile ? 446 : 486; // Set mobile and default sizes for StandingsIcon
    },
    standingsIconHeight() {
      return this.isMobile ? 270 : 273;
    },
    pitchIconWidth() {
      return this.isMobile ? 450 : 400; // Set mobile and default sizes for PitchIcon
    },
    pitchIconHeight() {
      return this.isMobile ? 300 : 410;
    },
    tshirtIconWidth() {
      return this.isMobile ? 449 : 409; // Set mobile and default sizes for TshirtIcon
    },
    tshirtIconHeight() {
      return this.isMobile ? 232 : 232;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateDimensions);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  },
  methods: {
    updateDimensions() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
}
</script>

<style scoped lang="scss">
.component-wrapper {
  border-radius: 20px;
  background: $color-white;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  height: 22.2vh;
  overflow: hidden;
  position: relative;
  color: $color-green-dark;

  &:hover {
    background: $color-green-main;

    .component-content {
      color: #FFFFFF;
    }
  }

  .card-navigation-button {
    width: 60px;
    height: 60px;
  }


  .player-icon {
    transform: translateY(-50%);
  }

  .standings-icon {
    transform: translateY(-45%);
  }

  .pitch-icon {
    transform: translateY(-25%);
  }

  .tshirt-icon {
    transform: translateY(-30%);
  }

  .component-content-container {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;

    .component-content {
      padding: 28px 25px;

      .title {
        @include title-h3-bold;

      }

      .subtitle {
        @include body-16-regular;

      }
    }
  }
}

@media screen and (min-width: 768px) {
  .component-wrapper {
    .component-content-container {
      .component-content {
        .title {
          @include title-h4-bold;

        }

        .subtitle {
          @include body-14-regular;

        }
      }
    }

    .player-icon {
      transform: translateY(0%);
    }

    .standings-icon {
      transform: translateY(5%);
    }

    .pitch-icon {
      transform: translateY(5%);
    }

    .tshirt-icon {
      transform: translateY(5%);
    }
  }
}

@media screen and (min-width: 1200px) {
  .component-wrapper {
    height: 46.25vh;
  }
}


</style>
