<template>

  <svg class="black icon" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.04">
      <path
          d="M106.371 71.6567H137.167C139.033 78.0336 138.753 92.7469 122.702 100.586C108.704 93.1202 106.371 82.8551 106.371 72.5899"
          stroke="black" stroke-width="7.46555" stroke-linecap="round"/>
      <path
          d="M-21.0098 101.052H23.7836L24.7167 101.519V227.5H159.563V100.119H204.357V57.6587C204.357 43.4741 190.981 31.2182 184.293 26.8633L124.102 4H61.5779C48.8243 8.8215 19.2109 20.0509 2.78669 26.3967C-13.6375 32.7424 -19.921 48.3267 -21.0098 55.3257V101.052Z"
          stroke="black" stroke-width="7.46555" stroke-linecap="round"/>
      <path
          d="M60.1777 4.4668C61.5775 13.9543 69.6963 32.9292 90.9731 32.9292C112.25 32.9292 121.924 14.2653 124.102 4.4668"
          stroke="black" stroke-width="7.46555" stroke-linecap="round"/>
      <path d="M1.85352 26.8633L24.7168 100.119" stroke="black" stroke-width="7.46555" stroke-linecap="round"/>
      <path d="M181.959 26.8633L159.562 97.3194" stroke="black" stroke-width="7.46555" stroke-linecap="round"/>
    </g>
  </svg>

  <svg class="white icon" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path
          d="M106.371 71.6567H137.167C139.033 78.0336 138.753 92.7469 122.702 100.586C108.704 93.1202 106.371 82.8551 106.371 72.5899"
          stroke="white" stroke-width="7.46555" stroke-linecap="round"/>
      <path
          d="M-21.0098 101.052H23.7836L24.7167 101.519V227.5H159.563V100.119H204.357V57.6587C204.357 43.4741 190.981 31.2182 184.293 26.8633L124.102 4H61.5779C48.8243 8.8215 19.2109 20.0509 2.78669 26.3967C-13.6375 32.7424 -19.921 48.3267 -21.0098 55.3257V101.052Z"
          stroke="white" stroke-width="7.46555" stroke-linecap="round"/>
      <path
          d="M60.1777 4.4668C61.5775 13.9543 69.6963 32.9292 90.9731 32.9292C112.25 32.9292 121.924 14.2653 124.102 4.4668"
          stroke="white" stroke-width="7.46555" stroke-linecap="round"/>
      <path d="M1.85352 26.8633L24.7168 100.119" stroke="white" stroke-width="7.46555" stroke-linecap="round"/>
      <path d="M181.959 26.8633L159.562 97.3194" stroke="white" stroke-width="7.46555" stroke-linecap="round"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'TshirtIcon',
  props: {
    width: {
      type: [String, Number],
      default: '209',
    },
    height: {
      type: [String, Number],
      default: '232',
    },
    stroke: {
      type: String,
      default: '#000000',
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.icon {
  transition: fill 0.3s, stroke 0.3s ease-in-out;
  width: 100%;
  height: auto;
}

.white {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .black {
  display: none;
  transition: fill 0.3s ease-in-out;
}

.component-wrapper:hover .white {
  display: block;
  transition: fill 0.3s ease-in-out;
}
</style>
