export default {
    namespaced: true,
    state: {
        isLoading: false,
    },
    mutations: {
        TOGGLE_PRELOADER(state, value) {
            state.isLoading = value;
        },
    },
    actions: {
        async showPreloader({commit}) {
            commit('TOGGLE_PRELOADER', true);
        },
        async hidePreloader({commit}) {
            commit('TOGGLE_PRELOADER', false);
        },
    }
};
