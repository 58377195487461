<script setup>

</script>

<template>
  <svg width="229" height="491" viewBox="0 0 229 491" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M51.1425 318.108H77.0343V321.855C77.0343 363.168 77.0343 404.39 77.0343 445.703C77.0343 450.639 77.5832 455.575 77.9492 460.51C77.9492 461.333 78.2236 462.43 78.7726 462.978C79.6875 463.892 80.8768 465.172 81.8832 465.172C82.9811 465.172 84.7194 463.984 84.9024 462.978C85.8173 459.231 86.3663 455.483 86.6407 451.644C86.9152 448.537 86.6407 445.338 86.6407 442.139C86.6407 382.545 86.6407 322.952 86.6407 263.359C86.6407 262.353 86.6407 261.348 86.6407 260.799C85.7258 265.918 85.2684 271.402 83.8045 276.703C80.6024 288.494 66.6044 292.333 57.5468 284.198C53.9787 280.908 52.8808 276.338 52.3319 271.859C51.5085 264.73 51.051 257.509 51.051 250.288C50.9595 179.727 50.9595 109.165 51.051 38.6039C51.051 32.0231 51.8745 25.4422 52.9723 18.8613C54.3447 10.0869 59.7426 4.51141 68.4342 2.50059C77.7662 0.306971 87.0982 0.306971 96.3387 2.86619C104.298 5.05982 109.056 10.4525 110.428 18.4043C111.526 24.9852 112.441 31.7489 112.441 38.4211C112.532 176.711 112.532 315 112.441 453.29C112.441 459.87 111.618 466.451 110.52 473.032C109.056 481.807 103.658 487.382 95.0578 489.393C85.7258 491.587 76.3938 491.587 67.1533 489.027C59.3767 486.834 54.5277 481.533 53.1553 473.672C51.9659 467.183 51.1425 460.51 51.1425 453.929C50.9596 409.691 51.1425 365.453 51.051 321.215C51.051 320.301 51.051 319.479 51.051 318.291L51.1425 318.108ZM76.9428 144.172C76.9428 176.985 76.9428 209.889 76.9428 242.702C76.9428 247.729 77.4917 252.756 77.9492 257.783C78.1321 259.977 79.1385 261.896 81.7003 261.988C84.3535 262.079 85.3599 260.068 85.7258 257.966C86.2748 254.401 86.7322 250.745 86.7322 247.089C86.7322 178.63 86.7322 110.079 86.7322 41.6202C86.7322 40.7975 86.8237 40.0663 86.7322 39.2437C86.1833 35.6791 85.8173 32.0231 84.9024 28.5498C84.6279 27.5444 82.8896 26.3562 81.8832 26.3562C80.7854 26.3562 79.596 27.6358 78.7726 28.5498C78.2236 29.0982 78.0407 30.1951 77.9492 31.0177C77.5832 36.9587 77.0343 42.8084 77.0343 48.7494C77.0343 80.5569 77.0343 112.273 77.0343 144.081L76.9428 144.172Z"
        fill="white"/>
    <path
        d="M229 173.42H203.109C203.109 172.049 203.109 170.678 203.109 169.399C203.109 126.715 203.109 84.1217 203.109 41.4375C203.109 37.7815 202.651 34.1255 202.102 30.5608C201.828 28.55 200.913 26.3564 198.26 26.3564C195.515 26.3564 194.691 28.6414 194.417 30.835C193.959 35.3137 193.411 39.8837 193.411 44.4538C193.411 106.424 193.411 168.393 193.411 230.363C193.411 230.637 193.411 230.912 193.411 230.729C194.234 225.793 194.783 220.218 196.155 214.916C199.357 203.126 213.355 199.287 222.413 207.421C225.981 210.712 227.079 215.282 227.628 219.761C228.451 226.89 228.909 234.111 228.909 241.331C229 311.893 229 382.454 228.909 453.016C228.909 459.596 228.085 466.177 226.987 472.758C225.615 481.533 220.217 487.108 211.526 489.21C202.194 491.404 192.862 491.404 183.621 488.845C175.661 486.651 170.904 481.258 169.532 473.307C168.434 466.726 167.519 459.962 167.519 453.29C167.427 315 167.427 176.711 167.519 38.4213C167.519 31.8404 168.342 25.2596 169.44 18.6787C170.812 9.90425 176.302 4.32879 184.902 2.31797C194.234 0.124354 203.566 0.124354 212.806 2.68358C220.583 4.8772 225.432 10.1784 226.804 18.0389C227.994 24.5284 228.817 31.2006 228.817 37.7815C229 82.0195 228.817 126.258 228.909 170.496C228.909 171.41 228.909 172.232 228.909 173.42H229ZM193.319 346.808C193.319 379.803 193.319 412.708 193.319 445.703C193.319 450.731 193.868 455.758 194.325 460.785C194.508 462.887 195.332 464.989 197.894 465.172C200.73 465.355 201.645 463.07 202.011 460.967C202.56 457.586 203.017 454.112 203.017 450.639C203.017 381.632 203.017 312.715 203.017 243.708C203.017 240.417 202.651 237.218 202.194 234.019C201.828 231.734 200.913 229.358 197.985 229.54C195.24 229.632 194.417 231.826 194.234 234.111C193.777 240.234 193.319 246.358 193.319 252.482C193.319 283.924 193.319 315.457 193.319 346.899V346.808Z"
        fill="white"/>
    <path
        d="M45.8366 487.657H20.4023V38.6042C13.4491 41.986 7.13624 45.1851 0.365961 48.4755C0.274471 47.3787 0.0914902 46.5561 0.0914902 45.7335C0 38.33 0 30.9265 0 23.5231C0 21.695 0.548941 20.6896 2.19577 19.867C11.2533 15.1142 20.2193 10.1785 29.3684 5.51708C31.2897 4.51167 33.5769 4.05467 35.7727 3.87187C38.9748 3.59767 42.2685 3.87187 45.8366 3.87187V487.748V487.657Z"
        fill="white"/>
    <path
        d="M162.212 487.657H136.778V38.6043C129.824 41.9861 123.512 45.1851 116.741 48.4756C116.65 47.3788 116.467 46.5562 116.467 45.7336C116.467 38.3301 116.467 30.9266 116.467 23.4317C116.467 21.6037 117.016 20.5983 118.663 19.7757C127.72 15.0229 136.686 10.0872 145.835 5.42577C147.756 4.42036 150.044 3.96336 152.239 3.78056C155.442 3.50636 158.735 3.78056 162.303 3.78056V487.657H162.212Z"
        fill="white"/>
  </svg>
</template>

<style scoped lang="scss">
svg {
  height: 491px; /* Default height */

  @media (max-width: 768px) {
    height: 300px;
  }
}
</style>