<template>
  <svg v-if="$root.$i18n.locale === 'bg'"
       version="1.1"
       id="Layer_1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 1687 563.1"
       style="enable-background:new 0 0 1687 563.1;"
       xml:space="preserve"
       class="icon-svg"
  >
    <g>
      <path
          class="st0"
          d="M287.2,332.8v143.5c0,51.3-37.3,84.8-93.2,84.8H2V2h278.6v162.2l-92.3-28.9V88.7H95.2v97.8L287.2,332.8z
         M194.9,368.2L95.2,289v185.4h99.7V368.2z"
      />
      <path
          class="st0"
          d="M632,176.3h-4.7l-88.5-34.5V91.5h-93.2v148.2h130.5v4.7l-32.6,84.8h-97.9v142.6h93.2v-67.1l88.5-34.5h4.7v191
         H352.4V88.7c0-52.2,39.1-86.7,96.9-86.7H632V176.3z"
      />
      <path
          class="st0"
          d="M987,332.8l-185.4,72.7v155.6h-93.2V2h185.4C949.7,2,987,35.5,987,86.8V332.8z M801.6,305.8l93.2-36.3v-178
         h-93.2V305.8z"
      />
      <path
          class="st0"
          d="M1048.5,88.7c0-52.2,37.3-86.7,93.2-86.7h186.4v472.5c0,52.2-37.3,86.7-93.2,86.7h-186.4V88.7z M1234.9,474.5
         V88.7h-93.2v385.8H1234.9z"
      />
      <path
          class="st0"
          d="M1685,176.3h-4.7l-88.5-34.5V91.5h-93.2v148.2h130.5v4.7l-32.6,84.8h-97.8v142.6h93.2v-67.1l88.5-34.5h4.7v191
         h-279.6V88.7c0-52.2,39.1-86.7,96.9-86.7H1685V176.3z"
      />
    </g>
  </svg>
  <svg v-if="$root.$i18n.locale === 'en'"
       version="1.1"
       id="Layer_1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 1680.1 551.8"
       style="enable-background:new 0 0 1680.1 551.8;"
       xml:space="preserve"
       class="icon-svg"
  >
  <g>
    <path
        class="st0"
        d="M281.4,270.4v196.3c0,50.2-36.5,83.1-91.3,83.1H2V2h183.5C239.4,2,275,35.8,275,86.9v116.9l-53.9,42.9
      L281.4,270.4z M93.3,89.6v106.8l91.3,35.6V89.6H93.3z M191,332.5l-97.7-38.3v168H191V332.5z"
    />
    <path
        class="st0"
        d="M627.4,172.7h-4.6l-86.7-33.8V89.6h-91.3v145.2h127.8v4.6l-32,83.1h-95.9v139.7h91.3v-65.7l86.7-33.8h4.6
      v187.2H353.5V86.9c0-51.1,38.3-84.9,94.9-84.9h178.9V172.7z"
    />
    <path
        class="st0"
        d="M835.5,380.9l-42,16.4v152.5h-91.3V2h181.7c54.8,0,91.3,32.9,91.3,83.1v241L920.4,348l75.8,200.8h-97.7
      L835.5,380.9z M793.5,299.6l91.3-35.6V89.6h-91.3V299.6z"
    />
    <path
        class="st0"
        d="M1055.5,86.9c0-51.1,36.5-84.9,91.3-84.9h182.6v462.9c0,51.1-36.5,84.9-91.3,84.9h-182.6V86.9z M1238.1,463
      V89.6h-91.3V463H1238.1z"
    />
    <path
        class="st0"
        d="M1678.1,172.7h-4.6l-86.7-33.8V89.6h-91.3v145.2h127.8v4.6l-32,83.1h-95.9v139.7h91.3v-65.7l86.7-33.8h4.6
      v187.2h-273.9V86.9c0-51.1,38.3-84.9,94.9-84.9h178.9V172.7z"
    />
  </g>
</svg>

</template>

<script>
export default {
  name: 'IndexBeroeFontOutline',
};
</script>

<style scoped>
.icon-svg {
  max-width: 1900px; /* Ensures the SVG scales to the container width */
  height: auto; /* Maintains the aspect ratio */
}

/* Apply styles for mobile devices */
@media (max-width: 768px) {
  .icon-svg {
    max-width: 350px; /* Set the maximum width for mobile devices */
  }
}

/* Move SVG styles here */
.st0 {
  fill: none;
  stroke: #06a64f;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
</style>
