<template>
  <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.782" cy="11.782" r="10.8002" stroke="#00A651" stroke-width="1.96366"/>
    <line x1="19.8396" y1="18.4515" x2="27.6943" y2="26.3061" stroke="#00A651" stroke-width="1.96366"/>
  </svg>


</template>
<style></style>
<script>
export default {
  name: "SearchIconGreen",
}
</script>
