<template>
  <div
      :class="[
      'main-button-wrapper',
      `button-theme-${theme}`,
      `button-size-${size}`,
      { 'hovered': isHovered },
    ]"
  >
    <div class="arrow-icon d-flex justify-content-center align-items-center h-100">
      <ArrowIcon v-if="theme === 'solid-green'" :width="16" :height="16" :stroke="iconStroke"/>
      <ArrowIcon v-if="theme === 'solid-white'" :width="16" :height="16" :stroke="'#0E4127'"/>
    </div>
  </div>
</template>

<script>
import ArrowIcon from "@/components/Common/Icons/ArrowIcon.vue";

export default {
  name: "CircleButton",
  components: {ArrowIcon},
  props: {
    theme: {
      type: String,
      default: 'solid-green',
    },
    size: {
      type: String,
      default: 'medium',
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconStroke() {
      return this.isHovered ? '#0E4127' : '#FFFFFF'; // Change stroke color on hover
    }
  },
};
</script>

<style lang="scss" scoped>
.main-button-wrapper {
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 10px;
  text-align: center;
  overflow: hidden;

  position: relative;

  .arrow-icon {
    transition: transform 0.5s ease, stroke 0.3s ease-in-out;
  }

  &:hover {
    .arrow-icon {
      transform: rotate(45deg);
    }
  }

  &.hovered {
    background: $color-green-light;
    box-shadow: 0 0 54px rgba(0, 255, 124, 0.74); /* Box shadow on hover */

    .arrow-icon {
      transform: rotate(45deg);
    }
  }
}

.button-size-medium {
  width: 60px;
  height: 60px;
}

.button-size-large {
  width: 100px;
  height: 100px;
}

.button-theme-solid-white {
  background: $color-white;

  &:hover {
    background: $color-green-light;
    box-shadow: 0 0 54px rgba(0, 255, 124, 0.74); /* Box shadow on hover */
    .arrow-icon {
      transform: rotate(45deg);
    }
  }
}

.button-theme-solid-green {
  background: $color-green-main;

  &:hover {
    background: $color-green-light;
    box-shadow: 0 0 54px rgba(0, 255, 124, 0.74); /* Box shadow on hover */
  }
}

@media screen and (min-width: 768px) {
  .button-size-medium {
    width: 80px;
    height: 80px;
  }
}
</style>
