<script>

import OneNewsPostThumbnail from "@/components/Components/OneNewsPostThumbnail/OneNewsPostThumbnail.vue";
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide,} from 'vue3-carousel'


export default {
  components: {
    OneNewsPostThumbnail,
    Carousel,
    Slide,
  },
  props: {
    newsData: {
      type: Array
    },
    newsTheme: {
      type: String
    }

  },
  data() {
    return {

      settings: {
        itemsToShow: 1.2,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
    };

  }
};
</script>

<template>
  <div class="index-specialties-wrapper ">
    <div class="">
      <div class="row d-none d-lg-flex">
        <div v-for="(news, index) in newsData" :key="index" class="col-md-3 service-thumbnails-container">
          <OneNewsPostThumbnail
              :id="news.id"
              :img-src="news.content.gallery[0]"
              :date="news.content.date"
              :title="news.content.title"
              :content="news.content.content"
              :category="news.hasOwnProperty('category') && news.content.category !== null ? news.content.category.name : ''"
          />
        </div>
      </div>
    </div>

    <div class="d-lg-none services-mobile-carousel">
      <Carousel :breakpoints="breakpoints" v-bind="settings">

        <slide v-for="(news, index) in newsdata" :key="index">
          <div class="carousel_item">
            <OneNewsPostThumbnail
                :id="news.id"
                :img-src="news.content.gallery[0]"
                :date="news.content.date"
                :title="news.content.title"
                :content="news.content.content"
                :category="news.hasOwnProperty('category') && news.content.category !== null ? news.content.category.name : ''"
            />
          </div>
        </slide>

      </carousel>
    </div>
  </div>


</template>

<style lang="scss" scoped>
.index-specialties-wrapper {


  .carousel_item {
    margin: 0 5px;
  }

  .index-specialties-heading {
    padding: $spacing-3 0;

    .specialties-title {
      font-size: $title-h4;

      line-height: $title-h4;

    }

    .specialties-description {
      margin-top: $spacing-2;
      font-size: $paragraph-2;
      line-height: $paragraph-2;
      width: 80%;
    }

    .specialties-subtitle {
      font-size: $subtitle-1;
      width: 50%;
      line-height: 20px;
      text-align: right;

    }
  }
}


@media screen and (min-width: 768px) {
  .index-specialties-wrapper {

    overflow: hidden;

    .index-specialties-heading {
      padding: $spacing-8 0;

      .specialties-title {
        font-size: $title-h2;
        width: 30%;
        line-height: 40px;
      }

      .specialties-description {
        font-size: $paragraph-2;
        width: 22%;
      }
    }
  }
}
</style>