<template>

  <svg
      :width="width"
      :height="height"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-svg"
  >
    <g clip-path="url(#clip0_197_204)">
      <circle cx="12.5" cy="12.5" r="11.75" class="icon-path" stroke-width="1.5"/>
      <path
          d="M8.5625 12.25H15.5625C16.0457 12.25 16.4375 12.6418 16.4375 13.125V19.125C16.4375 19.6082 16.0457 20 15.5625 20H9.4375C8.95425 20 8.5625 19.6082 8.5625 19.125V6.875C8.5625 6.39175 8.95425 6 9.4375 6H15.5625C16.0457 6 16.4375 6.39175 16.4375 6.875V7"
          class="icon-path" stroke-width="1.5"/>
    </g>
    <defs>
      <clipPath id="clip0_197_204">
        <rect width="25" height="25" class="icon-path"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HistoryIcon",
  props: {
    primaryColor: {
      type: String,
      default: '#0E4127', // Default primary color
    },
    hoverColor: {
      type: String,
      default: '#FFFFFF', // Default hover color
    },
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>

<style scoped lang="scss">
.icon-svg {
  transition: 0.3s ease-in-out;
}

.icon-path {
  stroke: var(--primary-color, #0E4127); /* Default fill color */
  transition: 0.3s ease-in-out;
}

.menu-item:hover .icon-svg .icon-path {
  stroke: var(--hover-color, #FFFFFF); /* Fill color on parent hover */
  transition: 0.3s ease-in-out;
}
</style>
