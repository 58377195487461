<template>
  <div class="component-wrapper">
    <div class="numbers">{{ numbers }}</div>
    <div v-html="content"></div>
    <div class="images-container">
      <div class="row">
        <div class="col-md-6 g-3" v-for="(image, index) in imageUrl" :key="index">
          <div class="image-box"
               :style="{ backgroundImage: 'url(/storage/' + image + ')' }"
          >
          </div>
        </div>

      </div>
    </div>
    <div class="video-container mt-4" v-if="videoUrl">
      <iframe
          :src="videoUrl"
          :width="width"
          :height="height"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryContentComponent",
  props: {
    numbers: {
      type: String
    },
    content: {
      type: String
    },
    imageUrl: {
      type: Array, // Change to Array since it holds multiple image URLs
      default: () => []
    },
    videoUrl: {
      type: String
    },
    width: {
      type: String,
      default: "100%" // default width for the iframe
    },
    height: {
      type: String,
      default: "500" // default height for the iframe
    }
  }
};
</script>

<style lang="scss" scoped>
.component-wrapper {
}

.numbers {
  font-family: Beroe;
  font-size: 150px;
  line-height: 120px;
  color: transparent; /* Text color */
  -webkit-text-stroke: 1px $color-green-main; /* Outline width and color */
  text-fill-color: white; /* Text fill color */
  margin-bottom: 25px;
}

.images-container {

  .image-box {
    height: 300px;
    background-size: cover;
    background-position: center;

  }
}

@media screen and (min-width: 768px) {
  .images-container, .video-container {
    max-width: 80%;
  }
  .images-container .image-box {
    height: 400px; /* Adjust height for larger screens */

  }
  .numbers {
    font-size: 250px;
    line-height: 250px;
    -webkit-text-stroke: 4px $color-green-main; /* Outline width and color */

  }
}
</style>
