import { createI18n } from "vue-i18n";
import { get } from 'lodash';
import en_translation from "./en";
import bg_translation from "./bg";
import axios from "axios";

// List of all locales.
export const allLocales = ["bg", "en",  "de"];
const loadedLanguages = ["bg"]; // our default language that is preloaded

let loadedLocale = window.localStorage.getItem('defaultLang') ?? 'bg';

const translations = {
  bg: bg_translation,
  en: en_translation
}

// Create Vue I18n instance.
export const i18n = createI18n({
  // legacy: false,
  globalInjection: true,
  // allowComposition: true,
  locale: loadedLocale,
  messages: translations,
  fallbackLocale: "bg",
  // useScope: "global",
  messageResolver: (obj, path) => get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null,
});

// Set new locale.
export const setLang =  async (lang) => {
  axios.defaults.headers.common["X-Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  window.localStorage.setItem('defaultLang', lang);
}

// Fetch locale.
export function loadLocale(lang) {

  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setLang(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setLang(lang));
  }
}