<template>
  <div class="burger" @click="toggleMenu">
    <span :class="{ active: isActive }"></span>
    <span :class="{ active: isActive }"></span>
    <span :class="{ active: isActive }"></span>
  </div>
</template>

<script>
export default {
  name: "HamburgerIcon",
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
      this.$emit('toggle', this.isActive); // Emit an event to the parent component
    },
    closeMenu() {
      this.isActive = false;
    }
  }
};
</script>

<style scoped lang="scss">
.burger {
  position: relative;
  width: 37px;
  height: 25px;
  background: transparent;
  cursor: pointer;
  display: block;
}

.burger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $color-green-main;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

/* Toggle classes for active state */
.burger span.active:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger span.active:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger span.active:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 26px;
  left: 5px;
}
</style>
