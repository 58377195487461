<script>
export default {
  name: 'ArrowRightIcon',
  props: {
    width: {
      type: [String, Number],
      default: '27',
    },
    height: {
      type: [String, Number],
      default: '25',
    },
    stroke: {
      type: String,
      default: '#0E4127',
    },
  },
}
</script>

<template>
  <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1 6.76416C0.447715 6.76416 4.82823e-08 7.21188 0 7.76416C-4.82823e-08 8.31644 0.447715 8.76416 1 8.76416L1 6.76416ZM26.7071 8.47127C27.0976 8.08074 27.0976 7.44758 26.7071 7.05706L20.3431 0.693094C19.9526 0.30257 19.3195 0.30257 18.9289 0.693094C18.5384 1.08362 18.5384 1.71678 18.9289 2.10731L24.5858 7.76416L18.9289 13.421C18.5384 13.8115 18.5384 14.4447 18.9289 14.8352C19.3195 15.2258 19.9526 15.2258 20.3431 14.8352L26.7071 8.47127ZM1 8.76416L26 8.76416L26 6.76416L1 6.76416L1 8.76416Z"
        :fill="stroke"/>
  </svg>

</template>

<style scoped lang="scss">

</style>