import axios from "axios";

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async getPlayer({state}, id) {
            if (!state) {
                return;
            }
            const response = await axios.get('/api/page/' + id);
            return response.data;
        },

        async getPlayers({state}) {
            if (!state) {
                return;
            }
            const response = await axios.get('/api/pages/player?limit=100');
            return response.data;
        },
    }
};
