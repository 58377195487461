<template>
  <svg
      :width="width"
      :height="height"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-svg"
  >
    <g clip-path="url(#clip0)">
      <path
          d="M20.3179 17.4735H13.4694C13.4694 18.0658 13.4545 18.6142 13.4725 19.1605C13.5107 20.3023 13.1124 21.2446 12.2662 22.0385C11.2218 23.0183 10.222 24.0431 9.25393 24.9989C8.12169 23.8864 7.0064 22.7916 5.87522 21.6802C6.66641 20.9009 7.52538 20.0547 8.38224 19.2086C8.6555 18.9391 8.8631 17.9226 8.67881 17.6166C8.62479 17.5257 8.45532 17.4745 8.3314 17.4567C6.7003 17.2227 5.61255 16.059 5.52782 14.443C5.51828 14.2529 5.52358 14.0617 5.5204 13.8705C5.50557 12.8614 5.65174 13.0098 4.61165 13.0025C3.11612 12.992 1.62059 12.9931 0.125063 12.9868C0.0932883 12.9868 0.0615136 12.9648 -0.000976562 12.9419V8.38312H13.4588V12.7685H20.3157V17.4735H20.3179ZM18.727 15.9243V14.3072H11.8647V9.9323H1.57505V11.4752H7.08689C7.08689 12.3652 7.08478 13.179 7.08689 13.9917C7.09113 15.3518 7.66413 15.9222 9.04104 15.9232C12.0851 15.9253 15.1291 15.9232 18.1731 15.9232C18.3468 15.9232 18.5215 15.9232 18.726 15.9232L18.727 15.9243ZM10.2834 17.5038C10.2834 17.86 10.2485 18.1734 10.2898 18.4773C10.3915 19.2263 10.1532 19.7957 9.54837 20.2793C8.99867 20.7191 8.52311 21.2487 8.07191 21.6843C8.48286 22.1064 8.83026 22.4626 9.18508 22.8261C9.94132 22.0897 10.7283 21.3511 11.476 20.576C11.6688 20.3765 11.8266 20.0652 11.8436 19.7946C11.8933 19.0435 11.8605 18.2872 11.8605 17.5027H10.2834V17.5038Z"
          fill="var(--primary-color, #0E4127)"
          class="icon-path"
      />
      <path
          d="M23.5937 5.64722C25.4917 5.65871 27.0042 7.1588 27 9.0245C26.9957 10.8777 25.4547 12.3913 23.5715 12.3934C21.6671 12.3955 20.126 10.861 20.1387 8.97645C20.1515 7.12119 21.6925 5.63573 23.5937 5.64722ZM23.5725 7.19849C22.5504 7.1964 21.7285 7.99659 21.7211 8.99838C21.7148 9.99391 22.5293 10.8192 23.5408 10.8421C24.5554 10.8651 25.4229 10.0159 25.4186 9.00361C25.4144 7.99972 24.5957 7.19954 23.5725 7.19849Z"
          fill="var(--primary-color, #0E4127)"
          class="icon-path"
      />
      <path
          d="M12.872 3.38671C12.8678 5.26912 11.3712 6.74414 9.46366 6.74414C7.56883 6.74414 6.04471 5.25868 6.02458 3.39402C6.00552 1.53876 7.59002 -0.0177302 9.48061 2.84067e-05C11.3818 0.0177871 12.8752 1.50951 12.872 3.38671ZM11.2939 3.37522C11.2918 2.37342 10.4741 1.55548 9.46896 1.54921C8.45641 1.54294 7.57837 2.41625 7.60061 3.40656C7.62285 4.39791 8.46488 5.20018 9.47425 5.19287C10.5048 5.18555 11.2971 4.39477 11.2949 3.37626L11.2939 3.37522Z"
          fill="var(--primary-color, #0E4127)"
          class="icon-path"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="27" height="25" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AcademyIcon',
  props: {
    width: {
      type: [String, Number],
      default: '27',
    },
    height: {
      type: [String, Number],
      default: '25',
    },
  },
}
</script>

<style scoped lang="scss">
.icon-svg {
  transition: fill 0.3s, stroke 0.3s ease-in-out;
}

.icon-path {
  transition: fill 0.3s ease-in-out;
}

.menu-item:hover .icon-path {
  fill: var(--hover-color, #FFFFFF);

}
</style>
