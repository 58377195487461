<template>
  <div class="page-wrapper container-custom text-center">
    <div class="page-title"> {{ $t('gdpr') }}</div>
    <div class="d-flex justify-content-center paragraph-text">
      <div class="text-start" v-if="$root.$i18n.locale === 'en'">
        <p>Notice regarding processing and protection of personal data<br/>Pursuant to Regulation (EU) 2016/679 of the
          European Parliament and of the Council of<br/>27.04.2016 on the protection of natural persons in connection
          with the processing of personal<br/>data and on the free movement of such data (GDPR) &amp;quot;Professional
          Football Club Beroe - Stara<br/>Zagora&amp;quot; EAD is the Administrator of personal data as a legal entity,
          which only determines the<br/>purposes and means for the processing of personal data.<br/>PFC Beroe processes
          and protects the personal data of the persons with whom it enters into a<br/>contractual or other type of
          relationship, observing the statutory obligations in this area, in<br/>connection with which we provide you
          with the following information regarding the processing<br/>and storage of personal data by our football club:<br/>1.
          Administrator identification and contact details<br/>&amp;quot;Professional Football Club Beroe - Stara Zagora&amp;quot;
          EAD is a legal entity entered in the Commercial<br/>Register at the Registration Agency with EIC 200106240,
          with headquarters and management<br/>address in the city of Stara Zagora, 10 &amp;quot;Georgi Kyumyurev&amp;quot;
          St., tel./fax : +359 42 621262<br/>e-mail: office@beroeclub.com<br/>2. What personal data do we process?<br/>The
          main types of personal data that PFC Beroe processes are the following:<br/>&bull; Basic information about the
          identity of the natural persons with whom PFC Beroe is in<br/>contractual relations, such as the three names,
          permanent/current address, identification<br/>numbers, e-mail address, contact details, etc.;<br/>&bull;
          Health and biometric data of the competitors from the representative men&amp;#39;s team and from<br/>the
          Junior High School;<br/>&bull; Photo and video images of the competitors, persons from the management and
          officials of PFC<br/>Beroe;<br/>&bull; Bank information in connection with payments made;<br/>3. For what
          purposes are personal data processed?<br/>&bull; PFC Beroe processes personal data for the purpose of
          concluding and executing a specific<br/>contract.</p>
        <p>&bull; The personal data of the persons from the PFC Beroe Junior High School are processed for the<br/>purposes
          of their training.<br/>&bull; The personal data of football players from PMO and athletes from Academy are
          processed in<br/>connection with the mandatory rules and requirements of the Bulgarian football union.<br/>4.
          On what basis are personal data processed?<br/>PFC Beroe processes data of natural persons with whom:<br/>&bull;
          There is an employment contract concluded or who are applying for work, as well as data of<br/>natural persons
          on the occasion of other types of contracts concluded with them;<br/>&bull; On the basis of legal obligations
          arising from a normative, sub-normative act or according to<br/>the rules, regulations and instructions of the
          BFS, UEFA, etc., which apply to the Administrator<br/>and are of a mandatory nature;<br/>&bull; On the basis
          of the legitimate interest of the employer in connection with the fulfillment of<br/>assumed obligations and
          granted rights;<br/>&bull; Based on the subject&amp;#39;s express written consent.<br/>5. Who are the
          recipients with whom we share personal data?<br/>PFC Beroe shares the personal data it processes with the
          following recipients:<br/>&bull; bodies and administrative structures, when required by legislation;<br/>&bull;
          Bulgarian Football Union, UEFA and other sports organizations, when this is mandatory and<br/>required
          according to their rules and instructions for work;<br/>&bull; with organizers of tournaments and sports
          competitions and in other cases when this is<br/>required and necessary in view of the main activity of the
          football club;<br/>&bull; with other persons exceptionally and only within the scope of the objective
          necessity of<br/>sharing, strictly protecting the rights of the subjects whose data it processes.<br/>6.
          Period of processing of personal data<br/>PFC Beroe complies with the statutory terms for storage and
          processing of personal data. When<br/>the processing term is not explicitly defined, in fulfillment of its
          obligation under the GDPR, PFC<br/>Beroe determines an appropriate storage term, guided by the terms for
          making possible legal<br/>claims by or against the Club, as well as by the need to store the data.<br/>7.
          Storage and processing of personal data<br/>PFC Beroe stores personal data on paper in specially designated
          premises that are locked and to<br/>which only designated responsible employees have access.</p>
        <p>Personal data that is stored on a computer is protected by an individual access password of the<br/>responsible
          employees and is processed by means of a program that guarantees its level of<br/>protection.<br/>8. Rights of
          subjects in relation to the protection of their personal data:<br/>&bull; The data subject has the right to
          obtain from the Administrator information whether his<br/>personal data is being processed and, if so, he has
          the right to obtain access to data and<br/>information contained therein.<br/>&bull; The data subject has the
          right to request that inaccurate personal data relating to him be<br/>corrected. Incomplete data can be
          completed.<br/>&bull; The data subject has the right to request the erasure of personal data relating to him.
          The<br/>administrator has an obligation to implement it in the following cases:<br/>&bull; the personal data
          are no longer necessary for the purposes for which they were collected and<br/>processed;<br/>&bull; the data
          subject withdraws his consent to processing and there is no other legal basis for the<br/>processing of the
          data;<br/>&bull; the data subject objects to their processing and there are no overriding legal grounds
          for<br/>processing;<br/>&bull; personal data were processed illegally;<br/>&bull; personal data must be
          deleted in order to comply with a legal obligation that applies to the<br/>Administrator.<br/>&bull; The data
          subject has the right to portability of personal data between individual<br/>administrators;<br/>&bull; The
          subject of personal data has the right to request the Administrator to limit the processing<br/>(use) of his
          personal data in the following cases:<br/>&bull; when the subject disputes the accuracy of his personal data,
          for the period until the<br/>administrator verifies their accuracy;<br/>&bull; when the processing of personal
          data is illegal, but the subject has requested not to delete it,<br/>but to limit it;<br/>&bull; when the
          controller no longer needs the data, but the data subject needs it and requires it for<br/>the establishment,
          exercise or defense of his legal claims;<br/>&bull; when the data subject has objected to their processing
          according to the procedure in the<br/>Regulation and is awaiting verification.</p>
        <p>9. Conditions under which you may object to processing<br/>The data subject has the right to object to the
          processing of personal data concerning him.<br/>The administrator shall terminate the processing of personal
          data unless he proves that there<br/>are compelling legal grounds for the processing that take precedence over
          the interests, rights<br/>and freedoms of the data subject or for the establishment, exercise or defense of
          legal claims.<br/>10. In the event of a violation of his rights and the security of his personal data, the
          data subject<br/>has the right to appeal to the supervisory body for personal data protection - the Commission<br/>for
          Personal Data Protection.</p>
      </div>
      <div class="text-start" v-if="$root.$i18n.locale === 'bg'">
        <p><span style="font-weight: 400;">Уведомление относно обработване и защита на лични данни</span></p>
        <p><span style="font-weight: 400;">По силата на Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 27.04.2016г. относно защитата на физически лица във връзка с обработването на лични данни и относно свободното движение на такива данни (GDPR) &bdquo;Професионален футболен клуб Берое &ndash; Стара Загора&ldquo; ЕАД е Администратор на лични данни като юридическо лице, което само определя целите и средствата за обработването на личните данни.</span><span
            style="font-weight: 400;"><br/></span><span style="font-weight: 400;">ПФК Берое обработва и защитава личните данни на лицата, с които влиза в договорни или друг вид отношения, като спазва нормативните задължения в тази област, във връзка с коeто Ви предоставяме следната информация относно обработването и съхранението на лични данни от страна на нашия футболен клуб:</span>
        </p>
        <ol>
          <li><span style="font-weight: 400;"> Идентификация на администратора и координати за връзка</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bdquo;Професионален футболен клуб Берое &ndash; Стара Загора&ldquo; ЕАД е юридическо лице, вписано в Търговски регистър при Агенция по вписванията с ЕИК 200106240, със седалище и адрес на управление гр. Стара Загора, ул. &bdquo;Георги Кюмюрев&ldquo; № 10, тел./факс: +359 42 621262</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">е-mail: office@beroe.bg.</span></li>
          <li><span style="font-weight: 400;"> Какви лични данни обработваме?</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">Основните видове лични данни, които ПФK Берое обработва, са следните:</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Основна информация за самоличността на физическите лица, с които ПФК Берое се намира в договорни отношения, като трите имена, постоянен/настоящ адрес, идентификационни номера, адрес на електронна поща, данни за контакт и т.н;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Здравни и биометрични данни на състезателите от представителния мъжки отбор и от ДЮШ;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Фото и видео изображения на състезателите, лица от ръководството и служебни лица на ПФК Берое;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Банкова информация във връзка с извършвани плащания;</span>
          </li>
          <li><span style="font-weight: 400;"> За какви цели се обработват личните данни?</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; ПФК Берое обработва лични данни с цел сключването и изпълнението на конкретен договор.</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Обработват се лични данни на лицата от ДЮШ на ПФК Берое за целите на обучението им.</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Личните данни на футболистите от ПМО и състезателите от ДЮШ се обработват във връзка със задължителните правилата и изискванията на БФС.</span>
          </li>
          <li><span style="font-weight: 400;"> На какво основание се обработват личните данни?</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">ПФК Берое обработва данни на физически лица, с които:</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Има сключен трудов договор или които кандидатстват за работа, както и данни на физически лица по повод сключени друг вид договори с тях;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Въз основа на правни задължения, произтичащи от нормативен, поднормативен акт или съгласно правилата, правилниците и указанията на БФС, УЕФА и др., които се прилагат за Администратора и са със задължителен характер;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Въз основа на легитимния интерес на работодателя във връзка с изпълнението на поети задължения и предоставени права;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Въз основа на изрично писмено съгласие на субекта.</span>
          </li>
          <li><span style="font-weight: 400;"> Кои са получателите, с които споделяме лични данни?</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">ПФК Берое споделя лични данни, които обработва, със следните получатели:</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; органи и административни структури, когато това се изисква от законодателството;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Български футболен съюз, УЕФА и други спортни организации, когато това е задължително и изискуемо съобразно правилата и указанията им за работа;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; с организатори на турнири и спортни състезания и в други случаи, когато това е изискуемо и необходимо с оглед основната дейност на футболния клуб;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; с други лица по изключение и единствено в рамките на обективната необходимост от споделяне, като защитава стриктно правата на субектите, чиито данни обработва.</span>
          </li>
          <li><span style="font-weight: 400;"> Срок на обработване на личните данни</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">ПФК Берое спазва нормативно определените срокове за съхранение и обработване на лични данни. Когато срокът за обработване не е изрично определен, в изпълнение на задължението си по GDPR, ПФК Берое определя подходящ срок за съхранение, като се ръководи от сроковете за предявяване на евентуални правни претенции от страна на или спрямо Клуба, както и от необходимостта от съхраняване на данните.</span>
          </li>
          <li><span style="font-weight: 400;"> Съхраняване и обработване на личните данни</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">ПФК Берое съхранява личните данни на хартиен носител в специално обособени за целта помещения, които се заключват и до които имат достъп само определените отговорни служители.</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">Личните данни, които се съхраняват на компютър, са защитени с индивидуална парола за достъп на отговорните служители и се обработват посредством програма, която гарантира нивото им на защита.</span>
          </li>
          <li><span
              style="font-weight: 400;"> Права на субектите във връзка със защитата на личните им данни:</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Субектът на данните има право да получи от Администратора информация дали се обработват негови лични данни и ако това е така, има право да получи достъп до данни и информация, съдържаща се в тях.</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Субектът на данните има право да поиска да бъдат коригирани неточни лични данни, свързани с него. Непълните данни могат да бъдат допълнени.</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; Субектът на данните има право да поиска свързаните с него лични данни да бъдат изтрити. Администраторът има задължение да го осъществи в следните случаи:</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; личните данни повече не са необходими за целите, за които са били събирани и обработвани;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; субектът на данните оттегля съгласието си за обработване и няма друго правно основание за обработването на данните;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; субектът на данните възразява срещу обработването им и няма законни основания за обработване, които да имат преимущество;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; личните данни са били обработвани незаконосъобразно;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; личните данни трябва да бъдат изтрити с цел спазване на правно задължение, което се прилага спрямо Администратора.</span>
          </li>
        </ol>
        <ul>
          <li><span style="font-weight: 400;"> Субектът на данните има право на преносимост на личните данни между отделните администратори;</span>
          </li>
          <li><span style="font-weight: 400;"> Субектът на лични данни има право да изиска от Администратора да ограничи обработването (използването) на личните му данни в следните случаи:</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; когато субектът оспорва точността на личните си данни, за срока докато администраторът провери точността им;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; когато обработването на личните данни е неправомерно, но субектът е поискал не изтриването, а ограничаването им;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; когато администраторът не се нуждае вече от данните, но субектът на данните се нуждае от тях и ги изисква за установяването, упражняването или защита на свои правни претенции;</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">&bull; когато субектът на данните е възразил срещу обработването им съгласно процедурата в Регламента и е в очакване на проверка.</span>
          </li>
        </ul>
        <ol start="9">
          <li><span style="font-weight: 400;"> Условия, при които може да възразите срещу обработването</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">Субектът на данните има право на възражение срещу обработване на лични данни, отнасящи се до него.</span><span
              style="font-weight: 400;"><br/></span><span style="font-weight: 400;">Администраторът прекратява обработването на личните данни, освен ако не докаже, че съществуват убедителни законови основания за обработването, които имат предимство пред интересите, правата и свободите на субекта на данни или за установяването, упражняването или защитата на правни претенции.</span>
          </li>
          <li><span style="font-weight: 400;"> В случай на нарушение на правата му и сигурността на личните му данни, субектът на данните има право на жалба до надзорния орган по защита на личните данни &ndash; Комисията за защита на лични данни.</span>
          </li>
        </ol>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: 'PrivacyPolicyView',
  components: {},
  props: {},
  data() {
    return {}
  },


}
</script>

<style lang="scss" scoped>


.page-wrapper {
  font-family: $font-regular;
  background-color: $color-grey-100;
  padding-top: 100px;
  color: $color-green-dark;

}

.page-title {
  @include title-h4-bold;
  text-transform: uppercase;
  margin-bottom: $spacing-8;
}


@media screen and (min-width: 768px) {
  .page-wrapper {
    padding-top: 30px;

  }
  .paragraph-text {
    padding: 0 300px;
  }
  .page-title {
    @include title-h2-bold;
  }
}

@media screen and (min-width: 1500px) {


}
</style>